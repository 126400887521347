import "./clientes.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";
import { accessAPI } from "../../utils/utils.js";
import Loader from "../../elementos/loader/Loader.js";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion.js";
import Footer from "../../elementos/footer/Footer.js";
import CampoEditable from "../../elementos/campoEditable/CampoEditable.js";
import TarjetaEmpleadoCliente from "./TarjetaEmpleadoCliente.js";
import Formulario from "../../elementos/formulario/Formulario.js";
import Boton from "../../elementos/boton/Boton.js";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function Cliente() {
  const [loader, setLoader] = useState(true);
  const [datosClienteSeleccionado, setDatosClienteSeleccionado] = useState();
  const [loaderAgregarEmpleado, setLoaderAgregarEmpleado] = useState();

  const [loaderGenerarUsuario, setLoaderGenerarUsuario] = useState(false);

  const { clienteId } = useParams();

  const { navigate } = useNavigate();

  const { notify } = useNotification();

  const crearEmpleadoFormConfig = {
    campos: [
      {
        nombre: "nombreresponsable",
        tipoitemformularioid: 1,
        etiqueta: "Nombre",
        requerido: true,
      },
      {
        nombre: "apellidoresponsable",
        tipoitemformularioid: 1,
        etiqueta: "Apellido",
        requerido: true,
      },
      {
        nombre: "emailresponsable",
        tipoitemformularioid: 2,
        etiqueta: "Email",
        requerido: true,
      },
      {
        nombre: "telefonoresponsable",
        tipoitemformularioid: 1,
        etiqueta: "Celular",
      },
      {
        nombre: "cargoresponsable",
        tipoitemformularioid: 1,
        etiqueta: "Cargo",
      },
    ],
    submit: { callback: crearEmpleadoSubmit },
    botones: [{ etiqueta: "Crear empleado", tipo: "submit", clases: "azul" }],
  };

  // Cuando selecciona a un cliente va a buscar los detalles
  useEffect(() => {
    if (clienteId) {
      // Prende el loader y limpa al cliente seleccionado
      setLoader(true);
      accessAPI(
        "GET",
        `admin/cliente/${clienteId}`,
        null,
        (respuesta) => {
          setDatosClienteSeleccionado(respuesta);
          window.history.replaceState(null, null, `/clientes/${clienteId}`);
          setLoader(false);
        },
        (respuesta) => {
          // Si la API da error, devuelve el error al cliente
          notify({
            mensaje: respuesta[0].msg,
            temporal: true,
            error: true,
          });
        }
      );
    }
  }, [clienteId, notify]);

  // Función ejecutada por los empleados de los clientes
  function cambiarResponsable(empleadoId) {
    accessAPI(
      "PUT",
      `admin/cliente/responsablecertificacion/${datosClienteSeleccionado.id}/${empleadoId}`,
      null,
      async (respuesta) => {
        // Si la API da el ok, actualiza los empleados
        let datosClienteSeleccionadoActual = { ...datosClienteSeleccionado };
        for await (const empleado of datosClienteSeleccionadoActual.empleadoclientes) {
          empleado.responsablecertificacion = null;
          if (empleado.id === empleadoId) {
            empleado.responsablecertificacion = 1;
          }
        }
        setDatosClienteSeleccionado(datosClienteSeleccionadoActual);
        setLoaderAgregarEmpleado(false);
      },
      (response) => {
        notify({
          mensaje: response[0].msg,
          temporal: true,
          error: true,
        });
        setLoaderAgregarEmpleado(false);
      }
    );
  }

  // Función llamada por el formulario de crear empleado
  function crearEmpleadoSubmit(formData) {
    setLoaderAgregarEmpleado(true);
    accessAPI(
      "POST",
      `admin/cliente/empleadocliente/${datosClienteSeleccionado.id}`,
      formData,
      (respuesta) => {
        // Si crea el empleado correctamente, lo agrega a los datos de la empresa seleccionada
        let datosClienteSeleccionadoActual = { ...datosClienteSeleccionado };
        datosClienteSeleccionadoActual.empleadoclientes.push(
          respuesta[0].empleado
        );
        setDatosClienteSeleccionado(datosClienteSeleccionadoActual);
        setLoaderAgregarEmpleado(false);
      },
      (respuesta) => {
        // Si la API da error, navega al listado de clientes
        navigate("/clientes");
        setLoaderAgregarEmpleado(false);
      }
    );
  }

  function generarUsuario() {
    setLoaderGenerarUsuario(true);
    accessAPI(
      "POST",
      `admin/cliente/${clienteId}/asignarusuario`,
      null,
      (respuesta) => {
        notify({
          mensaje: `Usuario creado exitosamente con email ${respuesta.email}`,
          temporal: true,
          error: false,
        });
        let datosClienteSeleccionadoActual = { ...datosClienteSeleccionado };
        datosClienteSeleccionadoActual.usuario = {};
        datosClienteSeleccionadoActual.usuario.email = respuesta.email;
        setDatosClienteSeleccionado(datosClienteSeleccionadoActual);
      },
      (respuesta) => {
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
        setLoaderGenerarUsuario(false);
      }
    );
  }

  return (
    <div className="seccion">
      <MenuNavegacion submenuSeleccionado="clientes" />
      <Footer />
      <div className="contenido">
        {loader && <Loader>Cargando clientes</Loader>}

        {datosClienteSeleccionado && (
          <div className="flexContainer">
            <div className="tarjetasContainer">
              {!datosClienteSeleccionado.usuario && (
                <div>
                  <Boton
                    clases="verde centrado"
                    callback={generarUsuario}
                    loader={loaderGenerarUsuario}
                  >
                    Generar usuario
                  </Boton>
                </div>
              )}

              {datosClienteSeleccionado.usuario &&
                datosClienteSeleccionado.usuario.email && (
                  <div className="emailUsuario">
                    Usuario existente: {datosClienteSeleccionado.usuario.email}
                  </div>
                )}
              <div className="tarjeta ">
                <h1>{datosClienteSeleccionado.razonsocial}</h1>
                <CampoEditable
                  etiqueta="Razón social"
                  campo="razonsocial"
                  valor={datosClienteSeleccionado.razonsocial}
                  entidadid={datosClienteSeleccionado.id}
                  endpoint="admin/cliente"
                />
                <CampoEditable
                  etiqueta="Nombre Fantasía"
                  campo="nombrefantasia"
                  valor={datosClienteSeleccionado.nombrefantasia}
                  entidadid={datosClienteSeleccionado.id}
                  endpoint="admin/cliente"
                />
                <CampoEditable
                  etiqueta="RUT"
                  campo="rut"
                  valor={datosClienteSeleccionado.rut}
                  entidadid={datosClienteSeleccionado.id}
                  endpoint="admin/cliente"
                />
                <CampoEditable
                  etiqueta="Dirección"
                  campo="direccion"
                  valor={datosClienteSeleccionado.direccion}
                  entidadid={datosClienteSeleccionado.id}
                  endpoint="admin/cliente"
                />
                <CampoEditable
                  etiqueta="C.P."
                  campo="codigopostal"
                  valor={datosClienteSeleccionado.codigopostal}
                  entidadid={datosClienteSeleccionado.id}
                  endpoint="admin/cliente"
                />
                <CampoEditable
                  etiqueta="Estado"
                  campo="estado"
                  valor={datosClienteSeleccionado.estado}
                  entidadid={datosClienteSeleccionado.id}
                  endpoint="admin/cliente"
                />
                <CampoEditable
                  etiqueta="Ciudad"
                  campo="ciudad"
                  valor={datosClienteSeleccionado.ciudad}
                  entidadid={datosClienteSeleccionado.id}
                  endpoint="admin/cliente"
                />
                <CampoEditable
                  etiqueta="País"
                  campo="pais"
                  valor={datosClienteSeleccionado.pais}
                  entidadid={datosClienteSeleccionado.id}
                  endpoint="admin/cliente"
                />
                <CampoEditable
                  etiqueta="Dir. depósito"
                  campo="direcciondeposito"
                  valor={datosClienteSeleccionado.direcciondeposito}
                  entidadid={datosClienteSeleccionado.id}
                  endpoint="admin/cliente"
                />
                <CampoEditable
                  etiqueta="C.P. depósito"
                  campo="codigopostaldeposito"
                  valor={datosClienteSeleccionado.codigopostaldeposito}
                  entidadid={datosClienteSeleccionado.id}
                  endpoint="admin/cliente"
                />
                <CampoEditable
                  etiqueta="Estado depósito"
                  campo="estadodeposito"
                  valor={datosClienteSeleccionado.estadodeposito}
                  entidadid={datosClienteSeleccionado.id}
                  endpoint="admin/cliente"
                />
                <CampoEditable
                  etiqueta="Ciudad depósito"
                  campo="ciudaddeposito"
                  valor={datosClienteSeleccionado.ciudaddeposito}
                  entidadid={datosClienteSeleccionado.id}
                  endpoint="admin/cliente"
                />
                <CampoEditable
                  etiqueta="País depósito"
                  campo="paisdeposito"
                  valor={datosClienteSeleccionado.paisdeposito}
                  entidadid={datosClienteSeleccionado.id}
                  endpoint="admin/cliente"
                />
                <CampoEditable
                  etiqueta="Teléfono"
                  campo="telefono"
                  valor={datosClienteSeleccionado.telefono}
                  entidadid={datosClienteSeleccionado.id}
                  endpoint="admin/cliente"
                />
                <CampoEditable
                  etiqueta="Página"
                  campo="pagina"
                  valor={datosClienteSeleccionado.pagina}
                  entidadid={datosClienteSeleccionado.id}
                  endpoint="admin/cliente"
                />
                <CampoEditable
                  etiqueta="Notas"
                  campo="notas"
                  valor={datosClienteSeleccionado.notas}
                  entidadid={datosClienteSeleccionado.id}
                  endpoint="admin/cliente"
                />
              </div>
            </div>

            {datosClienteSeleccionado &&
              datosClienteSeleccionado.empleadoclientes.length > 0 && (
                <div className="tarjetasContainer">
                  {datosClienteSeleccionado.empleadoclientes.map((empleado) => {
                    return (
                      <TarjetaEmpleadoCliente
                        empleado={empleado}
                        key={empleado.id}
                        cambiarResponsable={cambiarResponsable}
                      />
                    );
                  })}
                  <div className="tarjeta empleado">
                    <h1>Agregar empleado</h1>
                    <Formulario
                      config={crearEmpleadoFormConfig}
                      loader={loaderAgregarEmpleado}
                      key={datosClienteSeleccionado.empleadoclientes.length}
                    />
                  </div>
                </div>
              )}
          </div>
        )}
      </div>
    </div>
  );
}
