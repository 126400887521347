import CampoEditable from "../../elementos/campoEditable/CampoEditable";

export default function AuditorDetalle(props) {
  const { auditor } = props;

  return (
    <>
      <div className="tarjetasLaboratoriosContainer flexContainer vertical">
        {auditor && (
          <>
            <div className="tarjeta tresCuartos">
              <h1>
                {auditor.nombre} {auditor.apellido}
              </h1>
              <CampoEditable
                etiqueta="Nombre"
                campo="nombre"
                entidadid={auditor.id}
                valor={auditor.nombre}
                endpoint="admin/auditor"
              />
              <CampoEditable
                etiqueta="Apellido"
                campo="apellido"
                valor={auditor.apellido}
                entidadid={auditor.id}
                endpoint="admin/auditor"
              />
              <CampoEditable
                etiqueta="Telefono"
                campo="telefono"
                valor={auditor.telefono}
                entidadid={auditor.id}
                endpoint="admin/auditor"
              />
              <CampoEditable
                etiqueta="Cédula de identidad"
                campo="cedula"
                valor={auditor.cedula}
                entidadid={auditor.id}
                endpoint="admin/auditor"
              />
              <CampoEditable
                etiqueta="Email"
                campo="email"
                valor={auditor.email}
                entidadid={auditor.id}
                endpoint="admin/auditor"
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
