import CampoEditable from "../../elementos/campoEditable/CampoEditable";

export default function TarjetaProductoCampoPropio(props) {
  const { data } = props;

  return (
    <div key={data.id}>
      <CampoEditable
        etiqueta="Etiqueta"
        valor={data.etiqueta}
        campo="etiqueta"
        entidadid={data.id}
        endpoint="admin/producto/productocampospropios"
      />
      <CampoEditable
        etiqueta="Nombre para URSEA"
        valor={data.nombre}
        campo="nombre"
        entidadid={data.id}
        endpoint="admin/producto/productocampospropios"
      />
      <CampoEditable
        etiqueta="Notas en formulario"
        valor={data.notas}
        campo="notas"
        entidadid={data.id}
        endpoint="admin/producto/productocampospropios"
      />
      <CampoEditable
        etiqueta="tipo"
        valor={data.tipo}
        campo="tipo"
        entidadid={data.id}
        endpoint="admin/producto/productocampospropios"
        dropdownopciones={[
          { value: "string", etiqueta: "Texto" },
          { value: "int", etiqueta: "Número entero" },
          { value: "float", etiqueta: "Número con decimales" },
        ]}
      />
      <CampoEditable
        etiqueta="Opciones"
        valor={data.opciones.join(" ; ")}
        campo="opciones"
        entidadid={data.id}
        endpoint="admin/producto/productocampospropios"
      />
      <CampoEditable
        etiqueta="Obligatorio"
        valor={data.obligatorio}
        campo="obligatorio"
        entidadid={data.id}
        endpoint="admin/producto/productocampospropios"
      />
      <CampoEditable
        etiqueta="Mostrar en certificado"
        valor={data.mostrarencertificado}
        campo="mostrarencertificado"
        entidadid={data.id}
        endpoint="admin/producto/productocampospropios"
      />
      <CampoEditable
        etiqueta="Mostrar en plantilla de solicitud"
        valor={data.mostrarenplantilla ? 1 : 0}
        campo="mostrarenplantilla"
        entidadid={data.id}
        endpoint="admin/producto/productocampospropios"
      />
      <CampoEditable
        etiqueta="Enviar a URSEA"
        valor={data.enviaraursea}
        campo="enviaraursea"
        entidadid={data.id}
        endpoint="admin/producto/productocampospropios"
      />{" "}
      <CampoEditable
        etiqueta="Campo identificador para URSEA"
        valor={data.identificadorursea}
        campo="identificadorursea"
        entidadid={data.id}
        endpoint="admin/producto/productocampospropios"
        booleano={true}
      />
      <CampoEditable
        etiqueta="Orden de envío a URSEA"
        valor={data.orden}
        campo="orden"
        entidadid={data.id}
        endpoint="admin/producto/productocampospropios"
      />
    </div>
  );
}
