import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { accessAPI } from "../../utils/utils.js";
import Loader from "../../elementos/loader/Loader";
import Formulario from "../../elementos/formulario/Formulario";

export default function Documentos() {
  const [loader, setLoader] = useState(true);
  const [documentos, setDocumentos] = useState();
  const [creandoDocumento, setCreandoDocumento] = useState(false);
  const [resetCrearDocumentoForm, setResetCrearDocumentoForm] = useState(1);

  const navigate = useNavigate();

  const crearDocumentoFormConfig = {
    campos: [
      {
        nombre: "codigo",
        tipoitemformularioid: 1,
        etiqueta: "Código",
        requerido: true,
      },
      {
        nombre: "nombre",
        tipoitemformularioid: 1,
        etiqueta: "Nombre",
        requerido: true,
      },
    ],
    submit: {
      callback: crearDocumentoFormSubmit,
    },
    botones: [
      { etiqueta: "Aceptar", tipo: "submit" },
      {
        etiqueta: "Cancelar",
        callback: () => {
          setCreandoDocumento(false);
        },
        clases: "rojo",
        tipo: "button",
      },
    ],
  };

  // Cuando carga el componente busca los documentos existentes
  useEffect(() => {
    accessAPI(
      "GET",
      "admin/documento",
      null,
      (response) => {
        setDocumentos(response);
      },
      (response) => {
        console.log(response);
      }
    );
  }, []);

  // Cuadno se cargan los documentos, apaga el loader
  useEffect(() => {
    if (documentos) {
      setLoader(false);
    }
  }, [documentos]);

  // Callback de submit del formulario
  function crearDocumentoFormSubmit(contenidoCampos) {
    accessAPI(
      "POST",
      "admin/documento",
      contenidoCampos,
      (response) => {
        navigate(`/documento/${response.id}`);
      },
      (response) => {
        alert(response[0].msg);
        setResetCrearDocumentoForm(resetCrearDocumentoForm + 1);
      }
    );
  }

  return (
    <div>
      <div>Documentos</div>
      <div>Crear nuevo documento</div>

      {!creandoDocumento && (
        <button
          onClick={() => {
            setCreandoDocumento(true);
          }}
        >
          Nuevo documento
        </button>
      )}
      {creandoDocumento && (
        <Formulario
          config={crearDocumentoFormConfig}
          key={resetCrearDocumentoForm}
        />
      )}
      {loader && <Loader>Cargando documentos</Loader>}
      {!loader && documentos && (
        <div>
          <div>Documentos vigentes</div>
          {documentos.documentosVigentes.map((documento) => {
            return (
              <div key={documento.id}>
                <a href={`documento/${documento.id}`}>
                  {documento.codigo} v. {documento.version} - {documento.nombre}
                </a>
              </div>
            );
          })}
          <div>Documentos no vigentes</div>
          {documentos.documentosNoVigentes.map((documento) => {
            return (
              <div key={documento.id}>
                <a href={`documento/${documento.id}`}>
                  {documento.codigo} v. {documento.version} - {documento.nombre}
                </a>
                - {documento.fechaliberacion && <span>Liberado</span>}
                {!documento.fechaliberacion && <span>No liberado</span>}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
