// Configuración de campo con dropdown:
/*
<CampoEditable
  etiqueta="Tipo de certificado"
  campo="certificadotipoid"
  valor={datosNormaSeleccionada.certificadotipoid}
  entidadid={datosNormaSeleccionada.id}
  endpoint="admin/norma"
  dropdownurl="admin/config/certificadotipo/dropdown"
  dropdownopciones={[{value:'1', etiqueta:'opcion 1'}]}
  recursoUri="hola"
  noEditable="Mensaje de no editable"
  booleano={boolean}
  textarea={true}
  callback={function} //Se ejecuta cuando vuelve del put
 />
*/

import { useState, useEffect, useRef } from "react";
import "./campoEditable.css";
import cancelarIcono from "../../images/cancelarIcono.png";
import confirmarIcono from "../../images/confirmarIcono.png";
import flechaDerechaIcono from "../../images/flechaDerechaIcono.png";
import loaderImagen from "../../images/loaderImagen.png";
import { accessAPI } from "../../utils/utils.js";
import { useNotification } from "../notificacion/NotificationProvider.js";

export default function CampoEditable(props) {
  const [editando, setEditando] = useState(false);
  const [loader, setLoader] = useState(true);
  const [valor, setValor] = useState(props.valor);

  const { notify } = useNotification();

  const { booleano, callback } = props;

  const [dropdownOpciones, setDropdownOpciones] = useState(
    props.dropdownopciones
  );

  const campoRef = useRef();

  // El componente comienza con el loader encendido, si no es un
  // dropdown, lo apaga en seguida
  // Si es un dropdown, carga el valor por defecto
  useEffect(() => {
    if (!props.dropdownurl) {
      setLoader(false);
    }
  }, [valor, props.dropdownurl]);

  // Cuando entra en modo edición, si es un dropdown, va a buscar los parámetros
  useEffect(() => {
    if (editando) {
      if (props.dropdownurl && !dropdownOpciones) {
        accessAPI(
          "GET",
          props.dropdownurl,
          null,
          (respuesta) => {
            setDropdownOpciones(respuesta);
            setLoader(false);
          },
          (respuesta) => {
            // Si la API da error, devuelve el error al cliente
            notify({
              mensaje: respuesta[0].msg,
              temporal: true,
              error: true,
            });
            setLoader(false);
          }
        );
      }
    }
  }, [
    editando,
    props.dropdownurl,
    props.entidadid,
    props.valor,
    dropdownOpciones,
    callback,
    notify,
  ]);

  // Función ejecutada al confirmar el cambio de un campo
  function actualizarCampo() {
    let dato = booleano ? campoRef.current.checked : campoRef.current.value;
    setLoader(true);
    const nuevoValor = props.esBooleano
      ? campoRef.current.checked
      : campoRef.current.value;
    accessAPI(
      "PUT",
      `${props.endpoint}/${props.entidadid}`,
      { campo: props.campo, dato },
      (respuesta) => {
        // Si recibió un valor actualizado de la API, agrega ese
        if (respuesta.valoractualizado) {
          setValor(respuesta.valoractualizado);
          // Si no recibió el valor actualizado de la API, actualiza local
        } else {
          // Si es un dropdown, va a buscar la etiqueta en lugar del valor
          if (props.dropdownurl) {
            dropdownOpciones.forEach((opcion) => {
              if (
                // DESHABILITO ESLINT PORQUE NECESITO QUE JS PARSEE EL VALUE SELECCIONADO
                // eslint-disable-next-line
                opcion.value == campoRef.current.value
              ) {
                setValor(opcion.etiqueta);
              }
            });
          } else if (props.esBooleano) {
            setValor(nuevoValor ? "Sí" : "No");
          } else {
            setValor(dato);
          }
        }
        if (callback) {
          callback(respuesta);
        }

        // Si recibió un mensaje, lo muestra
        if (respuesta.msg) {
          notify({
            mensaje: respuesta.msg,
            error: false,
            temporal: true,
          });
        }
        setEditando(false);
        setLoader(false);
      },
      (respuesta) => {
        // Si la API da error, devuelve el error al cliente
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
        setLoader(false);
      }
    );
  }

  // Captura las teclas de enter y escape para confirmar el ingreso del campo
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      actualizarCampo();
      return;
    }

    if (event.key === "Escape") {
      setEditando(false);
      return;
    }
  };

  return (
    <div>
      <div className="campoEditableContainer flexContainer">
        {editando === false && (
          <>
            <span
              className="campoNombre"
              onClick={() => {
                setEditando(true);
              }}
            >
              {props.etiqueta}:
            </span>

            {valor && !booleano && (
              <>
                <span className="campoEditable">
                  {typeof valor === "string" &&
                    valor.split("\n").map((parrafo, index) => {
                      return (
                        <span key={index}>
                          {parrafo}
                          <br />
                        </span>
                      );
                    })}
                  {typeof valor != "string" && valor}
                </span>
                {props.recursoUri && (
                  <a href={`${props.recursoUri}`} rel="noreferrer">
                    <img
                      className="centrado"
                      src={flechaDerechaIcono}
                      alt="ira"
                    />
                  </a>
                )}
              </>
            )}

            {!valor && !booleano && (
              <span
                className="sinDato"
                onClick={() => {
                  setEditando(true);
                }}
              >
                Ingresar
              </span>
            )}

            {valor && booleano && <span>Si</span>}
            {!valor && booleano && <span>No</span>}
          </>
        )}
        {editando && !props.dropdownurl && !dropdownOpciones && (
          <>
            {!loader && (
              <span className="flexContainer campoNombre">
                <img
                  className="icono"
                  src={cancelarIcono}
                  onClick={() => {
                    setEditando(false);
                  }}
                  alt="cancelar"
                />
                {!props.noEditable && props.campo && (
                  <img
                    className="icono"
                    src={confirmarIcono}
                    onClick={actualizarCampo}
                    alt="confirmar"
                  />
                )}
              </span>
            )}
            {loader && (
              <span className="campoNombre">
                <img
                  src={loaderImagen}
                  className="campoEditableLoader"
                  alt="loader"
                />
              </span>
            )}
            {!props.noEditable &&
              props.campo &&
              !props.esFecha &&
              !booleano &&
              !props.textarea && (
                <input
                  className="prettyInput"
                  type="text"
                  defaultValue={valor}
                  disabled={loader}
                  ref={campoRef}
                  onKeyDown={handleKeyDown}
                />
              )}

            {!props.noEditable && props.campo && props.esFecha && !booleano && (
              <input
                className="prettyInput"
                type="date"
                defaultValue={valor}
                disabled={loader}
                ref={campoRef}
                onKeyDown={handleKeyDown}
              />
            )}
            {!props.noEditable && props.campo && !props.esFecha && booleano && (
              <input type="checkbox" defaultChecked={valor} ref={campoRef} />
            )}
            {(!props.campo || props.noEditable) && (
              <span className="campoNombre">
                {props.noEditable || "No puede ser editado"}
              </span>
            )}
          </>
        )}

        {!props.noEditable &&
          editando &&
          (props.dropdownurl || dropdownOpciones) && (
            <>
              {!loader && (
                <span className="flexContainer campoNombre">
                  <img
                    className="icono"
                    src={cancelarIcono}
                    onClick={() => {
                      setEditando(false);
                    }}
                    alt="cancelar"
                  />
                  <img
                    className="icono"
                    src={confirmarIcono}
                    onClick={actualizarCampo}
                    alt="confirmar"
                  />
                </span>
              )}

              {loader && (
                <span className="campoNombre">
                  <img
                    src={loaderImagen}
                    className="campoEditableLoader"
                    alt="loader"
                  />
                </span>
              )}
              <select className="prettyInput" disabled={loader} ref={campoRef}>
                {dropdownOpciones &&
                  dropdownOpciones.map((opcion) => {
                    return (
                      <option value={opcion.value} key={opcion.value}>
                        {opcion.etiqueta}
                      </option>
                    );
                  })}
              </select>
            </>
          )}
        {!props.noEditable && editando && props.textarea && (
          <textarea
            rows="10"
            className="prettyInput"
            defaultValue={valor}
            disabled={loader}
            ref={campoRef}
          />
        )}

        {props.noEditable && editando && props.dropdownurl && (
          <>
            <span className="flexContainer campoNombre">
              <img
                className="icono"
                src={cancelarIcono}
                onClick={() => {
                  setEditando(false);
                }}
                alt="cancelar"
              />
            </span>
            <span className="campoNombre">
              {props.noEditable || "No puede ser editado"}
            </span>
          </>
        )}
      </div>
    </div>
  );
}
