import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion.js";
import Footer from "../../elementos/footer/Footer.js";
import Loader from "../../elementos/loader/Loader.js";
import { accessAPI } from "../../utils/utils.js";
import Boton from "../../elementos/boton/Boton.js";
import "./procesos.css";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

import DataTable from "react-data-table-component";

export default function Procesos() {
  const [loaderClientes, setLoaderClientes] = useState(true);
  const [clientes, setClientes] = useState();

  const { notify } = useNotification();

  const navigate = useNavigate();

  const columnas = [
    {
      name: "Nombre",
      selector: (cliente) => `${cliente.etiqueta}`,
    },
    {
      name: "Procesos",

      selector: (cliente) => {
        if (cliente.certificados > 0) {
          return <span>{`${cliente.certificados}`}</span>;
        }
      },
      maxWidth: "120px",
      center: true,
    },
  ];

  // Cuando carga el componente va a buscar clientes
  useEffect(() => {
    accessAPI(
      "GET",
      "admin/cliente?filtro=procesos",
      null,
      (response) => {
        setClientes(response);
      },
      (respuesta) => {
        // Si la API da error, devuelve el error al cliente
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
      }
    );
  }, [notify]);

  // Cuando se cargan los clientes, apaga el loader
  useEffect(() => {
    if (clientes) {
      setLoaderClientes(false);
    }
  }, [clientes]);

  return (
    <div className="seccion">
      <MenuNavegacion submenuSeleccionado="procesos" />
      <Footer />
      <div className="contenido">
        <div className="flexContainer">
          <Boton uri="/procesos/tablero">Tablero</Boton>
          <Boton uri="/solicitudes">Solicitudes</Boton>
          <Boton uri="/procesos/nuevo" clases="verde">
            Nuevo proceso
          </Boton>
        </div>
        {loaderClientes && <Loader>Cargando clientes</Loader>}
        {!loaderClientes && clientes && (
          <>
            <div className="flexContainer">
              <div className="tarjetasContainer">
                <div className="tarjeta listadoClientes">
                  <DataTable
                    columns={columnas}
                    data={clientes}
                    highlightOnHover
                    pointerOnHover
                    striped
                    onRowClicked={(row, event) => {
                      navigate(`/procesos/cliente/${row.value}`);
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
