import { useState } from "react";
import Formulario from "../../elementos/formulario/Formulario";
import { accessAPI } from "../../utils/utils";
import NormaEnCertificadoTipo from "./NormaEnCertificadoTipo";

export default function TarjetaAsignarNorma(props) {
  const [agregarNormaLoader, setAgregarNormaLoader] = useState(false);

  const [normas, setNormas] = useState(props.normas);
  const { certificadoTipoId } = props;

  const formualrioAgregarNormaConfig = {
    secciones: [
      {
        campos: [
          {
            nombre: "normaid",
            tipoitemformularioid: 5,
            etiqueta: "Nueva norma",
            opciones: "admin/norma",
            sinOpcionInicial: true,
            requerido: true,
          },
        ],
      },
    ],
    submit: { callback: agregarNorma },
    botones: [{ etiqueta: "Agregar norma", tipo: "submit", clases: "azul" }],
  };

  // Función ejecutada por el formulario para agregar el esquema
  function agregarNorma(datosFormulario) {
    setAgregarNormaLoader(true);
    accessAPI(
      "PUT",
      `admin/config/certificadotipo/${certificadoTipoId}/norma`,
      datosFormulario,
      (respuesta) => {
        setNormas((nuevaNorma) => [...normas, respuesta]);
        setAgregarNormaLoader(false);
      },
      (respuesta) => {
        alert(respuesta[0].msg);
        setAgregarNormaLoader(false);
      }
    );
  }

  return (
    <div className="tarjeta tresCuartos">
      <h1>Normas utilizadas</h1>
      {normas.map((norma) => {
        return (
          <NormaEnCertificadoTipo
            norma={norma}
            certificadoTipoId={certificadoTipoId}
            key={norma.id}
          />
        );
      })}
      <Formulario
        config={formualrioAgregarNormaConfig}
        loader={agregarNormaLoader}
        key={normas.length}
      />
    </div>
  );
}
