import { useState } from "react";
import { accessAPI } from "../../utils/utils";

export default function EsquemaEnCertificadoTipo(props) {
  const [loaderEliminar, setLoaderEliminar] = useState(false);
  const [eliminado, setEliminado] = useState(false);

  const { esquema, certificadoTipoId } = props;

  // Función ejecutada al presionar el botón de eliminar esquema
  function eliminarEsquema(esquemaid) {
    accessAPI(
      "DELETE",
      `admin/config/certificadotipo/${certificadoTipoId}/esquema`,
      { esquemaid: esquema.esquema.id },
      (respuesta) => {
        setEliminado(true);
      },
      (respuesta) => {
        alert(respuesta[0].msg);
      }
    );
  }
  return (
    <div key={esquema.esquema.id} className={eliminado ? "eliminado" : ""}>
      {!eliminado && (
        <button
          className={loaderEliminar ? "botonEliminar loader" : "botonEliminar"}
          onClick={() => {
            setLoaderEliminar(true);
            if (!loaderEliminar) {
              eliminarEsquema(esquema.id);
            }
          }}
        >
          {loaderEliminar && <span>Cargando...</span>}
          {!loaderEliminar && <span>X</span>}
        </button>
      )}
      {esquema.esquema.etiqueta} ({esquema.esquema.nombre})
    </div>
  );
}
