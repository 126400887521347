import gear from "../../images/gear.png";
import Boton from "../boton/Boton";
import Version from "../version/Version";
import "./footer.css";

export default function Footer() {
  return (
    <footer>
      <Version />
      <div className="configBotonContainer">
        <Boton uri="/config" clases="relleno">
          <img src={gear} alt="Config" className="imagenBotonConfig" />
        </Boton>
      </div>
    </footer>
  );
}
