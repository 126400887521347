import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { accessAPI } from "../../utils/utils.js";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion";
import Footer from "../../elementos/footer/Footer";
import Loader from "../../elementos/loader/Loader";
import TarjetaDetallesSolicitud from "./TarjetaDetallesSolicitud";
import TarjetaCrearProceso from "./TarjetaCrearProceso";
import TarjetaProcesosVigentes from "./TarjetaProcesosVigentes";
import TarjetaMarcarSolicitudProcesada from "./TarjetaMarcarSolicitudProcesada";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function Solicitud() {
  const [loader, setLoader] = useState(true);
  const [solicitud, setSolicitud] = useState();
  const [procesosVigentes, setProcesosVigentes] = useState();

  const { notify } = useNotification();

  // Carga el id del solicitud de la URL
  const { solicitudId } = useParams();

  // Cuando carga el componente va a buscar el solicitud
  useEffect(() => {
    // Tengo que agregar este if para que no vuelva a llamar a la API cuando cambia otro objeto del componente
    if (!solicitud) {
      accessAPI(
        "GET",
        `admin/proceso/solicitud/${solicitudId}`,
        null,
        (respuesta) => {
          setSolicitud(respuesta);
        },
        (respuesta) => {
          // Si la API da error, devuelve el error al cliente
          notify({
            mensaje: respuesta[0].msg,
            temporal: true,
            error: true,
          });
        }
      );
    }
  }, [solicitud, solicitudId, notify]);

  // Cuando carga el solicitud y los tipos de pasos apaga el loader
  useEffect(() => {
    if (solicitud) {
      setLoader(false);
      setProcesosVigentes(solicitud.proceso);
    }
  }, [solicitud]);

  const marcarSolicitudComoProcesada = () => {
    setSolicitud((prevSolicitud) => ({
      ...prevSolicitud,
      procesada: true,
    }));
  };

  // Función disparada por TarjetaCrearProceso cuando se crea un proceso para agregarlo a la lista de procesos vigentes de la solicitud
  function agregarProcesoVigenteASolicitud(proceso) {
    setProcesosVigentes((prevProcesosVigentes) => [
      ...prevProcesosVigentes,
      proceso,
    ]);
  }

  return (
    <div className="seccion">
      <MenuNavegacion submenuSeleccionado="procesos" />
      <Footer />
      <div className="contenido">
        {loader && <Loader>Cargando el proceso</Loader>}
        {!loader && (
          <div className="flexContainer">
            <div className="tarjetasContainer">
              <div className="flexContainer vertical">
                {solicitud.procesada && (
                  <div className="notificacionProcesada">
                    Solicitud procesada
                  </div>
                )}
                <TarjetaDetallesSolicitud solicitud={solicitud} />
                {!solicitud.procesada && (
                  <TarjetaMarcarSolicitudProcesada
                    solicitud={solicitud}
                    onMarcarProcesada={marcarSolicitudComoProcesada}
                  />
                )}
              </div>
            </div>
            <div className="tarjetasContainer">
              <div className="flexContainer vertical">
                <TarjetaProcesosVigentes procesos={procesosVigentes} />
                {!solicitud.procesada && (
                  <TarjetaCrearProceso
                    solicitud={solicitud}
                    agregarProcesoVigenteASolicitud={
                      agregarProcesoVigenteASolicitud
                    }
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
