import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { accessAPI } from "../../utils/utils.js";
import Loader from "../../elementos/loader/Loader";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion.js";
import Footer from "../../elementos/footer/Footer";
import Boton from "../../elementos/boton/Boton";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

import DataTable from "react-data-table-component";

export default function Auditores() {
  const [loader, setLoader] = useState(true);
  const [auditores, setAuditores] = useState();

  const { notify } = useNotification();

  const { auditorId } = useParams();
  const navigate = useNavigate();

  const columnas = [
    {
      name: "Nombre",
      selector: (auditor) => `${auditor.nombre} ${auditor.apellido}`,
      sortable: true,
    },
  ];

  //Carga los auditores
  useEffect(() => {
    accessAPI(
      "GET",
      "admin/auditor",
      null,
      (respuesta) => {
        setAuditores(respuesta);
        setLoader(false);
      },
      (respuesta) => {
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
        setLoader(false);
      }
    );
  }, [auditorId, notify]);

  //Setea el loader dependiendo de si llegaron los datos
  useEffect(() => {
    if (auditores) {
      setLoader(false);
    }
  }, [auditores]);

  return (
    <div className="seccion ">
      <MenuNavegacion submenuSeleccionado="proveedores" />
      <Footer />
      <div className="contenido ">
        <h1>Auditores</h1>
        {loader && <Loader>Cargando auditores</Loader>}
        {!loader && auditores && (
          <>
            <Boton clases="verde centrado" uri="/proveedores/nuevoauditor">
              Nuevo auditor
            </Boton>
            <div className="tarjeta tresCuartos">
              <DataTable
                columns={columnas}
                data={auditores}
                highlightOnHover
                pointerOnHover
                striped
                onRowClicked={(row, event) => {
                  navigate("/proveedores/auditores/" + row.id);
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
