import CampoEditable from "../../elementos/campoEditable/CampoEditable";

export default function ConfigEventoSingle(props) {
  const { configEvento } = props;
  return (
    <div className="tarjeta">
      <CampoEditable
        etiqueta="Asunto de la comunicación"
        campo="asunto"
        valor={configEvento.asunto}
        entidadid={configEvento.id}
        endpoint="admin/comunicacion/eventoconfig"
      />
      <CampoEditable
        etiqueta="Texto de la comunicación"
        campo="texto"
        valor={configEvento.texto}
        textarea={true}
        entidadid={configEvento.id}
        endpoint="admin/comunicacion/eventoconfig"
      />
      <CampoEditable
        etiqueta="Destinatario email"
        campo="destinatarioemail"
        valor={configEvento.destinatarioemail}
        entidadid={configEvento.id}
        endpoint="admin/comunicacion/eventoconfig"
      />
      <CampoEditable
        etiqueta="Copia oculta (BCC) email"
        campo="bccemail"
        valor={configEvento.bccemail}
        entidadid={configEvento.id}
        endpoint="admin/comunicacion/eventoconfig"
      />
    </div>
  );
}
