import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion.js";
import Footer from "../../elementos/footer/Footer.js";
import Loader from "../../elementos/loader/Loader.js";
import { accessAPI, downloadFile, formatFecha } from "../../utils/utils.js";
import Boton from "../../elementos/boton/Boton.js";
import "./procesos.css";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function ProcesosCliente() {
  const [loaderProcesos, setLoaderProcesos] = useState(false);
  const [procesos, setProcesos] = useState();
  const [procesosNoActivos, setProcesosNoActivos] = useState();
  const [datosCliente, setDatosCliente] = useState();
  const [exportarResumenLoader, setExportarResumenLoader] = useState(false);

  const { notify } = useNotification();

  const { clienteId } = useParams();

  // Cuando carga el componente va a buscar los procesos de cliente seleccionado
  useEffect(() => {
    accessAPI(
      "GET",
      `admin/proceso/cliente/${clienteId}`,
      null,
      (respuesta) => {
        setProcesos(respuesta.procesosActivos);
        setProcesosNoActivos(respuesta.procesosNoActivos);
        setDatosCliente(respuesta.cliente);
      },
      (respuesta) => {
        notify({
          mensaje: respuesta[0].msg,
          error: true,
          temporal: true,
        });
      }
    );
  }, [clienteId, notify]);

  // Cuando se cargan los procesos, apaga el loader de procesos
  useEffect(() => {
    if (procesos || procesosNoActivos) {
      setLoaderProcesos(false);
    }
  }, [procesos, procesosNoActivos]);

  // Exporta un resumen de los certificados del cliente en Excel
  function exportarResumen() {
    setExportarResumenLoader(true);
    downloadFile(
      `admin/certificado/resumen/${clienteId}`,
      `Certificados vigentes - ${datosCliente.razonsocial} - ${formatFecha(
        new Date()
      )}.xlsx`,
      (respuesta) => {
        setExportarResumenLoader(false);
      },
      (respuesta) => {
        alert(respuesta[0].msg);
        setExportarResumenLoader(false);
      }
    );
  }

  return (
    <div className="seccion">
      <MenuNavegacion submenuSeleccionado="procesos" />
      <Footer />
      <div className="contenido">
        <div className="tarjetasContainer">
          {loaderProcesos && <Loader>Cargando procesos</Loader>}
          {!loaderProcesos && procesos && (
            <>
              <h1>Procesos para {datosCliente.razonsocial}</h1>
              <Boton
                callback={exportarResumen}
                clases="verde centrado"
                loader={exportarResumenLoader}
              >
                Exportar resumen
              </Boton>
              <div className="tarjeta tresCuartos">
                {procesos.map((proceso) => {
                  return (
                    <div className="procesoIndividual" key={proceso.id}>
                      <Boton
                        uri={`/proceso/${proceso.id}`}
                        clases="texto textoIzquierda"
                      >
                        {proceso.certificado.length !== 0 && (
                          <span className="punto verde"></span>
                        )}
                        {proceso.certificado.length === 0 && (
                          <span className="punto amarillo"></span>
                        )}
                        {proceso.certificadotipo.procesotipo.letra}-
                        {proceso.numero} - {proceso.nombrefantasia}
                      </Boton>
                      <div className="detalleProceso">
                        {proceso.norma ? proceso.norma.numero : "Sin norma"}
                      </div>
                      {proceso.certificado.length !== 0 && (
                        <Boton
                          uri={`/certificado/${proceso.certificado[0].id}`}
                          clases="detalleProceso texto"
                        >
                          Certificado emitido:{" "}
                          {formatFecha(proceso.certificado[0].fechaemision)}
                        </Boton>
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          )}

          {!loaderProcesos &&
            procesosNoActivos &&
            procesosNoActivos.length > 0 && (
              <div className="tarjeta tresCuartos">
                <h3>Procesos no activos</h3>
                {procesosNoActivos.map((procesoNoActivos) => {
                  return (
                    <div
                      className="procesoIndividual"
                      key={procesoNoActivos.id}
                    >
                      <Boton
                        uri={`/proceso/${procesoNoActivos.id}`}
                        clases="texto textoIzquierda"
                      >
                        {procesoNoActivos.certificado.length !== 0 && (
                          <span className="punto verde"></span>
                        )}
                        {procesoNoActivos.certificado.length === 0 && (
                          <span className="punto amarillo"></span>
                        )}
                        {procesoNoActivos.certificadotipo.procesotipo.letra}-
                        {procesoNoActivos.numero} -{" "}
                        {procesoNoActivos.nombrefantasia}
                      </Boton>
                      <div className="detalleProceso">
                        {procesoNoActivos.norma
                          ? procesoNoActivos.norma.numero
                          : "Sin norma"}
                      </div>
                      {procesoNoActivos.certificado.length !== 0 && (
                        <Boton
                          uri={`/certificado/${procesoNoActivos.certificado[0].id}`}
                          clases="detalleProceso texto"
                        >
                          Certificado emitido:{" "}
                          {formatFecha(
                            procesoNoActivos.certificado[0].fechaemision
                          )}
                        </Boton>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
        </div>
      </div>
    </div>
  );
}
