import { useState } from "react";
import Formulario from "../../elementos/formulario/Formulario";
import { accessAPI } from "../../utils/utils";

export default function ReemisionCertificado(props) {
  const [loader, setLoader] = useState(false);
  const { certificadoId, agregarEmision } = props;

  const formularioConfig = {
    secciones: [
      {
        nombre: "Reemisión",
        campos: [
          {
            nombre: "fechaemision",
            etiqueta: "Fecha de emisión (si queda en blanco es hoy)",
            tipoitemformularioid: 7,
          },
          {
            nombre: "notas",
            etiqueta: "Comentario",
            tipoitemformularioid: 1,
            requerido: true,
          },
        ],
      },
    ],
    submit: { callback: reemitirCertificado },
    botones: [
      {
        etiqueta: "Regenerar certificado",
        clases: "rojo",
        tipo: "submit",
      },
    ],
  };

  // Regenera el certificado
  function reemitirCertificado(contenidoCampos) {
    setLoader(true);
    accessAPI(
      "PUT",
      `admin/certificado/regenerar/${certificadoId}`,
      contenidoCampos,
      (respuesta) => {
        setLoader(false);
        agregarEmision(respuesta);
      },
      (respuesta) => {
        alert(respuesta[0].msg);
        setLoader(false);
      }
    );
  }

  return (
    <div>
      <Formulario config={formularioConfig} loader={loader} />
    </div>
  );
}
