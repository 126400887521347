import { useState, useEffect } from "react";
import { accessAPI } from "../../utils/utils.js";
import Formulario from "../../elementos/formulario/Formulario.js";
import Loader from "../../elementos/loader/Loader";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function ProcesoFormularioProducto(props) {
  const [loaderAgregarProducto, setLoaderAgregarProducto] = useState(false);
  const [loaderFormulario, setLoaderFormulario] = useState(true);
  const [formularioProductoConfig, setFormularioProductoConfig] = useState();

  const { notify } = useNotification();

  // Cuando carga el proceso y los tipos de pasos apaga el loader
  useEffect(() => {
    // Función ejecutada al agregar un producto
    function submitAgregarProducto(contenidoCampos) {
      // Prende el loader del formulario
      setLoaderAgregarProducto(true);
      // Toma los campos obligatorios
      let camposParaMandar = {
        marca: contenidoCampos.marca,
        modelo: contenidoCampos.modelo,
        descripcion: contenidoCampos.descripcion,
      };

      let camposPropios = {};
      // Itera el objeto buscando el resto de los campos
      for (const key in contenidoCampos) {
        if (key !== "marca" && key !== "modelo" && key !== "descripcion") {
          camposPropios[key] = contenidoCampos[key];
        }
        camposParaMandar.campospropios = camposPropios;
      }
      accessAPI(
        "POST",
        `admin/proceso/producto/${props.proceso.id}`,
        camposParaMandar,
        (respuesta) => {
          props.agregarProductoAProceso(respuesta);
          setLoaderAgregarProducto(false);
        },
        (respuesta) => {
          // Le avisa al usuario
          setLoaderAgregarProducto(true);
          notify({
            mensaje: respuesta[0].msg,
            temporal: true,
            error: true,
          });
        }
      );
    }

    const formAgregarProductoConfig = {
      campos: [],
      submit: { callback: submitAgregarProducto },
      botones: [
        { etiqueta: "Agregar producto", tipo: "submit", clases: "verde" },
      ],
    };
    if (props.proceso.certificadotipo?.productocampospropios) {
      let formularioProductoConfigParaModificar = {
        ...formAgregarProductoConfig,
      };
      for (const campoPropio of props.proceso.certificadotipo
        ?.productocampospropios) {
        // Genera un objeto para formatear la configuración según la API de formulario
        let campoParaConfigurar = {};
        campoParaConfigurar.nombre = campoPropio.id;
        campoParaConfigurar.etiqueta = campoPropio.notas
          ? campoPropio.etiqueta + " - " + campoPropio.notas
          : campoPropio.etiqueta;
        switch (campoPropio.tipo) {
          case "int":
            campoParaConfigurar.tipoitemformularioid = 4;
            break;
          case "float":
            campoParaConfigurar.tipoitemformularioid = 8;
            break;
          default:
            campoParaConfigurar.tipoitemformularioid = 1;
            break;
        }
        campoParaConfigurar.requerido = campoPropio.obligatorio ? true : false;
        // Si tiene opciones las carga
        if (campoPropio.opcionesdropdown.length) {
          campoParaConfigurar.opciones = campoPropio.opcionesdropdown;
          campoParaConfigurar.sinOpcionInicial = true;
          campoParaConfigurar.tipoitemformularioid = 5;
        }
        formularioProductoConfigParaModificar.campos.push(campoParaConfigurar);
      }

      setFormularioProductoConfig({ ...formularioProductoConfigParaModificar });
    } else {
      setFormularioProductoConfig({ ...formAgregarProductoConfig });
    }
  }, [props, props.proceso, notify]);

  // Cuando termina de configurar el formulario, apaga el loader
  useEffect(() => {
    if (formularioProductoConfig) {
      setLoaderFormulario(false);
    }
  }, [formularioProductoConfig]);

  return (
    <>
      {props.proceso && (
        <div className="tarjeta producto">
          <h1>Agregar producto</h1>
          {loaderFormulario && <Loader>Cargando</Loader>}
          {!loaderFormulario && (
            <Formulario
              config={formularioProductoConfig}
              loader={loaderAgregarProducto}
            />
          )}
        </div>
      )}
    </>
  );
}
