import { useState, useEffect } from "react";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion";
import Footer from "../../elementos/footer/Footer";
import Loader from "../../elementos/loader/Loader";
import { accessAPI } from "../../utils/utils";
import CampoEditable from "../../elementos/campoEditable/CampoEditable";
import Boton from "../../elementos/boton/Boton";

export default function Esquemas() {
  const [loader, setLoader] = useState(true);
  const [esquemas, setEsquemas] = useState();

  // Cuando carga el componente carga los tipos de proceso
  useEffect(() => {
    accessAPI(
      "GET",
      "admin/config/esquema/config",
      null,
      (respuesta) => {
        setEsquemas(respuesta);
      },
      (respuesta) => {
        console.log(respuesta);
      }
    );
  }, []);

  // Cuando carga los tipos de proceso apaga el loader
  useEffect(() => {
    if (esquemas) {
      setLoader(false);
    }
  }, [esquemas]);

  return (
    <div className="seccion">
      <MenuNavegacion submenuSeleccionado="config" />
      <Footer />
      <div className="contenido">
        {loader && <Loader>Cargando</Loader>}
        {!loader && (
          <div>
            <h1>Esquemas</h1>
            <Boton clases="verde centrado" uri="/esquemas/nuevo">
              Nuevo esquema
            </Boton>
            <div className="tarjetasContainer">
              {esquemas.map((unEsquema) => {
                return (
                  <div className="tarjeta tresCuartos" key={unEsquema.id}>
                    <CampoEditable
                      etiqueta="Etiqueta"
                      valor={unEsquema.etiqueta}
                      campo="etiqueta"
                      entidadid={unEsquema.id}
                      endpoint="admin/config/esquema"
                    />
                    <CampoEditable
                      etiqueta="Nombre certificado digital URSEA"
                      valor={unEsquema.nombre}
                      campo="nombre"
                      entidadid={unEsquema.id}
                      endpoint="admin/config/esquema"
                    />
                    <CampoEditable
                      etiqueta="Descripción"
                      valor={unEsquema.descripcion}
                      campo="descripcion"
                      entidadid={unEsquema.id}
                      endpoint="admin/config/esquema"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
