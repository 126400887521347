import { useState } from "react";
import { accessAPI } from "../../utils/utils.js";
import Formulario from "../../elementos/formulario/Formulario";
export default function ComunicarReevaluacion(props) {
  const [loader, setLoader] = useState(false);

  const { mostrarMensaje } = props;

  const configComunicarReevaluacion = {
    secciones: [
      {
        nombre: "Comunicar reevaluacion",
        campos: [
          {
            nombre: "nroItemDni",
            tipoitemformularioid: 1,
            etiqueta: "Número de ítem DNI",
            requerido: true,
          },
          {
            nombre: "nroAnalisisLatu",
            tipoitemformularioid: 4,
            etiqueta: "Número de análisis",
            requerido: true,
          },
        ],
      },
    ],
    submit: { callback: comunicarReevaluacion },
    botones: [{ etiqueta: "Enviar", tipo: "submit" }],
  };

  function comunicarReevaluacion(camposFormulario) {
    setLoader(true);
    accessAPI(
      "POST",
      "dni/juguetes/test/comunicarReevaluacion",
      camposFormulario,
      (respuesta) => {
        alert(JSON.stringify(respuesta));
        setLoader(false);
      },
      (respuesta) => {
        mostrarMensaje({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
        setLoader(false);
      }
    );
  }

  return (
    <div className="tarjeta tresCuartos">
      <Formulario loader={loader} config={configComunicarReevaluacion} />
    </div>
  );
}
