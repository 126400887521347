import "./menuNavegacion.css";
import { NavLink } from "react-router-dom";
import logoHorizontalBlanco from "../../images/logoHorizontalBlanco.png";
import Buscador from "../buscador/Buscador.js";

export default function MenuNavegacion(props) {
  return (
    <header>
      <div className="mainMenuContainer">
        <div className="mainMenu flexContainer">
          <div className="logoContainer flexContainer vertical">
            <NavLink to={"/"}>
              <img src={logoHorizontalBlanco} alt="logo" />
            </NavLink>
          </div>

          <Buscador />
        </div>
      </div>
      <div className="submenu">
        <menu className="flexContainer">
          <NavLink
            to={"/clientes/listado"}
            className={
              props.submenuSeleccionado === "clientes"
                ? "boton seleccionado"
                : "boton"
            }
          >
            Clientes
          </NavLink>

          <NavLink
            to={"/procesos/listado"}
            className={
              props.submenuSeleccionado === "procesos"
                ? "boton seleccionado"
                : "boton"
            }
          >
            Procesos
          </NavLink>
          <NavLink
            to={"/juguetes/operaciones"}
            className={
              props.submenuSeleccionado === "juguetes"
                ? "boton seleccionado"
                : "boton"
            }
          >
            Juguetes
          </NavLink>
        </menu>
      </div>
    </header>
  );
}
