import { useState } from "react";
import CampoEditable from "../../elementos/campoEditable/CampoEditable";
import { downloadFile, formatFecha } from "../../utils/utils.js";
import Boton from "../../elementos/boton/Boton";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function TarjetaDetallesSolicitud(props) {
  const [loaderDescargarArchivo, setLoaderDescargarArchivo] = useState(false);

  const { notify } = useNotification();

  // Descarga el archivo de la solicitud
  function descargarArchivo() {
    setLoaderDescargarArchivo(true);
    downloadFile(
      `admin/archivo/${props.solicitud.archivoproductos.id}`,
      props.solicitud.archivoproductos.nombredescarga,
      () => {
        notify({
          mensaje: "Archivo descargado.",
          temporal: true,
          error: false,
        });
        setLoaderDescargarArchivo(false);
      },
      (respuesta) => {
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
        setLoaderDescargarArchivo(false);
      }
    );
  }

  return (
    <div className="tarjeta chica">
      <h1>Detalles de la solicitud</h1>
      <CampoEditable
        etiqueta="Cliente"
        valor={props.solicitud.cliente.nombrefantasia}
      />
      <CampoEditable
        etiqueta="Nombre Fantasía"
        valor={props.solicitud.nombrefantasia}
      />
      <CampoEditable
        etiqueta="Fecha"
        valor={formatFecha(props.solicitud.fecha)}
      />
      <CampoEditable
        etiqueta="Nombre fabricante"
        valor={props.solicitud.fabrica.fabricantenombre}
      />
      <CampoEditable
        etiqueta="Dirección fabricante"
        valor={props.solicitud.fabrica.fabricantedireccion}
      />
      <CampoEditable
        etiqueta="Nombre fábrica"
        valor={props.solicitud.fabrica.fabricanombre}
      />
      <CampoEditable
        etiqueta="Dirección fábrica"
        valor={props.solicitud.fabrica.fabricadireccion}
      />
      <CampoEditable
        etiqueta="Tipo de certificado"
        valor={props.solicitud.certificadotipo.nombre}
      />
      <CampoEditable
        etiqueta="Referencia a contrato previo"
        valor={props.solicitud.codigocontratoprevio}
      />
      <CampoEditable
        etiqueta="Archivo de productos"
        valor={props.solicitud.archivoproductos.nombredescarga}
      />
      <Boton callback={descargarArchivo} loader={loaderDescargarArchivo}>
        Descargar archivo
      </Boton>
    </div>
  );
}
