import React, { useState, useEffect } from "react";
import "./notificacion.css";
import closeIconoBlanco from "../../images/closeIconoBlanco.png";

export default function Notificaciones(props) {
  const { mensaje, error, callback, temporal, cerrarMensaje } = props;

  const [mostrar, setMostrar] = useState(false);

  useEffect(() => {
    setMostrar(true);

    if (temporal) {
      setTimeout(() => {
        setMostrar(false);
        setTimeout(() => {
          cerrarMensaje();
        }, 2000);
      }, 6000);
    }
  }, [props, error, callback, temporal, cerrarMensaje]);

  function cancelar() {
    setMostrar(false);
    setTimeout(() => {
      cerrarMensaje();
    }, 2000);
  }

  return (
    <div className={`mensajeNotificacionContainer ${mostrar ? "mostrar" : ""}`}>
      <div className={`mensajeNotificacion ${error ? "error" : ""}`}>
        <span>{mensaje}</span>
        {!callback && !temporal && (
          <img
            src={closeIconoBlanco}
            alt="Aceptar"
            className="cerrarIcono"
            onClick={cancelar}
          />
        )}
      </div>
    </div>
  );
}
