import { useState } from "react";
import CampoEditable from "../../elementos/campoEditable/CampoEditable";
import { downloadFile, formatFecha } from "../../utils/utils";
import Boton from "../../elementos/boton/Boton";

export default function TarjetaAlcance(props) {
  const [loaderDescarga, setLoaderDescarga] = useState(false);
  const { alcance } = props;

  // Descarga la evidencia de la alcance de S3
  function descargarEvidencia() {
    if (loaderDescarga) {
      return;
    }
    setLoaderDescarga(true);
    downloadFile(
      `admin/archivo/${alcance.archivo.id}`,
      alcance.archivo.nombredescarga,
      (respuesta) => {
        setLoaderDescarga(false);
      },
      (respuesta) => {
        alert(respuesta[0].msg);
        setLoaderDescarga(false);
      }
    );
  }

  return (
    <div className="tarjeta">
      <CampoEditable etiqueta="Norma" valor={alcance.norma?.numero} />
      <CampoEditable
        etiqueta="Fecha de validez"
        valor={formatFecha(alcance.fechavalidez)}
      />
      <CampoEditable
        etiqueta="Activo"
        valor={alcance.activo}
        campo="activo"
        entidadid={alcance.id}
        endpoint={`admin/laboratorio/alcance`}
        booleano={true}
      />
      <CampoEditable
        etiqueta="Criterio de aceptación"
        valor={alcance.criterio}
      />
      {alcance.enevaluacion && (
        <CampoEditable
          etiqueta="En evaluación"
          valor={alcance.enevaluacion}
          booleano={true}
        />
      )}
      {alcance.normaequivalente && (
        <CampoEditable
          etiqueta="Norma equivalente"
          valor={alcance.normaequivalente}
        />
      )}
      {alcance.documentohomologacion && (
        <CampoEditable
          etiqueta="Documento de homologación"
          valor={alcance.documentohomologacion}
        />
      )}
      {alcance.notas && (
        <CampoEditable etiqueta="Notas" valor={alcance.notas} />
      )}
      {alcance.archivo && (
        <Boton loader={loaderDescarga} callback={descargarEvidencia}>
          Descargar{" "}
          {alcance.archivo.nombredescarga.length < 40
            ? alcance.archivo.nombredescarga
            : alcance.archivo.nombredescarga.substr(0, 31) +
              "... " +
              alcance.archivo.nombredescarga.substr(-4)}
        </Boton>
      )}
    </div>
  );
}
