import { useEffect, useState } from "react";
import { accessAPI } from "../../utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../elementos/loader/Loader";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion";
import Boton from "../../elementos/boton/Boton";
import Footer from "../../elementos/footer/Footer";

export default function BusquedaResultado() {
  const [resultados, setResultados] = useState([]);
  const [loader, setLoader] = useState(true);
  const { buscar } = useParams(); // Obtenemos el término de búsqueda desde los parámetros de la URL
  const navigate = useNavigate();

  useEffect(() => {
    if (buscar) {
      setLoader(true);
      accessAPI(
        "GET",
        `admin/buscador/buscarDatos?query=${buscar}`,
        null,
        (respuesta) => {
          setResultados(respuesta);
          setLoader(false);
        },
        (error) => {
          setLoader(false);
        }
      );
    }
  }, [buscar]);

  // Función para manejar la redirección y limpiar los resultados después de navegar
  const navegacion = (item) => {
    let ruta = "";

    switch (item.tipo) {
      case "cliente":
        ruta = `/clientes/${item.id}`;
        break;
      case "procesos":
        ruta = `/procesos/cliente/${item.id}`;
        break;
      case "empleado":
        ruta = `/clientes/${item.clienteid}`;
        break;
      case "solicitud":
        ruta = `/solicitud/${item.id}`;
        break;
      case "auditor":
        ruta = `/proveedores/auditores/${item.id}`;
        break;
      case "laboratorio":
        ruta = `/proveedores/laboratorios/listado/${item.id}`;
        break;
      case "proceso":
        ruta = `/proceso/${item.id}`;
        break;
      default:
        ruta = "/";
        return;
    }

    setResultados([]);
    navigate(ruta);
  };

  return (
    <div className="seccion">
      <MenuNavegacion submenuSeleccionado="" />
      <Footer />

      <div className="contenido">
        <div className="tarjeta">
          <div>
            {loader && <Loader>Cargando Resultados...</Loader>}
            {resultados.length > 0 && (
              <>
                {resultados.map((item, index) => {
                  return (
                    <Boton
                      callback={() => {
                        navegacion(item);
                      }}
                      clases="texto"
                      key={index}
                    >
                      <span className="gris"> {item.tipoEtiqueta}:</span>{" "}
                      {item.nombre}
                    </Boton>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
