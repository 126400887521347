import { useEffect, useState } from "react";
import { accessAPI, formatFecha } from "../../utils/utils.js";
import { useNavigate } from "react-router-dom";
import Loader from "../../elementos/loader/Loader";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion.js";
import Footer from "../../elementos/footer/Footer.js";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

import DataTable from "react-data-table-component";

export default function TableroDeProcesos() {
  const [loaderProcesos, setLoaderProcesos] = useState(true);
  const [procesos, setProcesos] = useState([]);

  const { notify } = useNotification();

  const navigate = useNavigate();

  const columnas = [
    {
      name: "Proceso",
      selector: (proceso) =>
        `${proceso.certificadotipo?.procesotipo?.letra}-${proceso.numero}`,
      sortable: true,
    },
    {
      name: "Cliente",
      selector: (proceso) => proceso.cliente.razonsocial,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      name: "Producto",
      selector: (proceso) =>
        `${proceso.certificadotipo.procesotipo.nombre} - ${proceso.certificadotipo.nombre}`,
      sortable: true,
      wrap: true,
      grow: 2,
    },
    {
      name: "Nombre",
      selector: (proceso) => proceso.nombrefantasia,
      wrap: true,
      grow: 2,
    },
    {
      name: "Norma",
      selector: (proceso) =>
        proceso.norma ? proceso.norma.numero : "Sin norma",
      sortable: true,
      wrap: true,
    },

    {
      name: "Certificado",
      selector: (proceso) => {
        return (
          <span>
            {proceso.certificado[0] && (
              <>
                <span className="punto verde" />
                {formatFecha(proceso.certificado[0]?.fechaemision)}
              </>
            )}
            {!proceso.certificado[0] && (
              <>
                <span className="punto amarillo" />
                No emitido
              </>
            )}
          </span>
        );
      },
      grow: 2,
    },
  ];

  // Carga los procesos
  useEffect(() => {
    accessAPI(
      "GET",
      "admin/proceso",
      null,
      (response) => {
        setProcesos(response.procesos);
        setLoaderProcesos(false);
      },
      (respuesta) => {
        // Si la API da error, devuelve el error al cliente
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
        setLoaderProcesos(false);
      }
    );
  }, [notify]);

  return (
    <div className="seccion clientes">
      <MenuNavegacion submenuSeleccionado="procesos" />
      <Footer />
      <div className="contenido">
        {loaderProcesos ? (
          <Loader>Cargando procesos</Loader>
        ) : (
          <div className="tarjeta">
            <DataTable
              columns={columnas}
              data={procesos}
              highlightOnHover
              pointerOnHover
              striped
              onRowClicked={(row, event) => {
                navigate("/proceso/" + row.id);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
