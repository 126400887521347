import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { accessAPI } from "../../utils/utils.js";
import Formulario from "../../elementos/formulario/Formulario.js";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion.js";
import Footer from "../../elementos/footer/Footer.js";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function NuevoProceso() {
  const [loaderEnvio, setLoaderEnvio] = useState(false);

  const { notify } = useNotification();

  const navigate = useNavigate();

  const crearProcesoFormConfig = {
    secciones: [
      {
        nombre: "Datos del proceso",
        campos: [
          {
            nombre: "nombrefantasia",
            tipoitemformularioid: 1,
            etiqueta: "Nombre del proceso",
            requerido: true,
          },
          {
            nombre: "clienteid",
            tipoitemformularioid: 5,
            etiqueta: "Cliente",
            requerido: true,
            opciones: "admin/cliente",
            sinOpcionInicial: true,
          },
          {
            nombre: "certificadotipoid",
            tipoitemformularioid: 5,
            etiqueta: "Tipo de certificado",
            requerido: true,
            opciones: "admin/config/certificadotipo",
            sinOpcionInicial: true,
          },
          {
            nombre: "esquemaid",
            tipoitemformularioid: 5,
            etiqueta: "Esquema de certificación",
            requerido: true,
            opciones: "admin/config/esquema",
            sinOpcionInicial: true,
            queryparams: ["certificadotipoid"],
          },
          {
            nombre: "normaid",
            tipoitemformularioid: 5,
            etiqueta: "Norma",
            requerido: false,
            opciones: "admin/norma",
            sinOpcionInicial: true,
            queryparams: ["certificadotipoid"],
          },
          {
            nombre: "fabricaexistenteid",
            tipoitemformularioid: 5,
            etiqueta: "Fabricante existente",
            requerido: true,
            opciones: "admin/proceso/fabricas",
            sinOpcionInicial: true,
            queryparams: ["clienteid"],
          },
          {
            nombre: "fabricantenombre",
            tipoitemformularioid: 1,
            etiqueta: "Nombre del fabricante",
          },
          {
            nombre: "fabricantedireccion",
            tipoitemformularioid: 1,
            etiqueta:
              "Dirección del fabricante - formato dir,dir2,cp,estado,ciudad,pais",
            regex: "^[^,]+(?:,[^,]*){5}$",
          },
          {
            nombre: "fabricanombre",
            tipoitemformularioid: 1,
            etiqueta: "Nombre de la fábrica",
          },
          {
            nombre: "fabricadireccion",
            tipoitemformularioid: 1,
            etiqueta:
              "Dirección de la fábrica - formato dir,dir2,cp,estado,ciudad,pais",
            regex: "^[^,]+(?:,[^,]*){5}$",
          },
        ],
      },
    ],
    submit: { callback: crearProcesoSubmit },
    botones: [{ etiqueta: "Crear proceso", tipo: "submit", clases: "azul" }],
  };

  function crearProcesoSubmit(camposFormulario) {
    setLoaderEnvio(true);
    accessAPI(
      "POST",
      "admin/proceso",
      camposFormulario,
      (respuesta) => {
        navigate(`/proceso/${respuesta.id}`);
      },
      (respuesta) => {
        // Si la API da error, devuelve el error al cliente
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
        setLoaderEnvio(false);
      }
    );
  }

  return (
    <div className="seccion procesos">
      <MenuNavegacion submenuSeleccionado="procesos" />
      <Footer />
      <div className="contenido">
        <div className="tarjetasContainer">
          <div className="tarjeta tresCuartos">
            <Formulario config={crearProcesoFormConfig} loader={loaderEnvio} />
          </div>
        </div>
      </div>
    </div>
  );
}
