import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion";
import Footer from "../../elementos/footer/Footer";
import Loader from "../../elementos/loader/Loader";
import emailIcon from "../../images/email.png";
import "./configEventos.css";

import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

import { accessAPI } from "../../utils/utils";

import DataTable from "react-data-table-component";

export default function ConfigEventos() {
  const [loader, setLoader] = useState(true);
  const [eventoTipos, setEventoTipos] = useState();

  const { notify } = useNotification();

  const navigate = useNavigate();

  const columnas = [
    {
      name: "Descripción",
      selector: (evento) => evento.descripcion,
      sortable: true,
      wrap: true,
    },
    {
      name: "Config",
      selector: (evento) => {
        if (evento.cuentaConfig) {
          return (
            <img className="iconoSobre" src={emailIcon} alt="Tiene configs" />
          );
        }
      },
      sortable: true,
      wrap: true,
      maxWidth: "1px",
    },
  ];

  // Cuando carga el componente va a buscar los tipos de eventos
  useEffect(() => {
    accessAPI(
      "GET",
      "admin/comunicacion/eventotipos",
      null,
      (respuesta) => {
        setEventoTipos(respuesta);
      },
      (respuesta) => {
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
      }
    );
  }, [notify]);

  // Cuando carga los tipos de eventos apaga el loader
  useEffect(() => {
    if (eventoTipos) {
      setLoader(false);
    }
  }, [eventoTipos]);

  return (
    <div className="seccion">
      <MenuNavegacion submenuSeleccionado="comunicacion" />
      <Footer />
      <div className="contenido">
        {loader && <Loader>Cargando eventos</Loader>}
        {!loader && eventoTipos && (
          <div className="tarjetasContainer">
            <div className="tarjeta ">
              <h1>
                Seleccione un tipo de evento para configurar las comunicaciones
              </h1>
              <DataTable
                columns={columnas}
                data={eventoTipos}
                highlightOnHover
                pointerOnHover
                striped
                onRowClicked={(row, event) => {
                  navigate(`/comunicacion/configeventos/${row.id}`);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
