import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import { NotificationProvider } from "./elementos/notificacion/NotificationProvider";

export default function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <NotificationProvider>
          <Router />
        </NotificationProvider>
      </BrowserRouter>
    </div>
  );
}
