import React, { createContext, useContext, useState } from "react";
import Notificaciones from "./Notificaciones";

const NotificationContext = createContext();

export function useNotification() {
  return useContext(NotificationContext);
}

export const NotificationProvider = ({ children }) => {
  const [mensaje, setMensaje] = useState("");
  const [error, setError] = useState(false);
  const [temporal, setTemporal] = useState();

  const notify = ({ mensaje, error = false, temporal = false }) => {
    setMensaje(mensaje);
    setError(error);
    setTemporal(temporal);
  };

  function cerrarMensaje() {
    setMensaje(null);
    setError(null);
    setTemporal(null);
  }

  return (
    <NotificationContext.Provider value={{ notify }}>
      {children}
      {mensaje && (
        <>
          <div>chau</div>
          <Notificaciones
            mensaje={mensaje}
            error={error}
            temporal={temporal}
            cerrarMensaje={cerrarMensaje}
          />
        </>
      )}
    </NotificationContext.Provider>
  );
};
