import { useState } from "react";
import CampoEditable from "../../elementos/campoEditable/CampoEditable";
import { formatFecha, accessAPI, downloadFile } from "../../utils/utils.js";
import Boton from "../../elementos/boton/Boton";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function EmisionCertificado(props) {
  const [loaderDescargaPDF, setLoaderDescargaPDF] = useState(false);
  const [loaderDescargaXML, setLoaderDescargaXML] = useState(false);
  const [loaderEnvioURSEA, setLoaderEnvioURSEA] = useState(false);
  const [emision, setEmision] = useState(props.emision);

  const { proceso } = props;

  const { notify } = useNotification();

  function descargarXML() {
    setLoaderDescargaXML(true);
    downloadFile(
      `admin/certificado/descargarxmlemision/${emision.id}`,
      emision.archivoxml.nombredescarga,
      (respuesta) => {
        setLoaderDescargaXML(false);
      },
      (respuesta) => {
        notify({ mensaje: respuesta[0].msg, error: true, temporal: true });
        setLoaderDescargaXML(false);
      }
    );
  }

  // Descarga el certificado
  function descargarCertificado() {
    setLoaderDescargaPDF(true);
    downloadFile(
      `admin/certificado/descargarpdfemision/${emision.id}`,
      emision.archivopdf.nombredescarga,
      (respuesta) => {
        setLoaderDescargaPDF(false);
      },
      (respuesta) => {
        notify({ mensaje: respuesta[0].msg, error: true, temporal: true });
        setLoaderDescargaPDF(false);
      }
    );
  }

  function enviarAURSEA() {
    setLoaderEnvioURSEA(true);
    accessAPI(
      "PUT",
      `admin/certificado/enviarurseaemision/${emision.id}`,
      null,
      (respuesta) => {
        const hoy = formatFecha(new Date());
        let emisionActual = emision;
        emision.fechaenvioursea = hoy;
        setEmision(emisionActual);
        setLoaderEnvioURSEA(false);
      },
      (respuesta) => {
        notify({ mensaje: respuesta[0].msg, error: true, temporal: true });
        setLoaderEnvioURSEA(false);
      }
    );
  }

  return (
    <div className="tarjeta">
      <h2>{emision.notas}</h2>
      <CampoEditable
        etiqueta="Fecha de emision"
        valor={formatFecha(emision.fechaemision)}
      />
      {proceso.certificadotipo.gestionadoursea && (
        <CampoEditable
          etiqueta="Fecha de envío a URSEA"
          valor={
            emision.fechaenvioursea
              ? formatFecha(emision.fechaenvioursea)
              : "No enviado"
          }
        />
      )}
      {emision.archivopdf?.id && (
        <div>
          <Boton
            clases="verde centrado"
            loader={loaderDescargaPDF}
            callback={descargarCertificado}
          >
            Descargar certificado
          </Boton>
        </div>
      )}
      {proceso.certificadotipo.gestionadoursea && emision.archivoxml?.id && (
        <div>
          <Boton
            loader={loaderDescargaXML}
            callback={descargarXML}
            clases="verde centrado"
          >
            Descargar XML
          </Boton>
        </div>
      )}
      {proceso.certificadotipo.gestionadoursea &&
        !emision.fechaenvioursea &&
        emision.archivoxml && (
          <>
            <div className="division"></div>
            <div>
              <Boton
                clases="rojo centrado"
                confirmacion={true}
                callback={enviarAURSEA}
                loader={loaderEnvioURSEA}
              >
                Enviar a URSEA
              </Boton>
            </div>
          </>
        )}
      {emision.productoalta?.length !== 0 && (
        <div>
          <div className="division" />
          <h2>Altas en esta emisión:</h2>
          <ul>
            {emision.productoalta?.map((producto) => {
              return (
                <li key={producto.id}>
                  {producto.producto_x_productocampospropios.map(
                    (campoPropio) => {
                      return (
                        <span key={campoPropio.id}>
                          <span className="gris">
                            {" "}
                            {campoPropio.productocampospropios.nombre}:{" "}
                          </span>
                          {campoPropio.valor}{" "}
                        </span>
                      );
                    }
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {emision.productobaja?.length !== 0 && (
        <div>
          <div className="division" />
          <h2>Bajas en esta emisión:</h2>
          <ul>
            {emision.productobaja?.map((producto) => {
              return (
                <li key={producto.id}>
                  {producto.producto_x_productocampospropios.map(
                    (campoPropio) => {
                      return (
                        <span key={campoPropio.id}>
                          <span className="gris">
                            {" "}
                            {campoPropio.productocampospropios.nombre}:{" "}
                          </span>
                          {campoPropio.valor}{" "}
                        </span>
                      );
                    }
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {emision.cambioproductocertificado?.length !== 0 && (
        <>
          <div className="division"></div>
          <div>
            <h2>Cambios en esta emision:</h2>
            <ul>
              {emision.cambioproductocertificado?.map((cambio) => {
                return (
                  <li key={cambio.id}>
                    {cambio.producto_x_productocampospropios && (
                      <span>
                        {
                          cambio.producto_x_productocampospropios
                            ?.productocampospropios.nombre
                        }{" "}
                        - de "{cambio.valoranterior}" a "{cambio.valornuevo}"
                      </span>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      )}
    </div>
  );
}
