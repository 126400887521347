import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../elementos/loader/Loader";
import { accessAPI } from "../../utils/utils.js";
import Formulario from "../../elementos/formulario/Formulario";
import Capitulo from "../../elementos/documentonorma/Capitulo";

export default function Documento() {
  const [loader, setLoader] = useState(true);
  const [documento, setDocumento] = useState();
  const [loaderMarcarVigente, setLoaderMarcarVigente] = useState(false);
  const [creandoNuevaVersion, setCreandoNuevaVersion] = useState(false);
  const [reiniciarFormulario, setReiniciarFormulario] = useState(1);
  const [agregandoCapitulo, setAgregandoCapitulo] = useState(false);

  const navigate = useNavigate();

  // Carga el id de la norma de la URL
  const { documentoId } = useParams();

  const agregarCapituloFormConfig = {
    campos: [
      {
        nombre: "numero",
        tipoitemformularioid: 4,
        etiqueta: "Número",
        requerido: false,
      },
      {
        nombre: "titulo",
        tipoitemformularioid: 1,
        etiqueta: "Título",
        requerido: true,
      },
    ],
    submit: { callback: agregarCapitulo },
    botones: [
      { etiqueta: "Agregar", tipo: "submit" },
      {
        etiqueta: "Cancelar",
        callback: () => {
          setAgregandoCapitulo(false);
        },
        tipo: "button",
      },
    ],
  };

  const nuevaVersionFormConfig = {
    campos: [
      {
        nombre: "version",
        tipoitemformularioid: 1,
        etiqueta: "Versión",
        requerido: true,
      },
    ],
    submit: { callback: crearNuevaVersion, etiqueta: "Crear" },
  };

  // Cuando carga el componente, trae el formulario de la API
  // Y los tipos
  useEffect(() => {
    accessAPI(
      "GET",
      `admin/documento/${documentoId}`,
      null,
      (response) => {
        setDocumento(response);
      },
      (response) => {
        console.log(response);
      }
    );
  }, [documentoId]);

  // Cuando recibe el formulario de la API apaga el loader
  useEffect(() => {
    if (documento) {
      setLoader(false);
      setLoaderMarcarVigente(false);
      setCreandoNuevaVersion(false);
    }
  }, [documento]);

  // Función para liberar el formulario
  function marcarDocumentoVigente() {
    if (
      !window.confirm(
        "¿Seguro que desea marcar este formulario como vigente? Suplantrá al actual vigente."
      )
    ) {
      return;
    }
    setLoaderMarcarVigente(true);
    accessAPI(
      "PUT",
      `admin/documento/vigente/${documentoId}`,
      null,
      (datosLiberacion) => {
        // Carga los datos de liberación en el formulario
        let documentoActual = { ...documento };
        documentoActual.fechaliberacion = datosLiberacion.param.fechaliberacion;
        documentoActual.usuarioliberadornombre =
          datosLiberacion.param.usuarioliberadornombre;
        documentoActual.usuarioliberadorapellido =
          datosLiberacion.param.usuarioliberadorapellido;
        documentoActual.vigente = 1;
        setDocumento(documentoActual);
      },
      (response) => {
        alert(response[0].msg);
        setLoaderMarcarVigente(false);
      }
    );
  }

  // Función ejecutada al presionar el botón de agregar capítulo
  function agregarCapitulo(capitulo) {
    // Le agrega el padreId = 0 y el tipo para que el punto agregado sea un capítulo
    capitulo.padreId = 0;
    capitulo.tipoitemdocumentoid = 1;
    // Envía el capítulo a la API
    accessAPI(
      "POST",
      `admin/documento/punto/${documentoId}`,
      capitulo,
      (response) => {
        // Si el capítulo es agregado correctamente, lo agrega a la norma actual
        let documentoActual = { ...documento };
        capitulo.contenido = [];
        documentoActual.capitulos.push(response);
        setDocumento(documentoActual);
        setAgregandoCapitulo(false);
      },
      (response) => {
        alert(response[0].msg);
      }
    );
  }

  // Crea una nueva versión del formulario editable
  // y navega ahí
  function crearNuevaVersion(opciones) {
    accessAPI(
      "POST",
      `admin/documento/${documentoId}/duplicar`,
      opciones,
      (nuevoFormularioId) => {
        navigate(`/documento/${nuevoFormularioId.id}`);
      },
      (response) => {
        alert(response[0].msg);
        setReiniciarFormulario(reiniciarFormulario + 1);
      }
    );
  }

  return (
    <div>
      {loader && <Loader>Cargando formulario</Loader>}
      {!loader && documento && (
        <div>
          {!documento.fechaliberacion && (
            <div>
              Este documento no está liberado
              {loaderMarcarVigente && <Loader>Procesando</Loader>}
              {!loaderMarcarVigente && !documento.noLiberable && (
                <div>
                  <button onClick={marcarDocumentoVigente}>
                    Marcar versión vigente
                  </button>
                </div>
              )}
            </div>
          )}
          {documento.vigente && <div>Este documento es el vigente.</div>}
          {!documento.vigente && (
            <div>
              <span>Este documento no es el vigente</span>
              {documento.versionVigente && (
                <span>
                  <a href={`${documento.versionVigente}`}>, ir al vigente</a>
                </span>
              )}
            </div>
          )}
          <div>
            <span>
              {documento.codigo} v. {documento.version} - {documento.nombre}
            </span>
          </div>
          {documento.fechaliberacion && (
            <div>
              Liberado por {documento.usuarioliberadornombre}{" "}
              {documento.usuarioliberadorapellido} en{" "}
              {new Date(documento.fechaliberacion * 1000).toLocaleString(
                "es-UY"
              )}
            </div>
          )}
          {documento.capitulos.map((capitulo) => {
            return (
              <Capitulo
                capitulo={capitulo}
                tipodocumentonorma="documento"
                documentonorma={documentoId}
                bloqueado={documento.vigente}
                key={capitulo.id}
              />
            );
          })}
          {!documento.vigente && (
            <>
              {!agregandoCapitulo && (
                <button
                  onClick={() => {
                    setAgregandoCapitulo(true);
                  }}
                >
                  Agregar capítulo
                </button>
              )}
              {agregandoCapitulo && (
                <div>
                  Agregar capítulo
                  <Formulario
                    config={agregarCapituloFormConfig}
                    key={documento.capitulos.length}
                  />
                </div>
              )}
            </>
          )}
          {documento.vigente && (
            <div>
              <div>Los documentos liberados no pueden ser modificados.</div>
              {!creandoNuevaVersion && (
                <button
                  onClick={() => {
                    setCreandoNuevaVersion(true);
                  }}
                >
                  Crear una nueva versión de este documento
                </button>
              )}
              {creandoNuevaVersion && (
                <Formulario
                  config={nuevaVersionFormConfig}
                  key={reiniciarFormulario}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
