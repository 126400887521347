import { useState } from "react";
import { accessAPI } from "../../utils/utils.js";
import Formulario from "../formulario/Formulario";

export default function Punto(props) {
  const [mostrandoBotonAgregar, setMostrandoBotonAgregar] = useState(false);
  const [agregandoContenido, setAgregandoContenido] = useState(false);

  const agregarContenidoPuntoFormConfig = {
    campos: [
      {
        nombre: "tipoitemdocumentoid",
        tipoitemformularioid: 5,
        etiqueta: "Tipo",
        requerido: true,
        sinOpcionInicial: true,
        opciones: props.tiposItemDocumento,
      },
      {
        nombre: "numero",
        tipoitemformularioid: 1,
        etiqueta: "Numero",
        requerido: false,
      },
      {
        nombre: "titulo",
        tipoitemformularioid: 1,
        etiqueta: "Título",
        requerido: false,
      },
      {
        nombre: "contenido",
        tipoitemformularioid: 1,
        etiqueta: "Contenido",
        requerido: false,
      },
    ],
    submit: { callback: enviarContenido },
    botones: [
      { etiqueta: "Enviar", tipo: "submit" },
      {
        etiqueta: "Cancelar",
        callback: () => {
          setAgregandoContenido(false);
        },
        tipo: "button",
      },
    ],
  };

  const agregarContenidoItemFormConfig = {
    campos: [
      {
        nombre: "tipoitemdocumentoid",
        tipoitemformularioid: 5,
        etiqueta: "Tipo",
        requerido: true,
        opciones: props.tiposItemDocumentoHijosItem,
      },
      {
        nombre: "contenido",
        tipoitemformularioid: 1,
        etiqueta: "Contenido",
        requerido: true,
      },
    ],
    submit: { callback: enviarContenido },
    botones: [
      { etiqueta: "Enviar", tipo: "submit" },
      {
        etiqueta: "Cancelar",
        callback: () => {
          setAgregandoContenido(false);
        },
        tipo: "button",
      },
    ],
  };

  // Callback del form de agregar contenido
  function enviarContenido(contenidoAAgregar) {
    contenidoAAgregar.padreId = props.punto.id;

    accessAPI(
      "POST",
      `admin/${props.tipodocumentonorma}/punto/${props.documentonorma}`,
      contenidoAAgregar,
      (response) => {
        // Cierra el formulario
        setAgregandoContenido(false);
        // Agrega el contenido al state
        if (props.punto.subpuntos) {
          props.punto.subpuntos.push(response);
          return;
        }
        props.punto.subpuntos = [response];
      },
      (response) => {
        console.log(response);
      }
    );
  }

  return (
    <div className={props.subpunto ? "indentado" : ""}>
      {props.punto.tipoitemdocumentoid === 1 && (
        <div>
          <h1>{props.punto.titulo}</h1>
        </div>
      )}
      {props.punto.tipoitemdocumentoid === 2 && (
        <p className="parrafo">{props.punto.contenido}</p>
      )}
      {props.punto.tipoitemdocumentoid === 3 && (
        <div
          className="item"
          onMouseEnter={() => {
            setMostrandoBotonAgregar(true);
          }}
          onMouseLeave={() => {
            if (!agregandoContenido) {
              setMostrandoBotonAgregar(false);
            }
          }}
        >
          {props.punto.contenido}
          {mostrandoBotonAgregar && (
            <span>
              {!agregandoContenido && (
                <button
                  onClick={() => {
                    setAgregandoContenido(true);
                  }}
                >
                  Agregar al punto
                </button>
              )}
            </span>
          )}
        </div>
      )}
      {props.punto.tipoitemdocumentoid === 4 && (
        <p className="nota">{props.punto.contenido}</p>
      )}
      {props.punto.tipoitemdocumentoid === 5 && (
        <div
          onMouseEnter={() => {
            setMostrandoBotonAgregar(true);
          }}
          onMouseLeave={() => {
            if (!agregandoContenido) {
              setMostrandoBotonAgregar(false);
            }
          }}
        >
          {props.punto.numero && <span>{props.punto.numero} </span>}
          {props.punto.titulo && <span>{props.punto.titulo} </span>}
          {mostrandoBotonAgregar && (
            <span>
              {!agregandoContenido && (
                <button
                  onClick={() => {
                    setAgregandoContenido(true);
                  }}
                >
                  Agregar al punto
                </button>
              )}
            </span>
          )}
          {props.punto.contenido && <div>{props.punto.contenido} </div>}
        </div>
      )}
      {props.punto.subpuntos &&
        props.punto.subpuntos.map((subpunto) => {
          return (
            <Punto
              punto={subpunto}
              documentonorma={props.documentonorma}
              tipodocumentonorma={props.tipodocumentonorma}
              tiposItemDocumento={props.tiposItemDocumento}
              tiposItemDocumentoHijosItem={props.tiposItemDocumentoHijosItem}
              subpunto={true}
              key={subpunto.id}
            />
          );
        })}
      {agregandoContenido && props.punto.tipoitemdocumentoid === 5 && (
        <div>
          <Formulario config={agregarContenidoPuntoFormConfig} />
        </div>
      )}
      {agregandoContenido && props.punto.tipoitemdocumentoid === 3 && (
        <div>
          <Formulario config={agregarContenidoItemFormConfig} />
        </div>
      )}
    </div>
  );
}
