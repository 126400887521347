import { useState, useEffect } from "react";
import TarjetaProductoProceso from "./TarjetaProductoProceso";
import ProcesoFormularioProducto from "./ProcesoFormularioProducto";
import ProcesoSubirPlanillaProductos from "./ProcesoSubirPlanillaProductos";
import DataTable from "react-data-table-component";
import Boton from "../../elementos/boton/Boton";
import { downloadFile } from "../../utils/utils";

export default function ProcesoProductos(props) {
  const { proceso } = props;
  const [productos, setProductos] = useState(props.proceso.producto || []);
  const [columnas, setColumnas] = useState();
  const [loaderDescargaPlantilla, setLoaderDescargarPlantilla] =
    useState(false);

  // Cuando carga el proceso, arma la tabla de productos
  useEffect(() => {
    // Recorre las caraterísticas de productos y se queda con los que se muestran en el certificado
    let columnasTabla = [];
    // Necesita el index para encontrar el contenido de los campos que se muestran en el certificado
    for (const [
      index,
      campo,
    ] of proceso.certificadotipo.productocampospropios.entries()) {
      if (campo.mostrarencertificado) {
        columnasTabla.push({
          name: campo.nombre,
          selector: (producto) =>
            producto.producto_x_productocampospropios[index]?.valor,
        });
      }
    }
    columnasTabla.push({
      name: "Mod.",
      selector: (producto) => {
        if (
          producto.cambioproductocertificado?.length ||
          producto.vigente === 0
        ) {
          return <span className="punto amarillo" />;
        }
      },
      maxWidth: "1px",
    });
    setColumnas(columnasTabla);
  }, [proceso.certificadotipo.productocampospropios]);

  // Descarga la planilla de template de productos que utilizan los auditores para proceso
  function descargarPlantilla() {
    setLoaderDescargarPlantilla(true);
    downloadFile(
      `admin/proceso/plantilla/${proceso.certificadotipo.id}`,
      `Plantilla productos auditoria - ${proceso.certificadotipo.codigo}`,
      (respuesta) => {
        setLoaderDescargarPlantilla(false);
      },
      (respuesta) => {
        setLoaderDescargarPlantilla(false);
        alert(respuesta[0].msg);
      }
    );
  }

  // Función ejecutada al agregar un producto
  function agregarProductoAProceso(respuesta) {
    setProductos((productos) => [...productos, respuesta.productoParaDevolver]);
  }

  // Función ejecutada por el componente TarjetaProductoProceso cuando corresponde eliminar un producto del proceso
  function eliminarProductoDeProceso(productoIdAEliminar) {
    let productosActuales = [...productos];
    for (const [index, producto] of productosActuales.entries()) {
      if (producto.id === productoIdAEliminar) {
        productosActuales.splice(index, 1);
      }
    }
    setProductos(productosActuales);
  }

  return (
    <>
      <h1>Productos:</h1>
      <Boton
        clases="centrado"
        callback={descargarPlantilla}
        loader={loaderDescargaPlantilla}
      >
        Descargar plantilla
      </Boton>
      <div className="tarjeta">
        <DataTable
          columns={columnas}
          data={productos}
          expandableRows
          expandableRowsComponent={TarjetaProductoProceso}
          expandableRowsComponentProps={{
            eliminarProductoDeProceso: eliminarProductoDeProceso,
          }}
          expandOnRowClicked
          expandableRowsHideExpander
          dense
        ></DataTable>
      </div>
      <ProcesoFormularioProducto
        proceso={proceso}
        agregarProductoAProceso={agregarProductoAProceso}
      />
      <ProcesoSubirPlanillaProductos proceso={proceso} />
    </>
  );
}
