import { useState, useEffect } from "react";
import { accessAPI, formatFecha } from "../../utils/utils";
import Formulario from "../../elementos/formulario/Formulario";
import CampoEditable from "../../elementos/campoEditable/CampoEditable";
import Loader from "../../elementos/loader/Loader";

export default function ProcesoFormularioEnsayo(props) {
  const [loader, setLoader] = useState(true);
  const [loaderAgregarEnsayo, setLoaderAgregarEnsayo] = useState(false);
  const [laboratorios, setLaboratorios] = useState();

  let formAgregarEnsayoConfig = {
    campos: [
      {
        nombre: "codigo",
        tipoitemformularioid: 1,
        etiqueta: "Código del reporte",
        requerido: true,
      },
      {
        nombre: "fechaemision",
        tipoitemformularioid: 7,
        etiqueta: "Fecha emisión",
        requerido: true,
      },
      {
        nombre: "laboratorioid",
        tipoitemformularioid: 5,
        etiqueta: "Laboratorio",
        requerido: true,
        opciones: laboratorios || [{ value: 0, etiqueta: "no hay" }],
        sinOpcionInicial: true,
      },
    ],
    submit: { callback: submitAgregarEnsayo },
    botones: [{ etiqueta: "Agregar ensayo", tipo: "submit", clases: "verde" }],
  };

  // Función ejecutada al agregar un producto
  function submitAgregarEnsayo(contenidoCampos) {
    setLoaderAgregarEnsayo(true);
    accessAPI(
      "POST",
      `admin/proceso/reporteensayo/${props.proceso.actividad[0].id}`,
      contenidoCampos,
      (respuesta) => {
        props.agregarTestReportAProceso(respuesta);
        setLoaderAgregarEnsayo(false);
      },
      (respuesta) => {
        console.log(respuesta);
      }
    );
  }

  // Cuando carga el componente, va a buscar la lista de laboratorios
  useEffect(() => {
    let url = "admin/laboratorio";
    if (props.proceso.norma) {
      url = `admin/laboratorio?norma=${props.proceso.norma.id}`;
    }

    accessAPI(
      "GET",
      url,
      null,
      (respuesta) => {
        setLaboratorios(respuesta);
      },
      (respuesta) => {
        console.log(respuesta);
      }
    );
  }, [props.proceso.norma?.id, props.proceso.norma]);

  // Cuando carga los laboratorios, apaga el loader
  useEffect(() => {
    if (laboratorios) {
      setLoader(false);
    }
  }, [laboratorios]);

  return (
    <div>
      <div className="titulo">Reportes de ensayo</div>
      {props.testReports &&
        props.testReports.length > 0 &&
        props.testReports.map((unReporteEnsayo) => {
          return (
            <div
              className="centradoCertificados tarjetaCertificados tarjeta"
              key={unReporteEnsayo.id}
            >
              <CampoEditable
                etiqueta="Código"
                campo="codigo"
                valor={unReporteEnsayo.codigo}
                entidadid={unReporteEnsayo.id}
              />
              {/* Le sumo 1 a la fecha para ajustar la zona horaria, hay que corregirlo */}
              <CampoEditable
                etiqueta="Fecha de emisión"
                // campo="fechaemision"
                valor={formatFecha(unReporteEnsayo.fechaemision)}
                // endpoint="admin/proceso"
                // entidadid={unReporteEnsayo.id}
                // esFecha={true}
              />
              <CampoEditable
                etiqueta="Emitido por"
                campo="laboratorioid"
                valor={unReporteEnsayo.laboratorio?.nombrefantasia}
                entidadid={unReporteEnsayo.id}
                dropdownurl="admin/laboratorio"
              />
            </div>
          );
        })}
      <div className="centradoCertificados tarjetaCertificados tarjeta ">
        {loader && <Loader>Cargando</Loader>}
        {!loader && props.testReports && (
          <>
            <h1>Agregar ensayo de certificación</h1>
            <Formulario
              key={props.testReports.length}
              config={formAgregarEnsayoConfig}
              loader={loaderAgregarEnsayo}
            />
          </>
        )}
      </div>
    </div>
  );
}
