import { useState } from "react";
import Formulario from "../../elementos/formulario/Formulario";
import { accessAPI } from "../../utils/utils";

export default function JuguetesOperaciones(props) {
  const [loader, setLoader] = useState();

  const { mostrarMensaje } = props;

  const configComunicarReevaluacion = {
    secciones: [
      {
        nombre: "Obtener test report",
        campos: [
          {
            nombre: "nroItemDni",
            tipoitemformularioid: 1,
            etiqueta: "Número de ítem DNI",
            requerido: true,
          },
        ],
      },
    ],
    submit: { callback: obtTestReport },
    botones: [{ etiqueta: "GET", tipo: "submit" }],
  };

  function obtTestReport(camposFormulario) {
    setLoader(true);
    accessAPI(
      "POST",
      "dni/juguetes/test/obtTestReport",
      camposFormulario,
      (respuesta) => {
        alert(JSON.stringify(respuesta));
        setLoader(false);
      },
      (respuesta) => {
        mostrarMensaje({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
        setLoader(false);
      }
    );
  }

  return (
    <div className="tarjeta tresCuartos">
      <Formulario loader={loader} config={configComunicarReevaluacion} />
    </div>
  );
}
