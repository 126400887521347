import { useState } from "react";
import Formulario from "../../elementos/formulario/Formulario";
import { accessAPI } from "../../utils/utils";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function NuevoConfigEvento(props) {
  const [loaderFormulario, setLoaderFormulario] = useState(false);

  const { notify } = useNotification();

  const { eventoTipoId, agregarConfiguracion } = props;

  const nuevoConfigEventoFormConfig = {
    secciones: [
      {
        nombre: "Nueva configuración de evento",
        campos: [
          {
            nombre: "asunto",
            tipoitemformularioid: 1,
            etiqueta: "Asunto del mail o notificación",
            requerido: true,
          },
          {
            nombre: "texto",
            tipoitemformularioid: 11,
            etiqueta: "Texto del mail o notificación",
            requerido: true,
          },
          {
            nombre: "destinatarioemail",
            tipoitemformularioid: 1,
            etiqueta: "Destinatario del email",
            requerido: true,
          },
          {
            nombre: "bccemail",
            tipoitemformularioid: 1,
            etiqueta: "Copia oculta (BCC) del email",
          },
        ],
      },
    ],
    submit: { callback: crearConfiguracionEvento },
    botones: [{ etiqueta: "Crear", tipo: "submit", clases: "verde" }],
  };

  // Callback del submit del formulario de creación de configuración
  function crearConfiguracionEvento(contenidoCampos) {
    setLoaderFormulario(true);
    accessAPI(
      "POST",
      `admin/comunicacion/eventotipos/${eventoTipoId}`,
      contenidoCampos,
      (respuesta) => {
        setLoaderFormulario(false);
        agregarConfiguracion(respuesta);
        notify({
          mensaje: "Configuración generada correctamente",
          temporal: true,
        });
      },
      (respuesta) => {
        setLoaderFormulario(false);
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
      }
    );
  }

  return (
    <div className="tarjeta conEspacio">
      <Formulario
        config={nuevoConfigEventoFormConfig}
        loader={loaderFormulario}
      />
    </div>
  );
}
