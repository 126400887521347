import { useState, useEffect } from "react";
import { accessAPI } from "../../utils/utils.js";
import { useNavigate } from "react-router-dom";
import Loader from "../../elementos/loader/Loader";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion";
import Footer from "../../elementos/footer/Footer";
import Boton from "../../elementos/boton/Boton";
import "./certificadoTipo.css";
import DataTable from "react-data-table-component";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function CertificadoTipos() {
  const [loader, setLoader] = useState(true);

  const { notify } = useNotification();

  const [certificadoTipos, setCertificadoTipos] = useState();

  const navigate = useNavigate();

  const columns = [
    {
      name: "Tipo de Proceso",
      selector: (row) => row.procesotipo.nombre,
      sortable: true,
    },
    {
      name: "Tipo de Certificado",
      selector: (row) => row.nombre,
      sortable: true,
    },
  ];

  // Cuando carga el componente va a buscar los tipos de certificado
  useEffect(() => {
    accessAPI(
      "GET",
      "admin/producto/certificadotipo",
      null,
      (respuesta) => {
        setCertificadoTipos(respuesta);
      },
      (respuesta) => {
        // Si la API da error, devuelve el error al cliente
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
      }
    );
  }, [notify]);

  // Cuando carga los tipos de certifiacado, apaga el loader
  useEffect(() => {
    if (certificadoTipos) {
      setLoader(false);
    }
  }, [certificadoTipos]);

  return (
    <div className="seccion clientes">
      <MenuNavegacion submenuSeleccionado="config" />
      <Footer />
      <div className="contenido">
        {loader && <Loader>Cargando tipos de certificado</Loader>}
        {!loader && certificadoTipos && (
          <div>
            <h1 className="centrado">Tipos de certificado</h1>
            <Boton clases="verde centrado" uri="certificadotipos/nuevo">
              Nuevo tipo de certificado
            </Boton>

            <div className="tarjeta">
              <DataTable
                columns={columns}
                data={certificadoTipos}
                highlightOnHover
                pointerOnHover
                striped
                onRowClicked={(row, event) => {
                  navigate("" + row.id);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
