import { useState } from "react";
import { accessAPI } from "../../utils/utils";
import Boton from "../../elementos/boton/Boton";

export default function TarjetaMarcarSolicitudProcesada(props) {
  const { solicitud, onMarcarProcesada } = props;
  const [loaderMarcarProcesada, setLoaderMarcarProcesada] = useState(false);

  function marcarProcesado() {
    setLoaderMarcarProcesada(true);
    accessAPI(
      "PUT",
      `admin/proceso/solicitud/${solicitud.id}`,
      null,
      (respuesta) => {
        setLoaderMarcarProcesada(false);
        onMarcarProcesada();
      },
      (respuesta) => {
        alert(respuesta[0].msg);
        setLoaderMarcarProcesada(false);
      }
    );
  }

  return (
    <div>
      <div className="tarjeta chica">
        <Boton
          clases="centrado rojo"
          callback={marcarProcesado}
          loader={loaderMarcarProcesada}
        >
          Marcar solicitud procesada
        </Boton>
      </div>
    </div>
  );
}
