import { useState } from "react";
import { accessAPI } from "../../utils/utils.js";
import Formulario from "../../elementos/formulario/Formulario.js";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function AgregarCompetencias(props) {
  const [loaderform, setLoaderform] = useState(false);

  const { notify } = useNotification();

  const { auditor, agregarCompetencia } = props;

  function crearCompetenciasubmit(camposFormulario, archivo) {
    setLoaderform(true);
    accessAPI(
      "POST",
      `admin/auditor/competencias/${auditor.id}`,
      camposFormulario,
      (respuesta) => {
        agregarCompetencia(respuesta);
        setLoaderform(false);
      },
      (respuesta) => {
        // Si la API da error, devuelve el error al laboratorio
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
        setLoaderform(false);
      },
      archivo
    );
  }

  const nuevaCompetenciaFormConfig = {
    secciones: [
      {
        nombre: "Agregar competencia",
        campos: [
          {
            nombre: "normaid",
            tipoitemformularioid: 5,
            etiqueta: "Norma",
            opciones: "admin/norma",
            requerido: true,
            sinOpcionInicial: true,
          },
          {
            nombre: "fechaformacion",
            tipoitemformularioid: 7,
            etiqueta: "Fecha de la formación",
            requerido: true,
          },
          {
            nombre: "revisor",
            tipoitemformularioid: 6,
            etiqueta: "Tiene competencias de revisor sobre esta norma",
          },
          {
            nombre: "evidencia",
            tipoitemformularioid: 10,
            etiqueta: "Evidencia",
            requerido: true,
          },
        ],
      },
    ],
    submit: { callback: crearCompetenciasubmit },
    botones: [{ etiqueta: "Agregar competencias", tipo: "submit" }],
  };

  return (
    <div className="tarjeta">
      <Formulario config={nuevaCompetenciaFormConfig} loader={loaderform} />
    </div>
  );
}
