import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion";
import Footer from "../../elementos/footer/Footer";
import Loader from "../../elementos/loader/Loader";
import ConfigEventoSingle from "./ConfigEventoSingle";
import NuevoConfigEvento from "./NuevoConfigEvento";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

import { accessAPI } from "../../utils/utils";

export default function ConfigEventos() {
  const [loader, setLoader] = useState(true);
  const [eventoTipo, setEventoTipo] = useState();
  const [listadoEventoConfigs, setListadoEventoConfigs] = useState();

  const { notify } = useNotification();

  // Carga el id del evento de la URL
  const { eventoTipoId } = useParams();

  // Cuando carga el componente va a buscar el evento seleccionado
  useEffect(() => {
    accessAPI(
      "GET",
      `admin/comunicacion/eventotipos/${eventoTipoId}`,
      null,
      (respuesta) => {
        setEventoTipo(respuesta);
        setListadoEventoConfigs(respuesta.configEvento);
      },
      (respuesta) => {
        notify({
          mensaje: respuesta[0].msg,
          error: true,
          temporal: true,
        });
      }
    );
  }, [eventoTipoId, notify]);

  // Agrega una configuración al listado
  function agregarConfiguracion(nuevaConfig) {
    setListadoEventoConfigs([...listadoEventoConfigs, nuevaConfig]);
  }

  // Cuando carga los tipos de eventos apaga el loader
  useEffect(() => {
    if (eventoTipo) {
      setLoader(false);
    }
  }, [eventoTipo]);

  return (
    <div className="seccion">
      <MenuNavegacion submenuSeleccionado="comunicacion" />
      <Footer />
      <div className="contenido">
        {loader && <Loader>Cargando configuración de evento</Loader>}
        {!loader && eventoTipo && (
          <div>
            <h1>Configuración de comunicaciones para evento</h1>
            <h2>{eventoTipo.descripcion}</h2>
            <p>Campos disponibles: {eventoTipo.parametros.join(", ")}</p>
            <p>
              Los parámetros deben estar rodeados de doble paréntesis recto, por
              ejemplo [[clienteid]].
            </p>
            <div className="tarjetasContainer">
              <NuevoConfigEvento
                eventoTipoId={eventoTipoId}
                agregarConfiguracion={agregarConfiguracion}
              />
              <div className="division conEspacio" />
              {listadoEventoConfigs.map((configEvento) => {
                return (
                  <ConfigEventoSingle
                    key={configEvento.id}
                    configEvento={configEvento}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
