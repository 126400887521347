import { useRef } from "react";

export default function SelectorCriterio(props) {
  const { nombre, toggleSelector, id } = props;

  const checkboxRef = useRef();

  function reportarCambio() {
    toggleSelector(id, checkboxRef.current.checked);
  }

  return (
    <div>
      <input
        ref={checkboxRef}
        onChange={reportarCambio}
        type="checkbox"
        id={`checkbox-${id}`}
        name={`checkbox-${id}`}
      />
      <label htmlFor={`checkbox-${id}`}>{nombre}</label>
    </div>
  );
}
