import { useState, useEffect } from "react";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion";
import Footer from "../../elementos/footer/Footer";
import Loader from "../../elementos/loader/Loader";
import { accessAPI } from "../../utils/utils";
import CampoEditable from "../../elementos/campoEditable/CampoEditable";

export default function ProcesoTipos() {
  const [loader, setLoader] = useState(true);
  const [procesoTipos, setProcesoTipos] = useState();

  // Cuando carga el componente carga los tipos de proceso
  useEffect(() => {
    accessAPI(
      "GET",
      "admin/config/procesotipo/numeroactual",
      null,
      (respuesta) => {
        setProcesoTipos(respuesta);
      },
      (respuesta) => {
        console.log(respuesta);
      }
    );
  }, []);

  // Cuando carga los tipos de proceso apaga el loader
  useEffect(() => {
    if (procesoTipos) {
      setLoader(false);
    }
  }, [procesoTipos]);

  return (
    <div className="seccion">
      <MenuNavegacion submenuSeleccionado="config" />
      <Footer />
      <div className="contenido">
        <h1>Tipos de proceso</h1>
        {loader && <Loader>Cargando</Loader>}
        {!loader && (
          <div>
            <div className="tarjetasContainer">
              {procesoTipos.map((unProcesoTipo) => {
                return (
                  <div className="tarjeta tresCuartos" key={unProcesoTipo.id}>
                    <CampoEditable
                      etiqueta="Nombre"
                      valor={unProcesoTipo.nombre}
                    />
                    <CampoEditable
                      etiqueta="Letra"
                      valor={unProcesoTipo.letra}
                    />
                    <CampoEditable
                      etiqueta="Siguiente número"
                      valor={unProcesoTipo.numeroactual}
                      campo="numeroactual"
                      entidadid={unProcesoTipo.id}
                      endpoint="admin/config/procesotipo"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
