import { formatFecha } from "../../utils/utils";

export default function EnviadoIndividual(props) {
  const { evento } = props;

  return (
    <div className="tarjeta">
      <p>
        <span className="gris">Creado en: </span>
        {formatFecha(evento.fechacreacion)}
      </p>
      <p>
        <span className="gris">Evento: </span>
        {JSON.stringify(evento.eventotiponombre)}
      </p>

      <p className="lineBreak">
        <span className="gris">Parametros: </span>
        {JSON.stringify(evento.parametros)}
      </p>

      <p className="lineBreak">
        <span className="gris">Log procesado: </span>
        {JSON.stringify(evento.eventoprocesado[0]?.logProcesado)}
      </p>

      {evento.eventoprocesado[0] && (
        <>
          <div className="division"></div>
          <h1>Comunicación enviada</h1>
        </>
      )}
      {evento.eventoprocesado.map((unEventoProcesado, index) => {
        return (
          <div key={index}>
            {index !== 0 && <div className="division" />}
            <p>
              <span className="gris">Enviado:</span>{" "}
              {formatFecha(unEventoProcesado.fechaprocesado)}
            </p>
            <p>
              <span className="gris">Destinatario: </span>
              {unEventoProcesado.textoenviado?.destinatario}
            </p>
            <p>
              <span className="gris">Copia oculta (BCC): </span>
              {unEventoProcesado.textoenviado?.bccemail}
            </p>
            <p>
              <span className="gris">Asunto: </span>
              {unEventoProcesado.textoenviado?.asunto}
            </p>
            <p>
              <span className="gris">Texto: </span>
              {unEventoProcesado.textoenviado?.texto
                .split("\n")
                .map((parrafo, index) => {
                  return (
                    <span key={index}>
                      {parrafo}
                      <br />
                    </span>
                  );
                })}
            </p>
          </div>
        );
      })}
    </div>
  );
}
