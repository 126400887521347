import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { accessAPI } from "../../utils/utils.js";
import Formulario from "../../elementos/formulario/Formulario.js";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion.js";
import Footer from "../../elementos/footer/Footer.js";

import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function NuevoLaboratorio() {
  const [loader, setLoader] = useState(false);

  const { notify } = useNotification();

  const navigate = useNavigate();

  const crearLaboratorioConfig = {
    secciones: [
      {
        nombre: "Datos del laboratorio",
        campos: [
          {
            nombre: "razonsocial",
            tipoitemformularioid: 1,
            etiqueta: "Razón social",
            requerido: true,
          },
          {
            nombre: "nombrefantasia",
            tipoitemformularioid: 1,
            etiqueta: "Nombre fantasía",
          },
          {
            nombre: "rut",
            tipoitemformularioid: 1,
            etiqueta: "RUT",
          },
          {
            nombre: "direccion",
            tipoitemformularioid: 1,
            etiqueta: "Direccion",
            requerido: true,
          },
          {
            nombre: "codigopostal",
            tipoitemformularioid: 1,
            etiqueta: "Código postal",
            requerido: true,
          },
          {
            nombre: "ciudad",
            tipoitemformularioid: 1,
            etiqueta: "Ciudad",
            requerido: true,
            respuesta: "Montevideo",
          },
          {
            nombre: "estado",
            tipoitemformularioid: 1,
            etiqueta: "Estado",
            requerido: true,
            respuesta: "Montevideo",
          },
          {
            nombre: "pais",
            tipoitemformularioid: 1,
            etiqueta: "País",
            requerido: true,
            respuesta: "Uruguay",
          },
          {
            nombre: "telefono",
            tipoitemformularioid: 1,
            etiqueta: "Teléfono",
          },
          {
            nombre: "email",
            tipoitemformularioid: 2,
            etiqueta: "Email",
          },
          {
            nombre: "pagina",
            tipoitemformularioid: 1,
            etiqueta: "Página",
          },
        ],
      },
    ],
    submit: { callback: crearLaboratorioSubmit },
    botones: [{ etiqueta: "Crear laboratorio", tipo: "submit" }],
  };

  // Cuando carga los clientes apaga el loader

  function crearLaboratorioSubmit(camposFormulario) {
    setLoader(true);
    accessAPI(
      "POST",
      "admin/laboratorio",
      camposFormulario,
      (respuesta) => {
        navigate(`/proveedores/laboratorios/listado/${respuesta[0].id}`);
      },
      (respuesta) => {
        // Si la API da error, devuelve el error al cliente
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
        setLoader(false);
      }
    );
  }

  return (
    <div className="seccion laboratorios">
      <MenuNavegacion submenuSeleccionado="proveedores" />
      <Footer />
      <div className="contenido">
        <div className="tarjeta tresCuartos">
          <Formulario
            config={crearLaboratorioConfig}
            className="nuevoLaboratorio"
            loader={loader}
          />
        </div>
      </div>
    </div>
  );
}
