import { useState } from "react";
import Boton from "../../elementos/boton/Boton";
import { accessAPI } from "../../utils/utils";

export default function TarjetaProcesoActivo(props) {
  const [procesoActivo, setProcesoActivo] = useState(props.proceso.activo);
  const [loader, setLoader] = useState(false);

  const { proceso } = props;

  // Envía el request a la API para cambiar el estado del proceso
  function toggleActivo() {
    setLoader(true);
    accessAPI(
      "PUT",
      `admin/proceso/activo/${proceso.id}`,
      null,
      (respuesta) => {
        setLoader(false);
        setProcesoActivo(respuesta);
      },
      (respuesta) => {
        setLoader(false);
        alert(respuesta[0].msg);
      }
    );
  }

  return (
    <div className="tarjeta">
      {procesoActivo === 1 && (
        <div className="centrado">
          Proceso <span className="verde">activo</span>
          <Boton
            callback={toggleActivo}
            confirmacion={true}
            clases="rojo centrado"
            loader={loader}
          >
            Marcar inactivo
          </Boton>
        </div>
      )}
      {procesoActivo !== 1 && (
        <div>
          Proceso <span className="rojo">inactivo</span>
          <Boton
            callback={toggleActivo}
            confirmacion={true}
            clases="verde centrado"
            loader={loader}
          >
            Marcar activo
          </Boton>
        </div>
      )}
    </div>
  );
}
