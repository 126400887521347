import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion";
import Footer from "../../elementos/footer/Footer";
import Boton from "../../elementos/boton/Boton";

export default function ConfigPortada() {
  return (
    <div className="seccion config">
      <MenuNavegacion submenuSeleccionado="config" />
      <Footer />
      <div className="contenido">
        <h1>Comunicaciones</h1>
        <div className="flexContainer">
          <div className="tarjetasContainer">
            <div className="tarjeta">
              <h1>Eventos</h1>
              <Boton uri="/comunicacion/configeventos" clases="texto">
                Configurar
              </Boton>
              <Boton uri="/comunicacion/enviados" clases="texto">
                Ver eventos enviados
              </Boton>
            </div>
          </div>
          <div className="tarjetasContainer">
            <div className="tarjeta">
              <h1>Generador de contactos</h1>
              <Boton uri="/clientes/comunicaciones" clases="texto">
                Generar listado
              </Boton>
            </div>
          </div>
        </div>
        <h1>Configuracion</h1>
        <div className="flexContainer">
          <div className="tarjetasContainer">
            <div className="tarjeta">
              <h1>Normas</h1>
              <Boton uri="/normas/listado" clases="texto">
                Ver listado
              </Boton>
              <Boton uri="/normas/nuevo" clases="texto verde">
                Nueva norma
              </Boton>
            </div>
            <div className="tarjeta">
              <h1>Esquemas</h1>
              <Boton uri="/esquemas" clases="texto">
                Ver listado
              </Boton>
              <Boton uri="/esquemas/nuevo" clases="texto verde">
                Nuevo esquema
              </Boton>
            </div>
            <div className="tarjeta">
              <h1>Laboratorios</h1>
              <Boton uri="/proveedores/laboratorios/listado" clases="texto">
                Ver listado
              </Boton>
              <Boton uri="/proveedores/laboratorios/nuevo" clases="texto verde">
                Nuevo laboratorio
              </Boton>
            </div>
            <div className="tarjeta">
              <h1>Auditores</h1>
              <Boton uri="/proveedores/auditores" clases="texto">
                Ver listado
              </Boton>
              <Boton uri="/proveedores/nuevoauditor" clases="texto verde">
                Nuevo auditor
              </Boton>
            </div>
          </div>
          <div className="tarjetasContainer">
            <div className="tarjeta">
              <h1>Tipos de certificado</h1>
              <Boton uri="/certificadotipos" clases="texto">
                Ver listado
              </Boton>
              <Boton uri="/certificadotipos/nuevo" clases="texto verde">
                Nuevo tipo de certificado
              </Boton>
            </div>
            <div className="tarjeta">
              <h1>Tipos de proceso</h1>
              <Boton uri="/procesotipos" clases="texto">
                Ver listado
              </Boton>
              {/* <Boton uri="/certificadotipos/nuevo" clases="texto verde">
                Nuevo tipo de certificado
              </Boton> */}
            </div>
            <div className="tarjeta">
              <h1>Reportes</h1>
              <Boton uri="/reporte" clases="texto">
                Ir a página de reportes
              </Boton>
              {/* <Boton uri="/certificadotipos/nuevo" clases="texto verde">
                Nuevo tipo de certificado
              </Boton> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
