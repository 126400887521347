import { useState } from "react";
import { accessAPI } from "../../utils/utils";

export default function NormaEnCertificadoTipo(props) {
  const [loaderEliminar, setLoaderEliminar] = useState(false);
  const [eliminado, setEliminado] = useState(false);

  const { norma, certificadoTipoId } = props;

  // Función ejecutada al presionar el botón de eliminar esquema
  function eliminarNorma(normaid) {
    accessAPI(
      "DELETE",
      `admin/config/certificadotipo/${certificadoTipoId}/norma`,
      { normaid: norma.norma.id },
      (respuesta) => {
        setEliminado(true);
      },
      (respuesta) => {
        alert(respuesta[0].msg);
      }
    );
  }
  return (
    <div key={norma.norma.id} className={eliminado ? "eliminado" : ""}>
      {!eliminado && (
        <button
          className={loaderEliminar ? "botonEliminar loader" : "botonEliminar"}
          onClick={() => {
            setLoaderEliminar(true);
            if (!loaderEliminar) {
              eliminarNorma(norma.id);
            }
          }}
        >
          {loaderEliminar && <span>Cargando...</span>}
          {!loaderEliminar && <span>X</span>}
        </button>
      )}
      {norma.norma.numero}
    </div>
  );
}
