import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { accessAPI, downloadFile, formatFecha } from "../../utils/utils.js";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion";
import CampoEditable from "../../elementos/campoEditable/CampoEditable";
import Footer from "../../elementos/footer/Footer";
import Loader from "../../elementos/loader/Loader";
import Boton from "../../elementos/boton/Boton";
import EstadoCertificado from "./EstadoCertificado";
import ReemisionCertificado from "./ReemisionCertificado";
import EmisionCertificado from "./EmisionCertificado";
import flechaDerechaIcono from "../../images/flechaDerechaIcono.png";
import ExtensionCertificado from "./ExtensionCertificado";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function Certificado() {
  const [loader, setLoader] = useState(true);

  const [certificado, setCertificado] = useState();
  const [emisiones, setEmisiones] = useState();

  const [loaderPDF, setLoaderPDF] = useState(false);
  const [loaderEtiquetas, setLoaderEtiquetas] = useState(false);
  const [loaderMarca, setLoaderMarca] = useState(false);

  const { notify } = useNotification();

  // Carga el id del certificado de la URL
  const { certificadoId } = useParams();

  // Cuando carga el componente va a buscar el certificado
  useEffect(() => {
    if (certificadoId) {
      accessAPI(
        "GET",
        `admin/certificado/${certificadoId}`,
        null,
        (respuesta) => {
          setCertificado(respuesta.certificado);
          setEmisiones(respuesta.certificado.emisioncertificado);
        },
        (respuesta) => {
          // Le avisa al usuario
          notify({
            mensaje: respuesta[0].msg,
            temporal: true,
            error: true,
          });
        }
      );
    }
  }, [certificadoId, notify]);

  // Cuando carga el certificado apaga el loader
  useEffect(() => {
    if (certificado) {
      setLoader(false);
    }
  }, [certificado]);

  // Regenera las etiquetas del certificado
  function regenerarEtiquetas() {
    setLoaderPDF(true);
    accessAPI(
      "PUT",
      `admin/certificado/generaretiquetas/${certificadoId}`,
      null,
      (respuesta) => {
        let certificadoActual = { ...certificado };
        // Si recibe etiquetas, las agrega al proceso
        if (respuesta[0].etiquetas.length) {
          for (const etiqueta of respuesta[0].etiquetas) {
            certificadoActual.proceso.etiqueta.push(etiqueta);
          }
        }
        setCertificado(certificadoActual);
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: false,
        });
        setLoaderPDF(false);
      },
      (respuesta) => {
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
        setLoaderPDF(false);
      }
    );
  }

  // Descarga las etiquetas del proceso
  function descargarEtiquetas(etiquetaId, etiquetaNombre) {
    setLoaderEtiquetas(true);
    downloadFile(
      `admin/certificado/descargaretiqueta/${etiquetaId}`,
      etiquetaNombre,
      (respuesta) => {
        setLoaderEtiquetas(false);
      },
      (respuesta) => {
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
        setLoaderEtiquetas(false);
      }
    );
  }

  // Genera y descarga la marca de conformidad
  function descargarMarca() {
    setLoaderMarca(true);
    downloadFile(
      `admin/certificado/descargarmarca/${certificadoId}`,
      `Marca de conformidad CP-${certificado.proceso.certificadotipo.procesotipo.letra}-${certificado.proceso.numero}.pdf`,
      (respuesta) => {
        setLoaderMarca(false);
      },
      (respuesta) => {
        setLoaderMarca(false);
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
      }
    );
  }

  // Genera la etiqueta de datos del importaodr para el empaque de RSPEBT
  function descargarEtiquetaDatosImportador() {
    setLoaderMarca(true);
    downloadFile(
      `admin/certificado/descargaretiquetadatos/${certificadoId}`,
      `Etiqueta datos cliente CP-${certificado.proceso.certificadotipo.procesotipo.letra}-${certificado.proceso.numero}.zip`,
      (respuesta) => {
        setLoaderMarca(false);
      },
      (respuesta) => {
        setLoaderMarca(false);
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
      }
    );
  }

  // Callback para agregar la reemisión de un certificado
  function agregarEmision(emision) {
    setEmisiones([emision, ...emisiones]);
  }

  return (
    <div className="seccion">
      <MenuNavegacion submenuSeleccionado="procesos" />
      <Footer />
      <div className="contenido">
        {loader && <Loader>Cargando el certificado</Loader>}
        {!loader && (
          <>
            <h1>
              Certificado{" "}
              {`CP-${certificado.proceso.certificadotipo.procesotipo.letra}-${
                certificado.proceso.numero
              } - ${formatFecha(certificado.fechaemision)}`}
              <a href={`/proceso/${certificado.proceso.id}`}>
                <img
                  src={flechaDerechaIcono}
                  className="flechaLinkProceso"
                  alt="Ir a proceso"
                />
              </a>
            </h1>
            <div className="flexContainer">
              <div className="tarjetasContainer">
                <div className="tarjeta">
                  <h1>Detalles</h1>
                  <CampoEditable
                    etiqueta="Estado"
                    valor={certificado.estadocertificado.nombre}
                  />
                  <CampoEditable
                    etiqueta="Fecha vencimiento"
                    valor={`${formatFecha(certificado.fechavencimiento)}`}
                    campo="fechavencimiento"
                    entidadid={certificado.id}
                    endpoint="admin/certificado"
                    esFecha={true}
                  />
                  {certificado.proceso.certificadotipo.gestionadoursea && (
                    <CampoEditable
                      etiqueta="PIN envío a URSEA"
                      valor={certificado.pinenvioursea}
                    />
                  )}
                  <div>
                    <Boton
                      clases="verde centrado"
                      loader={loaderMarca}
                      callback={descargarMarca}
                    >
                      Descargar Marca
                    </Boton>
                    <Boton
                      clases="verde centrado"
                      loader={loaderMarca}
                      callback={descargarEtiquetaDatosImportador}
                    >
                      Generar etiqueta de datos del importador
                    </Boton>
                    {certificado.proceso?.etiqueta?.length > 0 && (
                      <>
                        <div className="division" />
                        {certificado.proceso.etiqueta.map((etiqueta) => {
                          return (
                            <Boton
                              clases="verde centrado"
                              loader={loaderEtiquetas}
                              callback={() => {
                                descargarEtiquetas(
                                  etiqueta.id,
                                  etiqueta.nombrearchivopdf
                                    ? etiqueta.nombrearchivopdf
                                    : // Si la etiqueta viene de una extensión, se lo agrega al nombre
                                    etiqueta.extensioncertificado
                                        ?.clienteextendido?.razonsocial
                                    ? `${etiqueta.archivo?.nombredescarga} - ${etiqueta.extensioncertificado.clienteextendido.razonsocial}`.replace(
                                        ".pdf",
                                        ""
                                      ) + ".pdf"
                                    : etiqueta.archivo?.nombredescarga
                                );
                              }}
                              key={etiqueta.id}
                            >
                              {`Descargar ${
                                etiqueta.nombrearchivopdf
                                  ? etiqueta.nombrearchivopdf
                                  : // Si la etiqueta viene de una extensión, se lo agrega al nombre
                                  etiqueta.extensioncertificado
                                      ?.clienteextendido?.razonsocial
                                  ? `${etiqueta.archivo?.nombredescarga} - ${etiqueta.extensioncertificado.clienteextendido.razonsocial}`
                                  : etiqueta.archivo?.nombredescarga
                              }`}
                            </Boton>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
                <div className="tarjeta">
                  <ReemisionCertificado
                    certificadoId={certificadoId}
                    agregarEmision={agregarEmision}
                  />
                  <Boton
                    clases="rojo centrado"
                    loader={loaderPDF}
                    callback={regenerarEtiquetas}
                    confirmacion={true}
                  >
                    Regenerar etiquetas
                  </Boton>
                </div>
                <div>
                  {certificado && certificado.estadocertificado && (
                    <EstadoCertificado certificado={certificado} />
                  )}
                </div>
                <ExtensionCertificado
                  extensiones={certificado.extensioncertificado}
                />
              </div>
              <div className="tarjetasContainer">
                <h1>Emisiones</h1>
                {emisiones.map((emision) => {
                  return (
                    <EmisionCertificado
                      emision={emision}
                      proceso={certificado.proceso}
                      key={emision.id}
                    />
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
