import { useSearchParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { accessAPI } from "../../utils/utils.js";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion.js";
import Footer from "../../elementos/footer/Footer";
import Loader from "../../elementos/loader/Loader";
import NotificacionSistema from "./NotificacionSistema.js";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function Tablero() {
  const [searchParams] = useSearchParams();

  const [notificaciones, setNotificaciones] = useState([]);
  const [loaderNotificaciones, setLoaderNotificaciones] = useState(true);

  const { notify } = useNotification();

  const navigate = useNavigate();

  // Cuando carga el tablero, verifica si la URL trajo el queryparam de oauthtoken
  // si lo trajo, navega a login para autenticar
  useEffect(() => {
    if (searchParams && navigate) {
      if (searchParams.get("oauthtoken")) {
        navigate("/login", {
          state: { oauthtoken: searchParams.get("oauthtoken") },
        });
      } else {
        // Si no tiene queryparams, verifica que el token de LS sea correcto
        accessAPI(
          "GET",
          "usuario/me",
          null,
          () => {},
          (response) => {
            window.location.href = process.env.REACT_APP_LOGIN_URL;
          }
        );
        // Va a buscar las notificaciones
        accessAPI(
          "GET",
          "notificacion",
          null,
          (respuesta) => {
            setLoaderNotificaciones(false);
            setNotificaciones(respuesta);
          },
          (respuesta) => {
            // Si la API da error, devuelve el error al cliente
            notify({
              mensaje: respuesta[0].msg,
              temporal: true,
              error: true,
            });
          }
        );
      }
    }
  }, [searchParams, navigate, notify]);

  // Cuando termina de cargar las cosas apaga el loader

  return (
    <div className="seccion tablero">
      <MenuNavegacion />
      <div className="contenido">
        <h1>Bienvenido al sistema de administración de Qualis Fidelis</h1>
        <div className="division"></div>
        <div className="notificacionesContainer">
          <h2>Notificaciones</h2>
          {loaderNotificaciones && <Loader>Cargando notificaciones</Loader>}
          {!loaderNotificaciones &&
            notificaciones &&
            notificaciones.notificacionesNoProcesadas.map((notificacion) => {
              return (
                <NotificacionSistema
                  notificacion={notificacion}
                  key={notificacion.id}
                />
              );
            })}
        </div>
      </div>
      <Footer />
    </div>
  );
}
