import { useRef, useState } from "react";
import { accessAPI, downloadFile } from "../../utils/utils";
import Boton from "../../elementos/boton/Boton";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function ProcesoSubirPlanillaProductos(props) {
  const [uploading, setUploading] = useState(false);
  const [template] = useState(props.proceso.certificadotipo?.codigo);
  const [descargandoLoader, setDescargandoLoader] = useState(false);
  const archivoRef = useRef();

  const { notify } = useNotification();

  async function descargarTemplate() {
    setDescargandoLoader(true);
    downloadFile(
      `cliente/proceso/template/${template}`,
      `${template}.xlsx`,
      (respuesta) => {
        setDescargandoLoader(false);
      },
      (respuesta) => {
        setDescargandoLoader(false);
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
      }
    );
  }

  function subirArchivo() {
    if (!archivoRef.current.files[0]) {
      return;
    }
    setUploading(true);

    accessAPI(
      "POST",
      `admin/proceso/productos/${props.proceso.id}`,
      null,
      (respuesta) => {
        console.log(respuesta);
        setUploading(false);
        notify({
          mensaje: "Productos agregados correctamente",
          temporal: true,
          error: false,
        });
      },
      (respuesta) => {
        setUploading(false);
        // Si la API da error, devuelve el error al cliente
        notify({
          mensaje: `${respuesta[0].msg}`,
          temporal: true,
          error: true,
        });
      },
      archivoRef.current.files[0]
    );
  }

  return (
    <div className="tarjeta producto">
      <h1>Subir planilla de productos</h1>
      <p>CSV con ";" como separador.</p>
      {template && (
        <>
          <p>
            Puedes descargar el template en blanco presionando el siguiente
            botón.
          </p>
          <Boton
            clases="centrado"
            callback={descargarTemplate}
            loader={descargandoLoader}
          >
            Descargar template
          </Boton>
        </>
      )}
      <div className="division"></div>
      <div className="prettyInputContainer">
        <input ref={archivoRef} type="file" className="file prettyInput" />
      </div>
      <Boton clases="centrado verde" callback={subirArchivo} loader={uploading}>
        Subir archivo
      </Boton>
    </div>
  );
}
