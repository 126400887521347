import { useState } from "react";
import { accessAPI } from "../../utils/utils.js";
import Formulario from "../../elementos/formulario/Formulario.js";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function TarjetaCrearProceso(props) {
  const [loaderEnvio, setLoaderEnvio] = useState(false);
  const [resetForm, setResetForm] = useState(1);

  const { notify } = useNotification();

  const { solicitud } = props;

  const crearProcesoFormConfig = {
    secciones: [
      {
        nombre: "Nuevo proceso",
        campos: [
          {
            nombre: "nombrefantasia",
            tipoitemformularioid: 1,
            etiqueta: "Nombre del proceso",
            respuesta: solicitud.nombrefantasia,
            requerido: true,
          },
          {
            nombre: "certificadotipoid",
            tipoitemformularioid: 5,
            etiqueta: "Tipo de certificado",
            requerido: true,
            opciones: `admin/config/certificadotipo?disponibleproceso=true`,
            sinOpcionInicial: true,
            respuesta: solicitud.certificadotipo.disponibleproceso
              ? solicitud.certificadotipo.id
              : null,
          },
          {
            nombre: "esquemaid",
            tipoitemformularioid: 5,
            etiqueta: "Esquema de certificación",
            requerido: true,
            opciones: `admin/config/esquema`,
            sinOpcionInicial: true,
            queryparams: ["certificadotipoid"],
          },
          {
            nombre: "normaid",
            tipoitemformularioid: 5,
            etiqueta: "Norma",
            requerido: true,
            opciones: `admin/norma`,
            sinOpcionInicial: true,
            queryparams: ["certificadotipoid"],
          },
          {
            nombre: "fabricantenombre",
            tipoitemformularioid: 1,
            etiqueta: "Nombre del fabricante",
            respuesta: solicitud.fabrica.fabricantenombre,
            requerido: true,
          },
          {
            nombre: "fabricantedireccion",
            tipoitemformularioid: 1,
            etiqueta:
              "Dirección del fabricante - formato dir,dir2,cp,estado,ciudad,pais",
            respuesta: solicitud.fabrica.fabricantedireccion,
            requerido: true,
            regex: "^[^,]+(?:,[^,]*){5}$",
          },
          {
            nombre: "fabricanombre",
            tipoitemformularioid: 1,
            etiqueta: "Nombre de la fábrica",
            respuesta: solicitud.fabrica.fabricanombre,
            requerido: true,
          },
          {
            nombre: "fabricadireccion",
            tipoitemformularioid: 1,
            etiqueta:
              "Dirección de la fábrica - formato dir,dir2,cp,estado,ciudad,pais",
            respuesta: solicitud.fabrica.fabricadireccion,
            requerido: true,
            regex: "^[^,]+(?:,[^,]*){5}$",
          },
        ],
      },
    ],
    submit: { callback: crearProcesoSubmit },
    botones: [{ etiqueta: "Crear proceso", tipo: "submit", clases: "azul" }],
  };

  function crearProcesoSubmit(camposFormulario) {
    setLoaderEnvio(true);
    accessAPI(
      "POST",
      "admin/proceso/desdesolicitud",
      {
        ...camposFormulario,
        solicitudid: solicitud.id,
      },
      (respuesta) => {
        props.agregarProcesoVigenteASolicitud(respuesta);
        setLoaderEnvio(false);
        setResetForm(resetForm + 1);
      },
      (respuesta) => {
        // Si la API da error, devuelve el error al cliente
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
        setLoaderEnvio(false);
      }
    );
  }

  return (
    <div>
      <div className="tarjeta chica">
        <Formulario
          config={crearProcesoFormConfig}
          loader={loaderEnvio}
          key={resetForm}
        />
      </div>
    </div>
  );
}
