import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { accessAPI } from "../../utils/utils.js";
import Formulario from "../../elementos/formulario/Formulario";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion";
import Footer from "../../elementos/footer/Footer";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function NuevoCliente() {
  const [loader, setLoader] = useState(false);
  const { notify } = useNotification();

  const navigate = useNavigate();

  const crearClienteFormConfig = {
    secciones: [
      {
        nombre: "Datos de la empresa",
        campos: [
          {
            nombre: "razonsocial",
            tipoitemformularioid: 1,
            etiqueta: "Razón social",
            requerido: true,
          },
          {
            nombre: "nombrefantasia",
            tipoitemformularioid: 1,
            etiqueta: "Nombre fantasía",
          },
          {
            nombre: "rut",
            tipoitemformularioid: 1,
            etiqueta: "RUT",
          },
          {
            nombre: "direccion",
            tipoitemformularioid: 1,
            etiqueta: "Direccion",
            requerido: true,
          },
          {
            nombre: "codigopostal",
            tipoitemformularioid: 1,
            etiqueta: "Código postal",
          },
          {
            nombre: "estado",
            tipoitemformularioid: 1,
            etiqueta: "Estado",
            requerido: true,
            respuesta: "Montevideo",
          },
          {
            nombre: "ciudad",
            tipoitemformularioid: 1,
            etiqueta: "Ciudad",
            requerido: true,
            respuesta: "Montevideo",
          },
          {
            nombre: "pais",
            tipoitemformularioid: 1,
            etiqueta: "País",
            requerido: true,
            respuesta: "Uruguay",
          },
          {
            nombre: "direcciondeposito",
            tipoitemformularioid: 1,
            etiqueta: "Direccion del depósito",
            requerido: true,
          },
          {
            nombre: "codigopostaldeposito",
            tipoitemformularioid: 1,
            etiqueta: "Código postal del depósito",
          },
          {
            nombre: "estadodeposito",
            tipoitemformularioid: 1,
            etiqueta: "Estado del depósito",
            requerido: true,
            respuesta: "Montevideo",
          },
          {
            nombre: "ciudaddeposito",
            tipoitemformularioid: 1,
            etiqueta: "Ciudad del depósito",
            requerido: true,
            respuesta: "Montevideo",
          },
          {
            nombre: "paisdeposito",
            tipoitemformularioid: 1,
            etiqueta: "País del depósito",
            requerido: true,
            respuesta: "Uruguay",
          },
          {
            nombre: "telefono",
            tipoitemformularioid: 1,
            etiqueta: "Teléfono",
          },
          {
            nombre: "pagina",
            tipoitemformularioid: 1,
            etiqueta: "Página",
          },
        ],
      },
      {
        nombre: "Responsable de certificación",
        descripcion:
          "Usuario que gestionará los certificados desde esta plataforma. Puede actualizarse en caso de ser necesario.",
        campos: [
          {
            nombre: "nombreresponsable",
            tipoitemformularioid: 1,
            etiqueta: "Nombre",
            requerido: true,
          },
          {
            nombre: "apellidoresponsable",
            tipoitemformularioid: 1,
            etiqueta: "Apellido",
            requerido: true,
          },
          {
            nombre: "emailresponsable",
            tipoitemformularioid: 2,
            etiqueta: "Email",
            requerido: true,
          },
          {
            nombre: "telefonoresponsable",
            tipoitemformularioid: 1,
            etiqueta: "Celular",
          },
          {
            nombre: "cargoresponsable",
            tipoitemformularioid: 1,
            etiqueta: "Cargo",
          },
        ],
      },
    ],
    submit: { callback: crearClienteSubmit },
    botones: [{ etiqueta: "Crear cliente", tipo: "submit" }],
  };

  function crearClienteSubmit(camposFormulario) {
    setLoader(true);
    accessAPI(
      "POST",
      "cliente",
      camposFormulario,
      (respuesta) => {
        navigate(`/clientes/listado/${respuesta[0].id}`);
      },
      (respuesta) => {
        setLoader(false);
        notify({
          mensaje: respuesta[0].msg,
          error: true,
          temporal: false,
        });
      }
    );
  }

  return (
    <div className="seccion clientes">
      <MenuNavegacion submenuSeleccionado="clientes" />
      <Footer />
      <div className="contenido">
        <div className="tarjetasContainer">
          <div className="tarjeta tresCuartos">
            <Formulario config={crearClienteFormConfig} loader={loader} />
          </div>
        </div>
      </div>
    </div>
  );
}
