import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { accessAPI, formatFecha } from "../../utils/utils.js";
import Boton from "../../elementos/boton/Boton";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function GenerarCertificado(props) {
  const [loaderEmision, setLoaderEmision] = useState(false);

  const { notify } = useNotification();

  const navigate = useNavigate();

  // Emite el certificado para el proceso
  function emitirCertificado() {
    setLoaderEmision(true);
    accessAPI(
      "POST",
      "admin/certificado",
      { procesoid: props.proceso.id },
      (respuesta) => {
        // Cuando recibe el ok, navega al certificado generado
        navigate(`/certificado/${respuesta.nuevoCertificado.id}`);
      },
      (respuesta) => {
        // Si la API da error, devuelve el error al cliente
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
        setLoaderEmision(false);
      }
    );
  }

  return (
    <div>
      {props.proceso.certificado?.length !== 0 && (
        <div className="tarjeta">
          Certificado{" "}
          <span
            className={
              props.proceso.certificado[0].estadocertificado.vigente
                ? "verde"
                : "rojo"
            }
          >
            {props.proceso.certificado[0].estadocertificado.nombre.toLowerCase()}
          </span>{" "}
          emitido en {formatFecha(props.proceso.certificado[0].fechaemision)}
          <Boton
            clases="centrado verde"
            uri={`/certificado/${props.proceso.certificado[0].id}`}
          >
            Ver certificado
          </Boton>
        </div>
      )}
      {props.proceso.certificado?.length === 0 && (
        <div className="tarjeta">
          <Boton
            confirmacion={true}
            clases="centrado verde"
            callback={emitirCertificado}
            loader={loaderEmision}
          >
            Emitir certificado
          </Boton>
        </div>
      )}
    </div>
  );
}
