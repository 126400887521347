import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { accessAPI } from "../../utils/utils.js";
import Loader from "../../elementos/loader/Loader.js";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion.js";
import Footer from "../../elementos/footer/Footer.js";
import AuditorDetalle from "./AuditorDetalle.js";
import TarjetaCompetencia from "./TarjetaCompetencia";
import AgregarCompetencias from "./AgregarCompetencias";
import TarjetaPagoAuditor from "./TarjetaPagoAuditor";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function Auditor() {
  const [loader, setLoader] = useState(true);
  const [datosAuditorSeleccionado, setDatosAuditorSeleccionado] = useState();

  const [competencias, setCompetencias] = useState();

  const { notify } = useNotification();

  const { auditorId } = useParams();

  // Agrega una nueva competencia a la lista
  function agregarCompetencia(competencia) {
    setCompetencias([...competencias, competencia]);
  }

  // Cuando carga el componente, va a buscar el auditor seleccionado
  useEffect(() => {
    setLoader(true);
    accessAPI(
      "GET",
      `admin/auditor/${auditorId}?detalles=true`,
      null,
      (respuesta) => {
        setDatosAuditorSeleccionado(respuesta);
        setCompetencias(respuesta.competencias);
      },
      (respuesta) => {
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
        setLoader(false);
      }
    );
  }, [auditorId, notify]);

  //Setea el loader dependiendo de si llegaron los datos
  useEffect(() => {
    if (datosAuditorSeleccionado) {
      setLoader(false);
    }
  }, [datosAuditorSeleccionado]);

  return (
    <div className="seccion ">
      <MenuNavegacion submenuSeleccionado="proveedores" />
      <Footer />
      <div className="contenido">
        <div className="tarjetasContainer">
          {!datosAuditorSeleccionado && loader && (
            <Loader>Cargando auditor</Loader>
          )}
          {datosAuditorSeleccionado && competencias && (
            <AuditorDetalle auditor={datosAuditorSeleccionado} />
          )}
        </div>

        {datosAuditorSeleccionado && competencias && (
          <div className="flexContainer">
            <div className="tarjejtasContainer">
              <div className="titulo centrado">Competencias</div>
              {!!competencias.length &&
                competencias.map((competencia) => {
                  return (
                    <TarjetaCompetencia
                      competencia={competencia}
                      key={competencia.id}
                    />
                  );
                })}
              {!competencias.length && (
                <div>
                  Aún no se han ingresado competencias para este auditor
                </div>
              )}
              <AgregarCompetencias
                auditor={datosAuditorSeleccionado}
                agregarCompetencia={agregarCompetencia}
                key={competencias.length}
              />
            </div>

            <div className="tarjejtasContainer">
              <TarjetaPagoAuditor auditor={datosAuditorSeleccionado} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
