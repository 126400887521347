import { useState } from "react";
import { accessAPI } from "../../utils/utils.js";
import Formulario from "../../elementos/formulario/Formulario";
export default function ConfirmarPagoAnalisis(props) {
  const [loader, setLoader] = useState(false);

  const { mostrarMensaje } = props;

  const configConfirmarPago = {
    secciones: [
      {
        nombre: "Confirmar Pago",
        campos: [
          {
            nombre: "nroItemDni",
            tipoitemformularioid: 1,
            etiqueta: "Número de ítem DNI",
            requerido: true,
          },
          {
            nombre: "fechaPago",
            tipoitemformularioid: 7,
            etiqueta: "Fehca de realización del pago",
            requerido: true,
          },
        ],
      },
    ],
    submit: { callback: confirmacionDelPago },
    botones: [{ etiqueta: "Confirmar", tipo: "submit" }],
  };

  function confirmacionDelPago(camposFormulario) {
    setLoader(true);
    accessAPI(
      "POST",
      "dni/juguetes/test/confirmarPago",
      camposFormulario,
      (respuesta) => {
        alert(JSON.stringify(respuesta));
        setLoader(false);
      },
      (respuesta) => {
        mostrarMensaje({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
        setLoader(false);
      }
    );
  }
  return (
    <div className="tarjeta tresCuartos">
      <Formulario config={configConfirmarPago} loader={loader} />
    </div>
  );
}
