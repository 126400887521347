import { useState, useEffect } from "react";
import CampoEditable from "../../elementos/campoEditable/CampoEditable";

export default function TarjetaEmpleadoCliente(props) {
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(false);
  }, [props]);

  function cambiarResponsable() {
    setLoader(true);
    props.cambiarResponsable(props.empleado.id);
  }

  return (
    <div className="tarjeta empleado">
      <h1>
        {props.empleado.nombre} {props.empleado.apellido}
      </h1>

      <CampoEditable
        etiqueta="Nombre"
        campo="nombre"
        entidadid={props.empleado.id}
        valor={props.empleado.nombre}
        endpoint="admin/cliente/empleado"
      />
      <CampoEditable
        etiqueta="Apellido"
        campo="apellido"
        entidadid={props.empleado.id}
        valor={props.empleado.apellido}
        endpoint="admin/cliente/empleado"
      />
      <CampoEditable
        etiqueta="Email"
        campo="email"
        entidadid={props.empleado.id}
        valor={props.empleado.email}
        endpoint="admin/cliente/empleado"
      />
      <CampoEditable
        etiqueta="Celular"
        campo="celular"
        entidadid={props.empleado.id}
        valor={props.empleado.celular}
        endpoint="admin/cliente/empleado"
      />
      <CampoEditable
        etiqueta="Cargo"
        campo="cargo"
        entidadid={props.empleado.id}
        valor={props.empleado.cargo}
        endpoint="admin/cliente/empleado"
      />
      <CampoEditable
        etiqueta="Notas"
        campo="notas"
        entidadid={props.empleado.id}
        valor={props.empleado.notas}
        endpoint="admin/cliente/empleado"
      />
      {!loader && props.empleado.responsablecertificacion && (
        <div className="responsableCertificacion">
          Responsable de certificación
        </div>
      )}

      {!loader && !props.empleado.responsablecertificacion && (
        <div
          className="responsableCertificacion boton"
          onClick={cambiarResponsable}
        >
          Establecer responsable
        </div>
      )}
      {loader && (
        <div className="responsableCertificacion cargando">cargando...</div>
      )}
    </div>
  );
}
