import { useState } from "react";
import { useParams } from "react-router-dom";
import Formulario from "../../elementos/formulario/Formulario";
import { accessAPI, formatFecha } from "../../utils/utils";
import checkIcono from "../../images/checkIcono.png";
import crossIcono from "../../images/crossIcono.png";
import Boton from "../../elementos/boton/Boton";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function ExtensionCertificado(props) {
  const [extensiones, setExtensiones] = useState(props.extensiones || []);
  const [loaderExtension, setLoaderExtension] = useState(false);
  const [loaderCancelar, setLoaderCancelar] = useState(false);

  // Carga el id del certificado de la URL
  const { certificadoId } = useParams();

  const { notify } = useNotification();

  const nuevaExtensionFormConfig = {
    secciones: [
      {
        campos: [
          {
            nombre: "clienteextendidoid",
            tipoitemformularioid: 5,
            etiqueta: "Cliente",
            requerido: true,
            opciones: "admin/cliente",
            sinOpcionInicial: true,
          },
        ],
      },
    ],
    submit: { callback: generarExtension },
    botones: [
      { etiqueta: "Generar extension", tipo: "submit", clases: "verde" },
    ],
  };

  // Función ejecutada para extender el certificado a la empresa seleccionada
  function generarExtension(camposFormulario) {
    setLoaderExtension(true);
    accessAPI(
      "POST",
      `admin/certificado/extension/${certificadoId}`,
      camposFormulario,
      (respuesta) => {
        setLoaderExtension(false);
        setExtensiones((extensionesActuales) => [
          ...extensionesActuales,
          respuesta,
        ]);
      },
      (respuesta) => {
        setLoaderExtension(false);
        notify({ mensaje: respuesta[0].msg, error: true, temporal: true });
      }
    );
  }

  // Función ejecutada para dar de baja una extensión de certificado
  function cancelarExtension(extensionId) {
    setLoaderCancelar(true);
    accessAPI(
      "PUT",
      `admin/certificado/extension/${extensionId}`,
      null,
      (respuesta) => {
        setLoaderCancelar(false);
        // Recorre las extensiones para marcar la seleccionada como no activa
        const extensionesModificadas = extensiones.map((e) => {
          if (e.id === extensionId) {
            let nuevoE = JSON.parse(JSON.stringify(e));
            nuevoE.activo = false;
            nuevoE.fechabaja = new Date();
            return nuevoE;
          } else {
            return e;
          }
        });
        setExtensiones(extensionesModificadas);
      },
      (respuesta) => {
        setLoaderCancelar(false);
        notify({ mensaje: respuesta[0].msg, error: true, temporal: true });
      }
    );
  }

  return (
    <div className="tarjeta">
      <h1>Extension del certificado</h1>
      {extensiones.length === 0 && (
        <p className="gris">
          No hay extensiones ingresadas para este certificado.
        </p>
      )}
      {extensiones.length !== 0 && (
        <div>
          <h2>Extensiones existentes</h2>
          {extensiones.map((extension) => {
            return (
              <div
                key={extension.id}
                className="flexContainer extensionCertificado"
              >
                <div className="flexContainer vertical">
                  <div>
                    {extension.activo && (
                      <span>
                        <img
                          className="checkExtension"
                          src={checkIcono}
                          alt="Activo"
                        />
                      </span>
                    )}
                    {!extension.activo && (
                      <span>
                        <img
                          className="checkExtension"
                          src={crossIcono}
                          alt="Activo"
                        />
                      </span>
                    )}
                    {extension.clienteextendido?.razonsocial}
                  </div>
                  <div className="fechaExtension">
                    <span className="gris"> desde </span>
                    <span className="verde">
                      {formatFecha(extension.fechaextension)}
                    </span>
                  </div>
                  {extension.fechabaja && (
                    <div className="fechaExtension">
                      <span className="gris"> hasta </span>
                      <span className="rojo">
                        {formatFecha(extension.fechabaja)}
                      </span>
                    </div>
                  )}
                </div>
                <div className="ancho" />
                {extension.activo && (
                  <Boton
                    clases="chico rojo centrado"
                    callback={() => {
                      cancelarExtension(extension.id);
                    }}
                    confirmacion={true}
                    loader={loaderCancelar}
                  >
                    Cancelar
                  </Boton>
                )}
              </div>
            );
          })}
        </div>
      )}
      <div className="division" />
      <div>
        <h2>Nueva extension</h2>
        <Formulario
          config={nuevaExtensionFormConfig}
          loader={loaderExtension}
        />
      </div>
    </div>
  );
}
