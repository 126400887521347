import { useState } from "react";
import CampoEditable from "../../elementos/campoEditable/CampoEditable";
import { downloadFile, formatFecha } from "../../utils/utils";
import Boton from "../../elementos/boton/Boton";

export default function TarjetaCompetencia(props) {
  const [loaderDescarga, setLoaderDescarga] = useState(false);
  const { competencia } = props;

  // Descarga la evidencia de la competencia de S3
  function descargarEvidencia() {
    if (loaderDescarga) {
      return;
    }
    setLoaderDescarga(true);
    downloadFile(
      `admin/archivo/${competencia.archivo.id}`,
      competencia.archivo.nombredescarga,
      (respuesta) => {
        setLoaderDescarga(false);
      },
      (respuesta) => {
        alert(respuesta[0].msg);
        setLoaderDescarga(false);
      }
    );
  }

  return (
    <div className="tarjeta" key={competencia.id}>
      <CampoEditable etiqueta="Número" valor={competencia.norma?.numero} />
      <CampoEditable
        etiqueta="Fecha de acreditación"
        valor={formatFecha(competencia.fechaformacion)}
      />
      <CampoEditable
        etiqueta="Es revisor técnico"
        valor={competencia.revisor ? "Si" : "No"}
        boolean={true}
      />
      <CampoEditable
        etiqueta="Activo"
        valor={competencia.activo}
        campo="activo"
        entidadid={competencia.id}
        endpoint={`admin/auditor/competencia`}
        booleano={true}
      />
      {competencia.archivo && (
        <Boton loader={loaderDescarga} callback={descargarEvidencia}>
          Descargar{" "}
          {competencia.archivo.nombredescarga.length < 40
            ? competencia.archivo.nombredescarga
            : competencia.archivo.nombredescarga.substr(0, 31) +
              "... " +
              competencia.archivo.nombredescarga.substr(-4)}
        </Boton>
      )}
    </div>
  );
}
