import { useState, useEffect } from "react";
import { accessAPI } from "../../utils/utils.js";
import Loader from "../../elementos/loader/Loader.js";
import TarjetaDetallesPagoAuditor from "./TarjetaDetallesPagoAuditor";
import Boton from "../../elementos/boton/Boton";

import DataTable from "react-data-table-component";

export default function TarjetaPagoAuditor(props) {
  const [loader, setLoader] = useState(true);
  const [loaderBoton, setLoaderBoton] = useState(false);
  const [procesosParaPago, setProcesosParaPago] = useState();
  const [pendienteDePago, setPendienteDePago] = useState(0);

  const { auditor } = props;

  const columnas = [
    {
      name: "Proceso",
      selector: (proceso) =>
        `${proceso.certificadotipo.procesotipo.letra}-${proceso.numero}`,
    },
    {
      name: "Monto",
      selector: (proceso) => proceso.pagoauditor[0]?.monto,
      right: true,
    },
    {
      name: "Pagado",
      selector: (proceso) => {
        return (
          <>
            {proceso.pagoauditor[0]?.fechapagado && (
              <span className="punto verde"></span>
            )}
          </>
        );
      },
      center: true,
    },
  ];

  // Función ejecutada por el componente de detalles de pago para registrar un pago
  function registrarPago(pago) {
    // Recorre los procesos buscando el pagado
    let procesosParaPagoActual = JSON.parse(JSON.stringify(procesosParaPago));
    for (const proceso of procesosParaPagoActual) {
      if (proceso.id === pago.nuevoPago.procesoid) {
        proceso.pagoauditor = [pago.nuevoPago];
      }
    }
    setProcesosParaPago(procesosParaPagoActual);
    setPendienteDePago(pago.montoPendiente);
  }

  // Cuando carga el componente, va a buscar los datos de pago del auditor
  useEffect(() => {
    setLoader(true);
    accessAPI(
      "GET",
      `admin/auditor/pagos/${auditor.id}`,
      null,
      (respuesta) => {
        setProcesosParaPago(respuesta.procesos);
        setPendienteDePago(respuesta.montoPendiente);
      },
      (respuesta) => {
        alert(respuesta[0].msg);
        setLoader(false);
      }
    );
  }, [auditor]);

  useEffect(() => {
    if (procesosParaPago) {
      setLoader(false);
    }
  }, [procesosParaPago]);

  // Marca todos los procesos pendientes de pago que tienen precio como pagos
  function marcarTodosPagos() {
    setLoaderBoton(true);
    accessAPI(
      "POST",
      "admin/auditor/pagos/marcartodospagos",
      { auditorid: auditor.id },
      (respuesta) => {
        setLoaderBoton(false);
        setProcesosParaPago(respuesta);
        setPendienteDePago(0);
      },
      (respuesta) => {
        console.log(respuesta);
        setLoaderBoton(false);
      }
    );
  }

  return (
    <>
      {loader && <Loader>Cargando datos</Loader>}
      {!loader && procesosParaPago && (
        <>
          <div className="titulo centrado">Procesos para pago</div>
          <div className="tarjeta ">
            <DataTable
              columns={columnas}
              data={procesosParaPago}
              striped
              expandableRows
              expandableRowsComponent={TarjetaDetallesPagoAuditor}
              expandOnRowClicked
              expandableRowsHideExpander
              expandableRowsComponentProps={{ registrarPago, auditor }}
              dense
            />
            <div className="pendienteDePago">
              Pendiente de pago: {pendienteDePago}
            </div>
            <Boton
              clases="verde centrado"
              callback={marcarTodosPagos}
              loader={loaderBoton}
              confirmacion={true}
            >
              Marcar todos pagos
            </Boton>
          </div>
        </>
      )}
    </>
  );
}
