import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { accessAPI } from "../../utils/utils.js";
import Formulario from "../../elementos/formulario/Formulario";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion";
import Footer from "../../elementos/footer/Footer";

import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function NuevaNorma() {
  const [loaderEnvio, setLoaderEnvio] = useState(false);

  const { notify } = useNotification();

  const navigate = useNavigate();

  const crearNormaFormConfig = {
    secciones: [
      {
        nombre: "Datos de la norma",
        campos: [
          {
            nombre: "numero",
            tipoitemformularioid: 1,
            etiqueta: "Número",
            requerido: true,
          },
          {
            nombre: "nombre",
            tipoitemformularioid: 1,
            etiqueta: "Nombre",
            requerido: true,
          },
          {
            nombre: "acreditada",
            tipoitemformularioid: 6,
            etiqueta: "Acreditada",
            requerido: false,
          },
          {
            nombre: "nombrecertificado",
            tipoitemformularioid: 1,
            etiqueta: "Nombre del Certificado",
            requerido: true,
          },
          {
            nombre: "puntosdeseguimiento",
            tipoitemformularioid: 9,
            etiqueta: "Puntos de Seguimiento",
            requerido: false,
          },
        ],
      },
    ],
    submit: { callback: crearNormaSubmit },
    botones: [{ etiqueta: "Crear norma", tipo: "submit", clases: "azul" }],
  };

  function crearNormaSubmit(camposFormulario) {
    setLoaderEnvio(true);
    accessAPI(
      "POST",
      "admin/norma",
      camposFormulario,
      (respuesta) => {
        navigate(`/normas/listado/${respuesta.id}`);
      },
      (respuesta) => {
        // Si la API da error, devuelve el error al cliente
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
        setLoaderEnvio(false);
      }
    );
  }

  return (
    <div className="seccion normas">
      <MenuNavegacion submenuSeleccionado="config" />
      <Footer />
      <div className="contenido">
        <div className="tarjeta">
          <Formulario
            config={crearNormaFormConfig}
            className="nuevoCliente"
            loader={loaderEnvio}
          />
        </div>
      </div>
    </div>
  );
}
