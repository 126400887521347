import { useState, useEffect } from "react";
import Loader from "../loader/Loader";
import Punto from "./Punto";
import Formulario from "../formulario/Formulario";
import { accessAPI, APIerror } from "../../utils/utils.js";

export default function Capitulo(props) {
  const [loader, setLoader] = useState(false);
  const [contenido, setContenido] = useState([]);
  const [mostrandoExpandido, setMostrandoExpandido] = useState(false);
  const [agregandoContenido, setAgregandoContenido] = useState(false);
  const [tiposItemDocumentoHijosItem, setTiposItemDocumentoHijosItem] =
    useState();

  const agregarContenidoFormConfig = {
    campos: [
      {
        nombre: "tipoitemdocumentoid",
        tipoitemformularioid: 5,
        etiqueta: "Tipo",
        requerido: true,
        sinOpcionInicial: true,
        opciones: props.tiposItemDocumento,
      },
      {
        nombre: "numero",
        tipoitemformularioid: 1,
        etiqueta: "Numero",
        requerido: false,
      },
      {
        nombre: "titulo",
        tipoitemformularioid: 1,
        etiqueta: "Título",
        requerido: false,
      },
      {
        nombre: "contenido",
        tipoitemformularioid: 1,
        etiqueta: "Contenido",
        requerido: false,
      },
    ],

    submit: { callback: enviarContenido },
    botones: [
      { etiqueta: "Enviar", tipo: "submit" },
      {
        etiqueta: "Cancelar",
        callback: () => {
          setAgregandoContenido(false);
        },
        tipo: "button",
      },
    ],
  };

  // Si se setea como expandido sin contenido, apaga el loader
  useEffect(() => {
    if (mostrandoExpandido) {
      setLoader(false);
    }
  }, [mostrandoExpandido]);

  // Cuando se carga contenido en el capítulo, apaga el loader
  // y muestra la sección
  useEffect(() => {
    // Cuando se cargan los tipos de item, determina cuáles pueden ser
    // hijos de un tipo item
    if (props.tiposItemDocumento) {
      let tiposItemDocumentoHijosItem = [];
      props.tiposItemDocumento.forEach((item) => {
        if (item.puedeserhijoitem) {
          tiposItemDocumentoHijosItem.push(item);
        }
      });
      setTiposItemDocumentoHijosItem(tiposItemDocumentoHijosItem);
    }

    if (contenido.length !== 0 && props.tiposItemDocumento) {
      setMostrandoExpandido(true);
      setLoader(false);
    }
  }, [contenido, props.tiposItemDocumento]);

  // Va a buscar el contenido si no lo tiene
  // si lo tiene, lo muestra
  function expandirCapitulo() {
    if (contenido.length !== 0) {
      setMostrandoExpandido(true);
      return;
    }

    setLoader(true);
    accessAPI(
      "GET",
      `admin/${props.tipodocumentonorma}/${props.documentonorma}/${props.capitulo.id}`,
      null,
      (response) => {
        // Si la respuesta tiene contenido, lo cargo
        if (response.length) {
          setContenido(response);
        }
        // Si la respuesta no tiene contenido, setea el expandido para mostrarlo
        setMostrandoExpandido(true);
      },
      APIerror
    );
  }

  // Callback del form de agregar contenido
  function enviarContenido(contenidoAAgregar) {
    contenidoAAgregar.padreId = props.capitulo.id;

    accessAPI(
      "POST",
      `admin/${props.tipodocumentonorma}/punto/${props.documentonorma}`,
      contenidoAAgregar,
      (response) => {
        // Cierra el formulario
        setAgregandoContenido(false);
        // Agrega el contenido al state
        if (contenido.length === 0) {
          setContenido([response]);
          return;
        }
        let contenidoActual = [...contenido];
        contenidoActual.push(response);
        setContenido(contenidoActual);
      },
      (response) => {
        console.log(response);
      }
    );
  }

  return (
    <div>
      <div>
        <h1>
          {props.capitulo.numero && <span>{props.capitulo.numero} - </span>}
          {props.capitulo.titulo}
        </h1>
        {loader && <Loader>Cargando capitulo</Loader>}
        {!loader && !mostrandoExpandido && (
          <button onClick={expandirCapitulo}>Expandir</button>
        )}
        {!loader && mostrandoExpandido && (
          <div>
            {!contenido && <div>Este capítulo no tiene contenido</div>}
            {contenido && (
              <div>
                <button
                  onClick={() => {
                    setMostrandoExpandido(false);
                  }}
                >
                  Cerrar
                </button>
                {contenido.map((punto) => {
                  return (
                    <Punto
                      punto={punto}
                      documentonorma={props.documentonorma}
                      tipodocumentonorma={props.tipodocumentonorma}
                      tiposItemDocumento={props.tiposItemDocumento}
                      tiposItemDocumentoHijosItem={tiposItemDocumentoHijosItem}
                      key={punto.id}
                    />
                  );
                })}
              </div>
            )}
            {!props.bloqueado && (
              <>
                {!agregandoContenido && (
                  <button
                    onClick={() => {
                      setAgregandoContenido(true);
                    }}
                  >
                    Agregar contenido
                  </button>
                )}
                {agregandoContenido && (
                  <div>
                    <Formulario config={agregarContenidoFormConfig} />
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
