import { useState, useEffect } from "react";
import CampoEditable from "../../elementos/campoEditable/CampoEditable.js";
import { accessAPI } from "../../utils/utils.js";
import Boton from "../../elementos/boton/Boton";

export default function TarjetaProductoProceso(props) {
  const [camposPropios, setCamposPropios] = useState();
  const [cambiosProductoCertificado, setCambiosProductoCertificado] = useState(
    []
  );

  const [marcadoParaEliminar, setMarcadoParaEliminar] = useState(false);
  const [loaderEliminarProducto, setLoaderEliminarProducto] = useState(false);

  const { data, eliminarProductoDeProceso } = props;

  // Cuando entra por primera vez, procesa los campos propios del producto
  useEffect(() => {
    if (data.producto_x_productocampospropios) {
      setCamposPropios(data.producto_x_productocampospropios);
    }
    if (data.cambioproductocertificado?.length) {
      setCambiosProductoCertificado(data.cambioproductocertificado);
    }
  }, [
    data?.producto_x_productocampospropios,
    data.vigente,
    data.cambioproductocertificado,
  ]);

  // Cuando hay un cambio en los cambiosProductoCertificado verifica qeu alguno sea de eliminar el producto
  useEffect(() => {
    if (cambiosProductoCertificado.length) {
      let flagMarcadoParaEliminar = false;
      // Recorre los cambios y setea el flag si alguno es de baja
      for (const cambioProductoCertificado of cambiosProductoCertificado) {
        if (cambioProductoCertificado.bajaproducto) {
          flagMarcadoParaEliminar = true;
        }
      }
      setMarcadoParaEliminar(flagMarcadoParaEliminar);
    }
  }, [cambiosProductoCertificado]);

  // Función del boton deshacer cambio
  function eliminarCambioProductoCertificado(cambioProductoCertificadoId) {
    accessAPI(
      "DELETE",
      `admin/producto/cambioproductocertificado/${cambioProductoCertificadoId}`,
      null,
      (respuesta) => {
        // Busca el producto modificado en state
        let cambiosActuales = [...cambiosProductoCertificado];

        for (const [index, cambio] of cambiosActuales.entries()) {
          if (cambio.id === cambioProductoCertificadoId) {
            cambiosActuales.splice(index, 1);
          }
        }
        if (cambiosActuales.length) {
          setCambiosProductoCertificado(cambiosActuales);
        } else {
          setCambiosProductoCertificado([]);
        }
      },
      (respuesta) => {
        alert(respuesta[0].msg);
      }
    );
  }

  // Función llamada luego de que se ingresa un cambio en un campo propio para mostrarlo en la UI
  function callbackIngresarCambios(respuesta) {
    // Se fija si la respuesta viene con un valor nuevo, si no, es porque el certificado no está emitido entonces no tiene que hacer nada, se actualiza el campo directametne en el producto y listo
    if (!respuesta.valornuevo) {
      return;
    }
    // Recorre los cambios sin emitir
    let cambiosActuales = [...cambiosProductoCertificado];
    let indexEncontrado = -1;
    for (const [index, cambio] of cambiosActuales.entries()) {
      // Busca si el cambio fue el mismo campo
      if (cambio.productocampopropioid === respuesta.productocampopropioid)
        indexEncontrado = index;
    }

    // Si no lo encontró, lo agrega
    if (indexEncontrado === -1) {
      cambiosActuales.push({
        producto_x_productocampospropios: {
          productocampospropios: { etiqueta: respuesta.etiqueta },
        },
        valornuevo: respuesta.valornuevo,
        productocampopropioid: respuesta.productocampopropioid,
        id: respuesta.id,
      });
    } else {
      // Si lo encontró, lo sustituye
      cambiosActuales.splice(indexEncontrado, 1, {
        producto_x_productocampospropios: {
          productocampospropios: { etiqueta: respuesta.etiqueta },
        },
        valornuevo: respuesta.valornuevo,
        productocampopropioid: respuesta.productocampopropioid,
        id: respuesta.id,
      });
    }
    setCambiosProductoCertificado(cambiosActuales);
  }

  // Marca el producto para eliminar en la próxima emisión
  function eliminarProducto() {
    setLoaderEliminarProducto(true);
    accessAPI(
      "PUT",
      `admin/producto/marcareliminar/${data.id}`,
      null,
      (respuesta) => {
        setLoaderEliminarProducto(false);
        // Si la API indica que hay que eliminar el producto del proceso, llama a la función
        if (respuesta[0]?.eliminar) {
          eliminarProductoDeProceso(data.id);
        } else {
          // Si no, agrega el cambio a state
          setCambiosProductoCertificado((cambiosProductoCertificado) => [
            ...cambiosProductoCertificado,
            respuesta,
          ]);
        }
      },
      (respuesta) => {
        setLoaderEliminarProducto(false);
        alert(respuesta[0]?.msg);
      }
    );
  }

  return (
    <>
      <div className="detallesProducto">
        {data.vigente === 0 && <div>Producto no vigente</div>}
        {data.vigente === 1 && (
          <>
            {camposPropios &&
              camposPropios.map((campoPropio, index) => {
                return (
                  <CampoEditable
                    etiqueta={campoPropio.productocampospropios?.etiqueta}
                    valor={campoPropio.valor}
                    key={index}
                    endpoint={"admin/producto"}
                    entidadid={campoPropio.id}
                    campo={campoPropio.id}
                    callback={callbackIngresarCambios}
                    dropdownopciones={
                      campoPropio.productocampospropios?.opciones?.length
                        ? campoPropio.productocampospropios.opciones.map(
                            (opcion) => {
                              return { value: opcion, etiqueta: opcion };
                            }
                          )
                        : null
                    }
                  />
                );
              })}
            {!data.altaemisioncertificado?.id && (
              <>
                <div className="division" />
                Producto aún no emitido
              </>
            )}
            {cambiosProductoCertificado?.length !== 0 && (
              <>
                <div className="division" />
                <div>
                  <h2>Cambios no emitidos:</h2>
                  {cambiosProductoCertificado.map((cambio) => {
                    return (
                      <>
                        {cambio.producto_x_productocampospropios && (
                          <div className="flexContainer spaceBetween">
                            <span>
                              {
                                cambio.producto_x_productocampospropios
                                  ?.productocampospropios.etiqueta
                              }{" "}
                              - {cambio.valornuevo}
                            </span>
                            <Boton
                              callback={() => {
                                eliminarCambioProductoCertificado(cambio.id);
                              }}
                              clases="inline chico rojo"
                              confirmacion={true}
                            >
                              Deshacer
                            </Boton>
                          </div>
                        )}
                        {cambio.bajaproducto && (
                          <div className="flexContainer spaceBetween">
                            Producto dado de baja
                            <Boton
                              callback={() => {
                                eliminarCambioProductoCertificado(cambio.id);
                              }}
                              clases="inline chico rojo"
                              confirmacion={true}
                            >
                              Deshacer
                            </Boton>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </>
            )}
            {!marcadoParaEliminar && (
              <>
                <div className="division" />
                <div>
                  <Boton
                    clases="rojo chico margen"
                    confirmacion={true}
                    callback={eliminarProducto}
                    loader={loaderEliminarProducto}
                  >
                    Eliminar del certificado
                  </Boton>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
