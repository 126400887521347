import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../elementos/loader/Loader";
import { accessAPI } from "../../utils/utils.js";
import Formulario from "../../elementos/formulario/Formulario";

export default function FormularioProceso() {
  const [loader, setLoader] = useState(true);
  const [formularioProceso, setFormularioProceso] = useState();
  const [loaderMarcarVigente, setLoaderMarcarVigente] = useState(false);
  const [creandoNuevaVersion, setCreandoNuevaVersion] = useState(false);
  const [reiniciarFormulario, setReiniciarFormulario] = useState(1);
  const [tiposItemFormulario, setTiposItemFormulario] = useState();

  const navigate = useNavigate();

  // Carga el id del formulario de la URL
  const { formularioId } = useParams();

  const agregarItemFormConfig = {
    campos: [
      {
        nombre: "etiqueta",
        tipoitemformularioid: 1,
        etiqueta: "Nombre",
        requerido: true,
      },
      {
        nombre: "comentario",
        tipoitemformularioid: 1,
        etiqueta: "Comentario",
      },
      {
        nombre: "tipoitemformularioid",
        tipoitemformularioid: 5,
        etiqueta: "Tipo",
        requerido: true,
        opciones: tiposItemFormulario,
      },
      {
        nombre: "requerido",
        tipoitemformularioid: 6,
        etiqueta: "Requerido",
      },
    ],
    submit: { callback: agregarItem },
    botones: [{ etiqueta: "Agregar", tipo: "submit" }],
  };

  const nuevaVersionFormConfig = {
    campos: [
      {
        nombre: "version",
        tipoitemformularioid: 1,
        etiqueta: "Versión",
        requerido: true,
      },
    ],
    submit: { callback: crearNuevaVersion },
    botones: [{ etiqueta: "Cear", tipo: "submit" }],
  };

  // Cuando carga el componente, trae el formulario de la API
  // Y los tipos
  useEffect(() => {
    accessAPI(
      "GET",
      `admin/formulario/${formularioId}`,
      null,
      (response) => {
        setFormularioProceso(response);
      },
      (response) => {
        alert(response[0].msg);
      }
    );
    accessAPI(
      "GET",
      "admin/formulario/tipositemformulario",
      null,
      (response) => {
        setTiposItemFormulario(response);
      },
      (response) => {
        alert(response[0].msg);
      }
    );
  }, [formularioId]);

  // Cuando recibe el formulario de la API apaga el loader
  useEffect(() => {
    if (formularioProceso && tiposItemFormulario) {
      setLoader(false);
      setLoaderMarcarVigente(false);
      setCreandoNuevaVersion(false);
    }
  }, [formularioProceso, tiposItemFormulario]);

  // Función para liberar el formulario
  function marcarFormularioVigente() {
    if (
      !window.confirm(
        "¿Seguro que desea marcar este formulario como vigente? Suplantrá al actual vigente."
      )
    ) {
      return;
    }
    setLoaderMarcarVigente(true);
    accessAPI(
      "PUT",
      `admin/formulario/vigente/${formularioId}`,
      null,
      (datosLiberacion) => {
        // Carga los datos de liberación en el formulario
        let formularioProcesoActual = { ...formularioProceso };
        formularioProcesoActual.fechaliberacion =
          datosLiberacion.param.fechaliberacion;
        formularioProcesoActual.usuarioliberadornombre =
          datosLiberacion.param.usuarioliberadornombre;
        formularioProcesoActual.usuarioliberadorapellido =
          datosLiberacion.param.usuarioliberadorapellido;
        formularioProcesoActual.vigente = 1;
        setFormularioProceso(formularioProcesoActual);
      },
      (response) => {
        alert(response[0].msg);
        setLoaderMarcarVigente(false);
      }
    );
  }

  // Función para agregar un ítem al formulario
  function agregarItem(opciones) {
    accessAPI(
      "POST",
      `admin/formulario/${formularioId}/item`,
      opciones,
      (nuevoCampo) => {
        let formularioProcesoActual = { ...formularioProceso };
        formularioProcesoActual.campos.push(nuevoCampo);
        setFormularioProceso(formularioProcesoActual);
      },
      (response) => {
        console.log(response);
      }
    );
  }

  // Crea una nueva versión del formulario editable
  // y navega ahí
  function crearNuevaVersion(opciones) {
    accessAPI(
      "POST",
      `admin/formulario/${formularioId}/duplicar`,
      opciones,
      (nuevoFormularioId) => {
        navigate(`/formulario/${nuevoFormularioId.id}`);
      },
      (response) => {
        alert(response[0].msg);
        setReiniciarFormulario(reiniciarFormulario + 1);
      }
    );
  }

  return (
    <div>
      {loader && <Loader>Cargando formulario</Loader>}
      {!loader && formularioProceso && (
        <div>
          {!formularioProceso.fechaliberacion && (
            <div>
              Este formulario no está liberado
              {loaderMarcarVigente && <Loader>Procesando</Loader>}
              {!loaderMarcarVigente && !formularioProceso.noLiberable && (
                <div>
                  <button onClick={marcarFormularioVigente}>
                    Marcar versión vigente
                  </button>
                </div>
              )}
            </div>
          )}
          {formularioProceso.vigente && (
            <div>Este formulario es el vigente.</div>
          )}
          {!formularioProceso.vigente && (
            <div>
              <span>Este formulario no es el vigente</span>
              {formularioProceso.versionVigente && (
                <span>
                  <a href={`${formularioProceso.versionVigente}`}>
                    , ir al vigente
                  </a>
                </span>
              )}
            </div>
          )}
          <div>
            <span>
              {formularioProceso.codigo} v {formularioProceso.version} -{" "}
              {formularioProceso.nombre}
            </span>
          </div>
          {formularioProceso.fechaliberacion && (
            <div>
              Liberado por {formularioProceso.usuarioliberadornombre}{" "}
              {formularioProceso.usuarioliberadorapellido} en{" "}
              {new Date(
                formularioProceso.fechaliberacion * 1000
              ).toLocaleString("es-UY")}
            </div>
          )}
          <div>Campos:</div>
          {formularioProceso.campos.map((campo) => {
            return (
              <div key={campo.id}>
                {campo.etiqueta} -{" "}
                {
                  tiposItemFormulario[
                    tiposItemFormulario.findIndex(
                      (elemento) =>
                        elemento.value === campo.tipoitemformularioid
                    )
                  ].etiqueta
                }
                {campo.requerido && <span> - Requerido</span>}
                {campo.comentario && <span> - {campo.comentario}</span>}
              </div>
            );
          })}
          {!formularioProceso.fechaliberacion && (
            <div>
              Agregar item{" "}
              <Formulario
                config={agregarItemFormConfig}
                key={formularioProceso.campos.length}
              />
            </div>
          )}
          {formularioProceso.fechaliberacion && (
            <div>
              <div>Los formularios liberados no pueden ser modificados.</div>
              {!creandoNuevaVersion && (
                <button
                  onClick={() => {
                    setCreandoNuevaVersion(true);
                  }}
                >
                  Crear una nueva versión de este formulario
                </button>
              )}
              {creandoNuevaVersion && (
                <Formulario
                  config={nuevaVersionFormConfig}
                  key={reiniciarFormulario}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
