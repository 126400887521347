import { useState, useEffect, useRef } from "react";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion";
import Footer from "../../elementos/footer/Footer";
import Loader from "../../elementos/loader/Loader";
import EnviadoIndividual from "./EnviadoIndividual";
import Boton from "../../elementos/boton/Boton";
import { accessAPI } from "../../utils/utils.js";
import "./enviados.css";

import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function Enviados() {
  const [loader, setLoader] = useState(true);

  const [enviados, setEnviados] = useState();
  const [paginador, setPaginador] = useState();
  const [paginaSeleccionada, setPaginaSeleccionada] = useState(1);
  const [rangoFechas, setRangoFechas] = useState();

  const [toggleConEnvio, setToggleConEnvio] = useState(true);

  const { notify } = useNotification();

  const selectRef = useRef();

  // Cuando carga el componente, va a buscar los mensajes
  useEffect(() => {
    // función que arma el paginador con el número total y setea la página seleccionada
    function armarPaginador(numeroTotalPaginas) {
      let paginador = [];
      for (let i = 1; i <= numeroTotalPaginas; i++) {
        paginador.push({ numero: i, seleccionada: i === paginaSeleccionada });
      }
      return paginador;
    }

    setLoader(true);

    const url = toggleConEnvio
      ? `admin/comunicacion/mensajes/${paginaSeleccionada}?envio=true`
      : `admin/comunicacion/mensajes/${paginaSeleccionada}`;

    accessAPI(
      "GET",
      url,
      null,
      (respuesta) => {
        setEnviados(respuesta.eventos);
        // Configura el paginador
        setPaginador(armarPaginador(respuesta.paginas));
        setRangoFechas(`${respuesta.primeraFecha} - ${respuesta.ultimaFecha}`);
      },
      (respuesta) => {
        setPaginaSeleccionada(1);
        notify({
          mensaje: respuesta[0].msg,
          error: true,
          temporal: true,
        });
      }
    );
  }, [paginaSeleccionada, toggleConEnvio, notify]);

  // Cuando carga los mails enviados, apaga el loader
  useEffect(() => {
    if (enviados) {
      setLoader(false);
    }
  }, [enviados]);

  return (
    <div className="seccion">
      <MenuNavegacion submenuSeleccionado="comunicacion" />
      <Footer />
      <div className="contenido">
        {loader && <Loader>Cargando comunicaciones</Loader>}
        {!loader && enviados && (
          <>
            <h1>Eventos del sistema</h1>
            <div className="flexContainer paginador">
              {paginador && (
                <select
                  className="prettyInput"
                  ref={selectRef}
                  value={paginaSeleccionada}
                  onChange={() => {
                    setPaginaSeleccionada(selectRef.current.value);
                  }}
                >
                  {paginador.map((pagina) => {
                    return (
                      <option value={pagina.numero} key={pagina.numero}>
                        Página {pagina.numero}
                      </option>
                    );
                  })}
                </select>
              )}
              {rangoFechas && <h1>Fechas: {rangoFechas}</h1>}
              <Boton
                clases="verde"
                callback={() => {
                  setToggleConEnvio(!toggleConEnvio);
                }}
              >
                {toggleConEnvio && <span>Ver todos</span>}
                {!toggleConEnvio && <span>Ver sólo enviados</span>}
              </Boton>
            </div>
            <div className="tarjetasContainer">
              {enviados.map((evento) => {
                return <EnviadoIndividual key={evento.id} evento={evento} />;
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
