import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { accessAPI, storeInLS } from "../../utils/utils.js";
import Loader from "../../elementos/loader/Loader";

export default function Login() {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (state.oauthtoken) {
      accessAPI(
        "POST",
        "oauth/onetimesignon",
        { oauthtoken: state.oauthtoken },
        (response) => {
          storeInLS(process.env.REACT_APP_LS_LOGIN_TOKEN, response.token);
          navigate("/");
        },
        (response) => {
          window.location.href = process.env.REACT_APP_LOGIN_URL;
        }
      );
    } else {
      window.location.href = process.env.REACT_APP_LOGIN_URL;
    }
  }, [state, navigate]);

  return (
    <div className="seccion fullPageLoader">
      <Loader color="blanco" />
    </div>
  );
}
