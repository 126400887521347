import "./auditor.css";
import { useState } from "react";
import { accessAPI, formatFecha } from "../../utils/utils.js";
import Formulario from "../../elementos/formulario/Formulario";
import Boton from "../../elementos/boton/Boton";

export default function TarjetaDetallesPagoAuditor(props) {
  const [loader, setLoader] = useState(false);

  const { data, auditor } = props;

  const ingresarPagoFormConfig = {
    secciones: [
      {
        campos: [
          {
            nombre: "monto",
            tipoitemformularioid: 4,
            etiqueta: "Monto",
            requerido: true,
          },
        ],
      },
    ],
    botones: [
      {
        etiqueta: "No Pago",
        clases: "rojo",
        callback: (parametros) => {
          marcarPago(parametros, false);
        },
      },
      {
        etiqueta: "Pago",
        clases: "verde",
        callback: (parametros) => {
          marcarPago(parametros, true);
        },
      },
    ],
  };

  // Marcar auditoría como paga
  function marcarPago(parametros, pago) {
    // Si no hay un monto definito no manda
    // Tengo que hacer esto porque tengo dos botones sin submit en el formulario y no hace la validación automática
    if (!parametros.monto) {
      return;
    }

    setLoader(true);

    // Si pagó, marco hoy como la fecha de pago
    const fecha = pago ? new Date() : null;

    accessAPI(
      "POST",
      "admin/auditor/pagos",
      { ...parametros, fecha, procesoid: data.id, auditorid: auditor.id },
      (respuesta) => {
        props.registrarPago(respuesta);
        setLoader(false);
      },
      (respuesta) => {
        alert(respuesta[0]?.msg);
      }
    );
  }

  // Función ejecutada para marcar un pago existente como pagado
  function marcarPagoPagado() {
    setLoader(true);
    accessAPI(
      "PUT",
      `admin/auditor/pagos/pagado/${data.pagoauditor[0]?.id}`,
      null,
      (respuesta) => {
        props.registrarPago(respuesta);
        setLoader(false);
      },
      (respuesta) => {
        alert(respuesta[0]?.msg);
      }
    );
  }

  return (
    <div className="detallesPago">
      {data.pagoauditor.length === 0 && (
        <div>
          <h1>Ingresar pago</h1>
          {data.cliente && data.cliente.nombrefantasia && (
            <p>{data.cliente.nombrefantasia}</p>
          )}
          {data.nombrefantasia && <p>{data.nombrefantasia}</p>}
          <Formulario config={ingresarPagoFormConfig} loader={loader} />
        </div>
      )}
      {data.pagoauditor.length !== 0 && !data.pagoauditor[0].fechapagado && (
        <div>
          {data.cliente && data.cliente.nombrefantasia && (
            <p>{data.cliente.nombrefantasia}</p>
          )}
          {data.nombrefantasia && <p>{data.nombrefantasia}</p>}
          <p className="negrita">Proceso aún no pagado</p>
          <Boton
            clases="verde centrado"
            callback={marcarPagoPagado}
            loader={loader}
          >
            Marcar pagado
          </Boton>
        </div>
      )}
      {data.pagoauditor[0]?.fechapagado && (
        <div>
          Proceso pagado el {formatFecha(data.pagoauditor[0].fechapagado)}
        </div>
      )}
    </div>
  );
}
