import Boton from "../../elementos/boton/Boton";

export default function TarjetaProcesosVigentes(props) {
  const { procesos } = props;

  return (
    <div>
      <div className="tarjeta chica">
        <h1 className="titulo">Procesos vigentes de esta solicitud</h1>
        {procesos.map((proceso) => {
          return (
            <Boton
              clases="texto"
              uri={`/proceso/${proceso.id}`}
              key={proceso.id}
            >{`${proceso.certificadotipo.procesotipo?.letra}-${proceso.numero} - ${proceso.nombrefantasia}`}</Boton>
          );
        })}
      </div>
    </div>
  );
}
