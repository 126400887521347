import { useState } from "react";
import Formulario from "../../elementos/formulario/Formulario";
import { accessAPI } from "../../utils/utils";
import EsquemaEnCertificadoTipo from "./EsquemaEnCertificadoTipo";

export default function TarjetaAsignarEsquema(props) {
  const [agregarEsquemaLoader, setAgregarEsquemaLoader] = useState(false);

  const [esquemas, setEsquemas] = useState(props.esquemas);
  const { certificadoTipoId } = props;

  const formualrioAgregarEsquemaConfig = {
    secciones: [
      {
        campos: [
          {
            nombre: "esquemaid",
            tipoitemformularioid: 5,
            etiqueta: "Nuevo esquema",
            opciones: "admin/config/esquema?mostrarnombre=true",
            sinOpcionInicial: true,
            requerido: true,
          },
        ],
      },
    ],
    submit: { callback: agregarEsquema },
    botones: [{ etiqueta: "Agregar esquema", tipo: "submit", clases: "azul" }],
  };

  // Función ejecutada por el formulario para agregar el esquema
  function agregarEsquema(datosFormulario) {
    setAgregarEsquemaLoader(true);
    accessAPI(
      "PUT",
      `admin/config/certificadotipo/${certificadoTipoId}/esquema`,
      datosFormulario,
      (respuesta) => {
        setEsquemas((nuevoEsquema) => [...esquemas, respuesta]);
        setAgregarEsquemaLoader(false);
      },
      (respuesta) => {
        alert(respuesta[0].msg);
        setAgregarEsquemaLoader(false);
      }
    );
  }

  return (
    <div className="tarjeta tresCuartos">
      <h1>Esquemas utilizados</h1>
      {esquemas.map((esquema) => {
        return (
          <EsquemaEnCertificadoTipo
            esquema={esquema}
            certificadoTipoId={certificadoTipoId}
            key={esquema.id}
          />
        );
      })}
      <Formulario
        config={formualrioAgregarEsquemaConfig}
        loader={agregarEsquemaLoader}
        key={esquemas.length}
      />
    </div>
  );
}
