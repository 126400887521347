import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { accessAPI } from "../../utils/utils.js";
import Formulario from "../../elementos/formulario/Formulario.js";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion.js";
import Footer from "../../elementos/footer/Footer.js";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function NuevaCaracteristicaProducto() {
  const [loaderEnvio, setLoaderEnvio] = useState(false);

  const { notify } = useNotification();

  const { certificadoTipoId } = useParams();

  const navigate = useNavigate();

  const crearCaracteristicaProductoFormConfig = {
    secciones: [
      {
        nombre: "Nueva característica de producto",
        campos: [
          {
            nombre: "etiqueta",
            tipoitemformularioid: 1,
            etiqueta: "Etiqueta",
            requerido: true,
          },
          {
            nombre: "nombre",
            tipoitemformularioid: 1,
            etiqueta: "Nombre para URSEA",
            requerido: true,
          },
          {
            nombre: "notas",
            tipoitemformularioid: 1,
            etiqueta: "Notas en formulario",
          },
          {
            nombre: "tipo",
            tipoitemformularioid: 5,
            etiqueta: "Tipo de datos",
            requerido: true,
            opciones: [
              { value: "string", etiqueta: "Texto" },
              { value: "int", etiqueta: "Número entero" },
              { value: "float", etiqueta: "Número decimal" },
            ],
            sinOpcionInicial: true,
          },
          {
            nombre: "opciones",
            tipoitemformularioid: 1,
            etiqueta:
              "Opciones (separadas por punto y coma rodeado de espacios)",
            requerido: false,
          },
          {
            nombre: "obligatorio",
            tipoitemformularioid: 5,
            etiqueta: "Campo obligatorio",
            opciones: [
              { value: "0", etiqueta: "Opcional" },
              { value: 1, etiqueta: "Obligatorio" },
            ],
          },
          {
            nombre: "mostrarencertificado",
            tipoitemformularioid: 5,
            etiqueta: "Mostrar en certificado",
            opciones: [
              { value: "0", etiqueta: "No mostrar" },
              { value: 1, etiqueta: "Mostrar" },
            ],
          },
          {
            nombre: "enviaraursea",
            tipoitemformularioid: 5,
            etiqueta: "Enviar a URSEA",
            opciones: [
              { value: "0", etiqueta: "No enviar" },
              { value: 1, etiqueta: "Enviar" },
            ],
          },
          {
            nombre: "identificadorursea",
            tipoitemformularioid: 6,
            etiqueta: "Campo identificador para URSEA",
          },
        ],
      },
    ],
    submit: { callback: crearCaracteristicaProducto },
    botones: [
      {
        etiqueta: "Agregar característica de producto",
        tipo: "submit",
        clases: "azul",
      },
      {
        etiqueta: "Cancelar",
        clases: "rojo",
        callback: () => {
          navigate(-1);
        },
      },
    ],
  };

  function crearCaracteristicaProducto(respuestasFormulario) {
    setLoaderEnvio(true);
    accessAPI(
      "POST",
      `admin/producto/certificadotipo/${certificadoTipoId}/productocampospropios`,
      respuestasFormulario,
      (respuesta) => {
        navigate(-1);
      },
      (respuesta) => {
        // Si la API da error, devuelve el error al cliente
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
        setLoaderEnvio(false);
      }
    );
  }

  return (
    <div className="seccion">
      <MenuNavegacion submenuSeleccionado="config" />
      <Footer />
      <div className="contenido">
        <div className="tarjetasContainer">
          <div className="tarjeta tresCuartos">
            <Formulario
              config={crearCaracteristicaProductoFormConfig}
              loader={loaderEnvio}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
