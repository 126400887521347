import { useState, useEffect } from "react";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion";
import Footer from "../../elementos/footer/Footer";
import Loader from "../../elementos/loader/Loader";
import { accessAPI } from "../../utils/utils";
import SelectorCriterio from "./SelectorCriterio";
import Boton from "../../elementos/boton/Boton";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function Comunicaciones() {
  const [loader, setLoader] = useState(true);
  const [loaderBoton, setLoaderBoton] = useState(false);

  const [clientesConCertificado, setClientesConCertificado] = useState(false);
  const [mailsClientes, setMailsClientes] = useState();

  const { notify } = useNotification();
  const [certificadosTipo, setCertificadosTipo] = useState();

  // Cuando carga el componente, va a buscar el listado de clientes
  useEffect(() => {
    accessAPI(
      "GET",
      "admin/config/certificadotipo",
      null,
      (respuesta) => {
        setCertificadosTipo(respuesta);
      },
      (respuesta) => {
        notify({
          texto: respuesta[0].msg,
          error: true,
          temporal: true,
        });
      }
    );
  }, [notify]);

  // Cuando carga los certificados tipo, apaga el loader
  useEffect(() => {
    if (certificadosTipo) {
      setLoader(false);
    }
  }, [certificadosTipo]);

  // Función disparada por cada selector
  function toggleCertificadoTipo(id, valor) {
    // Recorre los certificados tipo, busca el cambiado y le asigna el valor
    let certificadosTipoActual = JSON.parse(JSON.stringify(certificadosTipo));
    for (const certificadoTipo of certificadosTipoActual) {
      if (certificadoTipo.value === id) {
        certificadoTipo.checked = valor;
      }
    }
    setCertificadosTipo(certificadosTipoActual);
  }

  // Función ejecutada para obtener las direcciones luego del filtro
  function obtenerDirecciones() {
    setLoaderBoton(true);
    setMailsClientes();
    accessAPI(
      "POST",
      "admin/cliente/comunicaciones",
      { certificadosTipo, clientesConCertificado },
      (respuesta) => {
        setMailsClientes(respuesta.mailsClientes);
        setLoaderBoton(false);
      },
      (respuesta) => {
        setLoaderBoton(false);
        notify({
          texto: respuesta[0].msg,
          error: true,
          temporal: true,
        });
      }
    );
  }

  return (
    <div className="seccion clientes">
      <MenuNavegacion submenuSeleccionado="comunicacion" />
      <Footer />
      {loader && <Loader>Cargando</Loader>}
      {!loader && (
        <div className="contenido">
          <div className="tarjetasContainer">
            <div className="tarjeta">
              <h1>Filtrar por certificado tipo</h1>
              {certificadosTipo &&
                certificadosTipo.map((certificadoTipo) => {
                  return (
                    <SelectorCriterio
                      key={certificadoTipo.value}
                      id={certificadoTipo.value}
                      nombre={certificadoTipo.etiqueta}
                      toggleSelector={toggleCertificadoTipo}
                    />
                  );
                })}
              <h1>Filtrar por certificado vigente</h1>
              <SelectorCriterio
                nombre="Con certificados emitidos"
                toggleSelector={(id, valor) => {
                  setClientesConCertificado(valor);
                }}
              />
              <Boton
                callback={obtenerDirecciones}
                loader={loaderBoton}
                clases="verde"
              >
                Obtener direcciones
              </Boton>
            </div>
            <div className="tarjeta">
              <h1>Direcciones filtradas</h1>
              {mailsClientes}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
