import CampoEditable from "../../elementos/campoEditable/CampoEditable.js";
import { formatFecha } from "../../utils/utils.js";
import Boton from "../../elementos/boton/Boton";

export default function TarjetaDetallesProceso(props) {
  const { proceso } = props;

  return (
    <>
      <div className="tarjeta">
        <h1>
          Proceso {proceso.certificadotipo.procesotipo?.letra}-{proceso.numero}
        </h1>
        <CampoEditable
          etiqueta="Nombre Fantasía"
          campo="nombrefantasia"
          valor={proceso.nombrefantasia}
          entidadid={proceso.id}
          endpoint="admin/proceso"
        />
        <CampoEditable
          etiqueta="Cliente"
          valor={proceso.cliente.nombrefantasia}
          campo="clienteid"
          entidadid={proceso.id}
          endpoint="admin/proceso"
          dropdownurl={"admin/cliente"}
        />
        <CampoEditable
          etiqueta="Certificado tipo"
          valor={proceso.certificadotipo.nombre}
          campo="certificadotipoid"
          entidadid={proceso.id}
          endpoint="admin/proceso"
          dropdownurl={"admin/config/certificadotipo"}
        />
        <CampoEditable
          etiqueta="Norma"
          valor={proceso.norma ? proceso.norma.numero : null}
          campo="normaid"
          entidadid={proceso.id}
          endpoint="admin/proceso"
          dropdownurl={"admin/norma"}
          noEditable={
            proceso.producto.length !== 0 && "Este proceso ya tiene productos"
          }
        />
        <CampoEditable
          etiqueta="Esquema"
          campo="esquemaid"
          valor={proceso.esquema.etiqueta}
          entidadid={proceso.id}
          endpoint="admin/proceso"
          dropdownurl={`admin/config/esquema?certificadotipoid=${proceso.certificadotipo.id}`}
        />
        <CampoEditable
          etiqueta="Nombre del fabricante"
          campo="fabricantenombre"
          valor={proceso.fabrica.fabricantenombre}
          entidadid={proceso.id}
          endpoint="admin/proceso"
        />
        <CampoEditable
          etiqueta="Direccion del fabricante"
          campo="fabricantedireccion"
          valor={proceso.fabrica.fabricantedireccion}
          entidadid={proceso.id}
          endpoint="admin/proceso"
        />
        <CampoEditable
          etiqueta="Nombre de la fábrica"
          campo="fabricanombre"
          valor={proceso.fabrica.fabricanombre}
          entidadid={proceso.id}
          endpoint="admin/proceso"
        />
        <CampoEditable
          etiqueta="Dirección de la fábrica"
          campo="fabricadireccion"
          valor={proceso.fabrica.fabricadireccion}
          entidadid={proceso.id}
          endpoint="admin/proceso"
        />
        <CampoEditable
          etiqueta="Auditor"
          campo="auditorid"
          valor={
            proceso.auditor
              ? `${proceso.auditor?.nombre} ${proceso.auditor?.apellido}`
              : null
          }
          entidadid={proceso.id}
          endpoint="admin/proceso"
          dropdownurl="admin/auditor/dropdown"
        />
        <CampoEditable
          etiqueta="Revisor"
          campo="revisorid"
          valor={
            proceso.revisor
              ? `${proceso.revisor?.nombre} ${proceso.revisor?.apellido}`
              : null
          }
          entidadid={proceso.id}
          endpoint="admin/proceso"
          dropdownurl="admin/auditor/dropdown"
        />
        <CampoEditable
          etiqueta="Fecha de ingreso de solicitud"
          campo="fcert01"
          valor={
            proceso.solicitudproceso?.id
              ? formatFecha(proceso.solicitudproceso?.fecha)
              : formatFecha(proceso.fcert01)
          }
          entidadid={proceso.id}
          endpoint="admin/proceso"
          esFecha={true}
        />
        <CampoEditable
          etiqueta="Fecha de evaluación de factibilidad"
          campo="fcert02"
          valor={
            proceso.factibilidad[0]?.fecha &&
            proceso.factibilidad[0]?.procesofactible
              ? formatFecha(proceso.factibilidad[0]?.fecha)
              : proceso.fcert02
              ? formatFecha(proceso.fcert02)
              : null
          }
          entidadid={proceso.id}
          endpoint="admin/proceso"
          esFecha={true}
        />
        <CampoEditable
          etiqueta="Fecha de ingreso de FCERT03"
          campo="fcert03"
          valor={formatFecha(proceso.fcert03)}
          entidadid={proceso.id}
          endpoint="admin/proceso"
          esFecha={true}
        />
        <CampoEditable
          etiqueta="Fecha de ingreso de FCERT04"
          campo="fcert04"
          valor={formatFecha(proceso.fcert04)}
          entidadid={proceso.id}
          endpoint="admin/proceso"
          esFecha={true}
        />
        <CampoEditable
          etiqueta="Fecha de ingreso de FCERT05"
          campo="fcert05"
          valor={formatFecha(proceso.fcert05)}
          entidadid={proceso.id}
          endpoint="admin/proceso"
          esFecha={true}
        />
        <CampoEditable
          etiqueta="Fecha de ingreso de FCERT08"
          campo="fcert08"
          valor={formatFecha(proceso.fcert08)}
          entidadid={proceso.id}
          endpoint="admin/proceso"
          esFecha={true}
        />
        {proceso.solicitudproceso && (
          <Boton
            clases="texto"
            uri={`/solicitud/${proceso.solicitudproceso.id}`}
          >
            Ver solicitud
          </Boton>
        )}
      </div>
    </>
  );
}
