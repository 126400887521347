import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./notificacionSistema.css";
import { accessAPI } from "../../utils/utils";
import flechaDerechaIcono from "../../images/flechaDerechaIcono.png";

export default function NotificacionSistema(props) {
  const navigate = useNavigate();
  const [procesada, setProcesada] = useState(false);

  const [loader, setLoader] = useState(false);

  function marcarNotificacionProcesada() {
    if (!loader) {
      setLoader(true);
      accessAPI(
        "PUT",
        `notificacion/${props.notificacion.id}`,
        null,
        (respuesta) => {
          setProcesada(true);
        },
        (respuesta) => {
          alert(respuesta[0].msg);
        }
      );
    }
  }

  return (
    <div className="notificacionSistema">
      <div className="titulo">
        <span
          onClick={() => {
            navigate(
              `${props.notificacion.notificaciontipo.uri}${props.notificacion.recursoid}`
            );
          }}
        >
          {props.notificacion.notificaciontipo.titulo}
        </span>
        <img
          src={flechaDerechaIcono}
          className="flechaNavegacion"
          alt="ir"
          onClick={() => {
            navigate(
              `${props.notificacion.notificaciontipo.uri}${props.notificacion.recursoid}`
            );
          }}
        />
        {!procesada && (
          <button
            onClick={marcarNotificacionProcesada}
            className={loader ? "cargando" : ""}
          >
            Marcar procesada
          </button>
        )}

        {procesada && <span className="procesada"> - procesada</span>}
      </div>
      <div className="texto">
        {props.notificacion.notificaciontipo.texto} -{" "}
        {props.notificacion.customtext}
      </div>
    </div>
  );
}
