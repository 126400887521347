import { useState } from "react";
import { accessAPI } from "../../utils/utils.js";
import Formulario from "../../elementos/formulario/Formulario";
export default function EnviarResultadoDeAnalisis(props) {
  const [loader, setLoader] = useState(false);

  const { mostrarMensaje } = props;
  const configFormularioEnvioResultados = {
    secciones: [
      {
        nombre: "Enviar resultado de análisis",
        campos: [
          {
            nombre: "nroItemDni",
            tipoitemformularioid: 1,
            etiqueta: "Número de ítem DNI",
            requerido: true,
          },
          {
            nombre: "nroAnalisisLatu",
            tipoitemformularioid: 4,
            etiqueta: "Número de análisis",
            requerido: true,
          },
          {
            nombre: "conformidadAnalisis",
            tipoitemformularioid: 4,
            etiqueta: "Conformidad de análisis",
            requerido: true,
          },
          {
            nombre: "razonNoConformidad",
            tipoitemformularioid: 4,
            etiqueta: "Razón de no conformidad",
          },
          {
            nombre: "estado",
            tipoitemformularioid: 4,
            etiqueta: "Estado",
            requerido: true,
          },
        ],
      },
    ],
    submit: { callback: enviarResultadoDeAnalisis },
    botones: [{ etiqueta: "Enviar", tipo: "submit" }],
  };

  function enviarResultadoDeAnalisis(camposFormulario) {
    setLoader(true);
    accessAPI(
      "POST",
      "dni/juguetes/test/enviarResultadoAnalisis",
      camposFormulario,
      (respuesta) => {
        alert(JSON.stringify(respuesta));
        setLoader(false);
      },
      (respuesta) => {
        mostrarMensaje({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
        setLoader(false);
      }
    );
  }

  return (
    <div className="tarjeta tresCuartos">
      <Formulario loader={loader} config={configFormularioEnvioResultados} />
    </div>
  );
}
