import "./certificado.css";
import "./cambioEstadoCertificado.css";
import { formatFecha } from "../../utils/utils";

export default function CambioEstadoCertificado(props) {
  return (
    <div className="cambioEstadoCertificado">
      <div>
        {formatFecha(props.cambioestado.fechacambio)} -{" "}
        <span
          className={props.cambioestado.nuevoestado.id === 1 ? "verde" : "rojo"}
        >
          {props.cambioestado.nuevoestado.nombre}
        </span>
        <span className="gris">
          {` - por: ${props.cambioestado.usuario.administrador[0].nombre}
          ${props.cambioestado.usuario.administrador[0].apellido} `}
        </span>
      </div>
      <div className="gris">{props.cambioestado.notas}</div>
    </div>
  );
}
