import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { accessAPI, downloadFile } from "../../utils/utils.js";
import Loader from "../../elementos/loader/Loader.js";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion.js";
import Footer from "../../elementos/footer/Footer.js";
import CampoEditable from "../../elementos/campoEditable/CampoEditable.js";
import TarjetaProductoCampoPropio from "./TarjetaProductoCampoPropio";
import TarjetaAsignarEsquema from "./TarjetaAsignarEsquema";
import TarjetaAsignarNorma from "./TarjetaAsignarNorma";
import Boton from "../../elementos/boton/Boton";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

import DataTable from "react-data-table-component";

export default function CertificadoTipo() {
  const [loader, setLoader] = useState(true);

  const { notify } = useNotification();

  const [certificadoTipo, setCertificadoTipo] = useState();

  const [loaderDescargarPlantilla, setLoaderDescargarPlantilla] =
    useState(false);

  const { certificadoTipoId } = useParams();

  const columnas = [
    {
      name: "Etiqueta",
      selector: (campoPropio) => campoPropio.etiqueta,
      grow: 2,
    },
    {
      name: "Tipo",
      selector: (campoPropio) => campoPropio.tipo,
    },
    {
      name: "Opciones",
      selector: (campoPropio) =>
        campoPropio.opciones.length ? "Opciones" : "",
    },
  ];

  // Cuando carga el componente va a buscar el tipo de certificado de la URL
  useEffect(() => {
    accessAPI(
      "GET",
      `admin/producto/certificadotipo/${certificadoTipoId}`,
      null,
      (respuesta) => {
        setCertificadoTipo(respuesta.certificadoTipo);
      },
      (respuesta) => {
        // Si la API da error, devuelve el error al cliente
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
      }
    );
  }, [certificadoTipoId, notify]);

  // Cuando carga los tipos de certifiacado, apaga el loader
  useEffect(() => {
    if (certificadoTipo) {
      setLoader(false);
    }
  }, [certificadoTipo]);

  // Descarga la planilla de template de productos que utilizan los clientes para solicitud
  function descargarPlantilla() {
    setLoaderDescargarPlantilla(true);
    downloadFile(
      `cliente/proceso/plantilla/${certificadoTipo.id}`,
      `Plantilla productos - ${certificadoTipo.codigo}`,
      (respuesta) => {
        setLoaderDescargarPlantilla(false);
      },
      (respuesta) => {
        setLoaderDescargarPlantilla(false);
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
      }
    );
  }

  // Descarga la planilla de template de productos que utilizan los auditores para proceso
  function descargarPlantillaAuditoria() {
    setLoaderDescargarPlantilla(true);
    downloadFile(
      `admin/proceso/plantilla/${certificadoTipo.id}`,
      `Plantilla productos auditoria - ${certificadoTipo.codigo}`,
      (respuesta) => {
        setLoaderDescargarPlantilla(false);
      },
      (respuesta) => {
        setLoaderDescargarPlantilla(false);
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
      }
    );
  }

  return (
    <div className="seccion">
      <MenuNavegacion submenuSeleccionado="config" />
      <Footer />
      <div className="contenido">
        {loader && <Loader>Cargando certificado tipo</Loader>}
        {!loader && certificadoTipo && (
          <div className="tarjetasContainer">
            <div className="tarjeta tresCuartos">
              <CampoEditable
                etiqueta="Código"
                valor={certificadoTipo.codigo}
                campo="codigo"
                entidadid={certificadoTipo.id}
                endpoint="admin/config/certificadotipo"
              />
              <CampoEditable
                etiqueta="Nombre"
                valor={certificadoTipo.nombre}
                campo="nombre"
                entidadid={certificadoTipo.id}
                endpoint="admin/config/certificadotipo"
              />
              <CampoEditable
                etiqueta="Reglamentación"
                valor={certificadoTipo.reglamentacion}
                campo="reglamentacion"
                entidadid={certificadoTipo.id}
                endpoint="admin/config/certificadotipo"
              />
              <CampoEditable
                etiqueta="Texto legal para certificado"
                valor={certificadoTipo.textolegalcertificado}
                campo="textolegalcertificado"
                entidadid={certificadoTipo.id}
                endpoint="admin/config/certificadotipo"
              />
              <CampoEditable
                etiqueta="Tipo de proceso"
                valor={certificadoTipo?.procesotipo?.nombre}
                campo="procesotipoid"
                entidadid={certificadoTipo.id}
                endpoint="admin/config/certificadotipo"
                dropdownurl="admin/config/procesotipo"
              />
              <CampoEditable
                etiqueta="Duración del certificado (años)"
                valor={certificadoTipo.duracioncertificado}
                campo="duracioncertificado"
                entidadid={certificadoTipo.id}
                endpoint="admin/config/certificadotipo"
              />
              <CampoEditable
                etiqueta="Disponible para solicitud"
                valor={certificadoTipo.disponiblesolicitud}
                campo="disponiblesolicitud"
                entidadid={certificadoTipo.id}
                endpoint="admin/config/certificadotipo"
                booleano={true}
              />
              <CampoEditable
                etiqueta="Disponible para proceso"
                valor={certificadoTipo.disponibleproceso}
                campo="disponibleproceso"
                entidadid={certificadoTipo.id}
                endpoint="admin/config/certificadotipo"
                booleano={true}
              />
              <CampoEditable
                etiqueta="Gestionado por URSEA"
                valor={certificadoTipo.gestionadoursea}
                campo="gestionadoursea"
                entidadid={certificadoTipo.id}
                endpoint="admin/config/certificadotipo"
                booleano={true}
              />
              <CampoEditable
                etiqueta="Nombre certificado URSEA"
                valor={certificadoTipo.nombreursea}
                campo="nombreursea"
                entidadid={certificadoTipo.id}
                endpoint="admin/config/certificadotipo"
              />
            </div>
            <TarjetaAsignarEsquema
              esquemas={certificadoTipo.certificadotipo_x_esquema}
              certificadoTipoId={certificadoTipoId}
            />
            <TarjetaAsignarNorma
              normas={certificadoTipo.certificadotipo_x_norma}
              certificadoTipoId={certificadoTipoId}
            />
            <div className="tarjeta tresCuartos">
              <Boton
                clases="verde centrado"
                callback={descargarPlantilla}
                loader={loaderDescargarPlantilla}
              >
                Descargar plantilla de producto para solicitud
              </Boton>
              <Boton
                clases="verde centrado"
                callback={descargarPlantillaAuditoria}
                loader={loaderDescargarPlantilla}
              >
                Descargar plantilla de producto para auditoría
              </Boton>
            </div>
            <Boton
              clases="centrado verde"
              uri={`/certificadotipos/${certificadoTipoId}/nuevacaracteristicaproducto`}
            >
              Agregar característica de producto
            </Boton>
            <div className="tarjeta tresCuartos">
              <DataTable
                columns={columnas}
                data={certificadoTipo.productocampospropios}
                striped
                expandableRows
                expandableRowsComponent={TarjetaProductoCampoPropio}
                expandOnRowClicked
                expandableRowsHideExpander
                dense
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
