import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion";
import Footer from "../../elementos/footer/Footer";
import ConfirmarPagoAnalisis from "./ConfirmarPagoAnalisis";
import EnviarResultadoDeAnalisis from "./EnviarResultadoDeAnalisis";
import ObtenerTestReport from "./ObtenerTestReport";
import ComunicarReevaluacion from "./ComunicarReevaluacion";

import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function JuguetesOperaciones() {
  const { notify } = useNotification();
  return (
    <div className="seccion">
      <MenuNavegacion submenuSeleccionado="juguetes" />
      <Footer />
      <div className="contenido">
        <div className="tarjetasContainer">
          <ConfirmarPagoAnalisis
            mostrarMensaje={(mensaje) => {
              notify({ mensaje, error: false, temopral: false });
            }}
          />
          <ObtenerTestReport
            mostrarMensaje={(mensaje) => {
              notify({ mensaje, error: false, temopral: false });
            }}
          />
          <EnviarResultadoDeAnalisis
            mostrarMensaje={(mensaje) => {
              notify({ mensaje, error: false, temopral: false });
            }}
          />
          <ComunicarReevaluacion
            mostrarMensaje={(mensaje) => {
              notify({ mensaje, error: false, temopral: false });
            }}
          />
        </div>
      </div>
    </div>
  );
}
