import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { accessAPI } from "../../utils/utils.js";
import Loader from "../../elementos/loader/Loader";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion.js";
import Footer from "../../elementos/footer/Footer";
import LaboratorioDetalle from "./LaboratorioDetalle";
import Boton from "../../elementos/boton/Boton";
import "./laboratorios.css";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function Laboratorios() {
  const [loader, setLoader] = useState(true);
  const [laboratorios, setLaboratorios] = useState();
  const [loaderLaboratorio, setLoaderLaboratorio] = useState(false);
  const [laboratorioSeleccionado, setLaboratorioSeleccionado] = useState();
  const [datosLaboratorioSeleccionado, setDatosLaboratorioSeleccionado] =
    useState();

  const { notify } = useNotification();

  const { laboratorioId } = useParams();

  // Cuando carga el componente, va a buscar la lista de laboratorios
  useEffect(() => {
    accessAPI(
      "GET",
      "admin/laboratorio",
      null,
      (response) => {
        setLaboratorios(response);
      },
      (respuesta) => {
        // Si la API da error, devuelve el error al laboratorio
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
      }
    );
    // Si le pidió un laboratorio en particular, lo carga
    setLaboratorioSeleccionado(laboratorioId);
  }, [laboratorioId, notify]);

  // Cuando carga los laboratorios apaga el loader
  useEffect(() => {
    if (laboratorios) {
      setLoader(false);
    }
  }, [laboratorios]);

  // Cuando selecciona a un laboratorio va a buscar los detalles
  useEffect(() => {
    if (laboratorioSeleccionado) {
      // Prende el loader y limpa al laboratorio seleccionado
      setDatosLaboratorioSeleccionado();
      setLoaderLaboratorio(true);
      accessAPI(
        "GET",
        `admin/laboratorio/${laboratorioSeleccionado}`,
        null,
        (respuesta) => {
          setDatosLaboratorioSeleccionado(respuesta);
        },
        (respuesta) => {
          // Si la API da error, devuelve el error al laboratorio
          notify({
            mensaje: respuesta[0].msg,
            temporal: true,
            error: true,
          });
          setLoaderLaboratorio(false);
          setLaboratorioSeleccionado();
        }
      );
    }
  }, [laboratorioSeleccionado, notify]);

  return (
    <div className="seccion laboratorios">
      <MenuNavegacion submenuSeleccionado="proveedores" />
      <Footer />
      <div className="contenido">
        <h1>Laboratorios</h1>
        <Boton uri="/proveedores/laboratorios/nuevo" clases="verde centrado">
          Nuevo laboratorio
        </Boton>
        {loader && <Loader>Cargando laboratorios</Loader>}
        {!loader && laboratorios && (
          <>
            <div className="flexContainer">
              <div className="tarjetasContainer">
                <div className="tarjeta listadoLaboratorios">
                  {laboratorios.map((laboratorio) => {
                    return (
                      <div
                        className={
                          laboratorioSeleccionado === laboratorio.value
                            ? "laboratorio  seleccionado"
                            : "laboratorio"
                        }
                        key={laboratorio.value}
                      >
                        <div
                          onClick={() => {
                            setLaboratorioSeleccionado(laboratorio.value);
                          }}
                        >
                          {laboratorio.etiqueta}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="tarjetasContainer">
                {!datosLaboratorioSeleccionado && loaderLaboratorio && (
                  <Loader>Cargando laboratorio</Loader>
                )}

                {datosLaboratorioSeleccionado && (
                  <LaboratorioDetalle
                    laboratorio={datosLaboratorioSeleccionado}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
