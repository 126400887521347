import { useState, useEffect } from "react";
import Loader from "../../elementos/loader/Loader.js";
import { accessAPI } from "../../utils/utils.js";
import checkIcono from "../../images/checkIcono.png";
import crossIcono from "../../images/crossIcono.png";
import Boton from "../../elementos/boton/Boton";

import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function Factibilidad(props) {
  const [loader, setLoader] = useState(false);
  const [loaderEvaluarFactibilidad, setLoaderEvaluarFactibilidad] =
    useState(false);
  const [factibilidad, setFactibilidad] = useState({});
  // Necesito indicar cuando cargó la factibilidad porque muchos procesos no la tienen creada aún
  const [factibilidadCargada, setFactibilidadCargada] = useState(false);

  const { notify } = useNotification();

  // Se crea la factibilidad
  function evaluarFactibilidad() {
    setLoaderEvaluarFactibilidad(true);
    accessAPI(
      "PUT",
      `admin/proceso/factibilidad/${props.proceso.id}`,
      null,
      (respuesta) => {
        setFactibilidad(respuesta);
        setLoaderEvaluarFactibilidad(false);
      },
      (respuesta) => {
        // Si la API da error, devuelve el error al cliente
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
        setLoaderEvaluarFactibilidad(false);
      }
    );
  }

  // Cuando carga el proceso evalua su factibilidad
  useEffect(() => {
    setLoader(true);
    accessAPI(
      "GET",
      `admin/proceso/factibilidad/${props.proceso.id}`,
      null,
      (respuesta) => {
        setFactibilidad(respuesta);
        setFactibilidadCargada(true);
        setLoader(false);
      },
      (respuesta) => {
        // Si la API da error, devuelve el error al cliente
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
        setLoader(false);
      }
    );
  }, [props.proceso, notify]);

  const renderEntidadStatus = (entidad, value, valorEncontrado) => {
    return (
      <div className="etiquetaFactibilidad">
        <img
          className="iconoStatus"
          src={value ? checkIcono : crossIcono}
          alt="status"
        />
        {entidad}
        {value && valorEncontrado && <span>: {valorEncontrado}</span>}
      </div>
    );
  };

  return (
    <div className="tarjeta centradoCertificados tarjetaCertificados">
      {loader && <Loader>Cargando la factibilidad</Loader>}
      {!loader && <h1>Factibilidad del proceso</h1>}
      {factibilidadCargada && (
        <div>
          {renderEntidadStatus(
            "Auditor",
            factibilidad?.auditor?.id,
            `${factibilidad?.auditor?.nombre} ${factibilidad?.auditor?.apellido}`
          )}
          {renderEntidadStatus(
            "Revisor",
            factibilidad?.revisor?.id,
            `${factibilidad?.revisor?.nombre} ${factibilidad?.revisor?.apellido}`
          )}
          {renderEntidadStatus(
            "Laboratorio",
            factibilidad?.laboratorio?.id,
            factibilidad?.laboratorio?.nombrefantasia
          )}
          {renderEntidadStatus(
            "Puntos de seguimiento",
            factibilidad?.puntosdeseguimiento
          )}
        </div>
      )}
      {(!factibilidad || !factibilidad?.procesofactible) && (
        <Boton
          clases="centrado verde"
          callback={evaluarFactibilidad}
          loader={loaderEvaluarFactibilidad}
        >
          Evaluar factibilidad
        </Boton>
      )}
    </div>
  );
}
