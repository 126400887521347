import "./clientes.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { accessAPI } from "../../utils/utils.js";
import Loader from "../../elementos/loader/Loader";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion.js";
import Footer from "../../elementos/footer/Footer";
import Boton from "../../elementos/boton/Boton";
import DataTable from "react-data-table-component";

export default function Clientes() {
  const [loader, setLoader] = useState(true);
  const [clientes, setClientes] = useState();

  const navigate = useNavigate();

  const columnas = [
    {
      name: "Nombre",
      selector: (cliente) => `${cliente.etiqueta}`,
    },
    {
      name: "Certificados",

      selector: (cliente) => {
        if (cliente.certificados > 0) {
          return (
            <span className="verde negrita">{`${cliente.certificados}`}</span>
          );
        }
      },
      maxWidth: "120px",
      center: true,

    },
  ];

  // Cuando carga el componente, va a buscar la lista de clientes
  useEffect(() => {
    accessAPI(
      "GET",
      "admin/cliente",
      null,
      (response) => {
        setClientes(response);
      },
      (response) => {
        console.log(response);
      }
    );
  }, []);

  // Cuando carga los clientes apaga el loader
  useEffect(() => {
    if (clientes) {
      setLoader(false);
    }
  }, [clientes]);

  return (
    <div className="seccion clientes">
      <MenuNavegacion submenuSeleccionado="clientes" />
      <Footer />
      <div className="contenido">
        <Boton uri="/clientes/nuevo" clases="verde centrado">
          Nuevo cliente
        </Boton>
        {loader && <Loader>Cargando clientes</Loader>}
        {!loader && clientes && (
          <div className="tarjeta">
            <DataTable
              columns={columnas}
              data={clientes}
              highlightOnHover
              pointerOnHover
              striped
              dense
              onRowClicked={(row, event) => {
                navigate(`/clientes/${row.value}`);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
