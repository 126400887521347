import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { accessAPI } from "../../utils/utils.js";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion";
import Footer from "../../elementos/footer/Footer";
import Loader from "../../elementos/loader/Loader";
import ProcesoFormularioEnsayo from "./ProcesoFormularioEnsayo";
import GenerarCertificado from "./GenerarCertificado";
import TarjetaDetallesProceso from "./TarjetaDetallesProceso";
import TarjetaProcesoActivo from "./TarjetaProcesoActivo";
import ProcesoProductos from "./ProcesoProductos";
import Factibilidad from "./Factibilidad.js";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function Proceso() {
  const [loader, setLoader] = useState(true);
  const [proceso, setProceso] = useState();

  const [testReports, setTestReports] = useState([]);

  const { notify } = useNotification();

  // Carga el id del proceso de la URL
  const { procesoId } = useParams();

  function agregarTestReportAProceso(respuesta) {
    // Si el array ya tiene productos, lo agrega
    setTestReports([...testReports, respuesta.nuevoReporteEnsayo]);
    // Le avisa al usuario
    notify({
      mensaje: "Reporte de ensayo agregado exitosamente",
      temporal: true,
      error: false,
    });
  }

  // Cuando carga el componente va a buscar el proceso
  useEffect(() => {
    // Tengo que agregar este if para que no vuelva a llamar a la API cuando cambia otro objeto del componente
    if (!proceso) {
      accessAPI(
        "GET",
        `admin/proceso/${procesoId}`,
        null,
        (respuesta) => {
          setProceso(respuesta);
        },
        (respuesta) => {
          // Si la API da error, devuelve el error al cliente
          notify({
            mensaje: respuesta[0].msg,
            temporal: true,
            error: true,
          });
        }
      );
    }
  }, [proceso, procesoId, notify]);

  // Cuando carga el proceso y los tipos de pasos apaga el loader
  useEffect(() => {
    if (proceso) {
      // Si tiene test reports, los carga en su propio state
      if (proceso.actividad[0]?.reporteensayo.length) {
        setTestReports(proceso.actividad[0]?.reporteensayo);
      }
      setLoader(false);
    }
  }, [proceso]);

  return (
    <div className="seccion procesos">
      <MenuNavegacion submenuSeleccionado="procesos" />
      <Footer />
      <div className="contenido">
        {loader && <Loader>Cargando el proceso</Loader>}
        {!loader && (
          <div className="flexContainer">
            <div className="tarjetasContainer">
              <TarjetaDetallesProceso proceso={proceso} />
              <ProcesoProductos proceso={proceso} />
            </div>
            <div className="tarjetasContainer">
              <GenerarCertificado proceso={proceso} testReports={testReports} />
              <TarjetaProcesoActivo proceso={proceso} />
              <ProcesoFormularioEnsayo
                proceso={proceso}
                testReports={testReports}
                agregarTestReportAProceso={agregarTestReportAProceso}
              />
              <Factibilidad proceso={proceso} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
