import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion";
import Footer from "../../elementos/footer/Footer";

export default function ClientesProveedoresPortada() {
  return (
    <div className="seccion clientes">
      <MenuNavegacion submenuSeleccionado="clientes" />
      <Footer />
      <div className="contenido">Portada de clientes</div>
    </div>
  );
}
