import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { accessAPI } from "../../utils/utils.js";
import Formulario from "../../elementos/formulario/Formulario";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion";
import Footer from "../../elementos/footer/Footer";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function NuevoAuditor() {
  const [loader, setLoader] = useState(false);

  const { notify } = useNotification();

  const navigate = useNavigate();

  const crearAuditorFormConfig = {
    secciones: [
      {
        nombre: "Datos del auditor",
        campos: [
          {
            nombre: "nombre",
            tipoitemformularioid: 1,
            etiqueta: "Nombre",
            requerido: true,
          },
          {
            nombre: "apellido",
            tipoitemformularioid: 1,
            etiqueta: "Apellido",
            requerido: true,
          },
          {
            nombre: "cedula",
            tipoitemformularioid: 1,
            etiqueta: "Cédula de identidad",
            requerido: true,
          },
          {
            nombre: "email",
            tipoitemformularioid: 2,
            etiqueta: "Email",
            requerido: false,
          },
          {
            nombre: "telefono",
            tipoitemformularioid: 1,
            etiqueta: "Teléfono",
            requerido: false,
          },
        ],
      },
    ],
    submit: { callback: crearAuditor },
    botones: [{ etiqueta: "Crear auditor", tipo: "submit", clases: "azul" }],
  };

  function crearAuditor(camposFormulario) {
    setLoader(true);
    accessAPI(
      "POST",
      "admin/auditor",
      camposFormulario,
      (respuesta) => {
        setLoader(false);
        navigate(`/proveedores/auditores/${respuesta.id}`);
      },
      // navigate(`/normas/listado/${respuesta.id}`);
      (respuesta) => {
        // Si la API da error, devuelve el error al cliente
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
      }
    );
  }
  return (
    <div className="seccion normas">
      <MenuNavegacion submenuSeleccionado="proveedores" />
      <Footer />
      <div className="contenido">
        <div className="tarjeta">
          <Formulario
            config={crearAuditorFormConfig}
            className="nuevoCliente"
            loader={loader}
          />
        </div>
      </div>
    </div>
  );
}
