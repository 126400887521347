import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { accessAPI } from "../../utils/utils.js";
import Formulario from "../../elementos/formulario/Formulario";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion";
import Footer from "../../elementos/footer/Footer";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function NuevoCertificadoTipo() {
  const [loaderEnvio, setLoaderEnvio] = useState(false);

  const { notify } = useNotification();

  const navigate = useNavigate();

  const crearCertificadoTipoFormConfig = {
    secciones: [
      {
        nombre: "Datos de tipo de certificado",
        campos: [
          {
            nombre: "codigo",
            tipoitemformularioid: 1,
            etiqueta: "Código",
            requerido: true,
          },
          {
            nombre: "nombre",
            tipoitemformularioid: 1,
            etiqueta: "Nombre",
            requerido: true,
          },
          {
            nombre: "reglamentacion",
            tipoitemformularioid: 1,
            etiqueta: "Reglamentación",
            requerido: true,
          },
          {
            nombre: "textolegalcertificado",
            tipoitemformularioid: 1,
            etiqueta: "Texto legal en el certificado",
            requerido: true,
          },
          {
            nombre: "procesotipoid",
            tipoitemformularioid: 5,
            etiqueta: "Tipo de proceso",
            opciones: "admin/config/procesotipo",
            sinOpcionInicial: true,
            requerido: true,
          },
          {
            nombre: "duracioncertificado",
            tipoitemformularioid: 4,
            etiqueta: "Duración del certificado (años)",
            requerido: true,
          },
          {
            nombre: "disponiblesolicitud",
            tipoitemformularioid: 6,
            etiqueta: "Disponible para solicitud",
            requerido: false,
          },
          {
            nombre: "disponibleproceso",
            tipoitemformularioid: 6,
            etiqueta: "Disponible para procesos",
            requerido: false,
          },
          {
            nombre: "gestionadoursea",
            tipoitemformularioid: 6,
            etiqueta: "Gestionado por URSEA",
            requerido: false,
          },
          {
            nombre: "nombreursea",
            tipoitemformularioid: 1,
            etiqueta: "Nombre en el certificado digital de URSEA",
            requerido: false,
          },
        ],
      },
    ],
    submit: { callback: crearCertificadoTipoSubmit },
    botones: [
      { etiqueta: "Crear tipo de certificado", tipo: "submit", clases: "azul" },
      {
        etiqueta: "Cancelar",
        clases: "rojo",
        callback: () => {
          navigate("/certificadotipos");
        },
      },
    ],
  };

  function crearCertificadoTipoSubmit(respuestasFormulario) {
    setLoaderEnvio(true);
    accessAPI(
      "POST",
      "admin/config/certificadotipo",
      respuestasFormulario,
      (respuesta) => {
        navigate("/certificadotipos");
      },
      (respuesta) => {
        // Si la API da error, devuelve el error al cliente
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
        setLoaderEnvio(false);
      }
    );
  }

  return (
    <div className="seccion normas">
      <MenuNavegacion submenuSeleccionado="config" />
      <Footer />
      <div className="contenido">
        <div className="tarjeta">
          <Formulario
            config={crearCertificadoTipoFormConfig}
            className="nuevoCliente"
            loader={loaderEnvio}
          />
        </div>
      </div>
    </div>
  );
}
