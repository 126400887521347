import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion";
import Footer from "../../elementos/footer/Footer";
import Loader from "../../elementos/loader/Loader";
import { accessAPI } from "../../utils/utils.js";
import "./solicitudes.css";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function Solicitudes() {
  const [loaderClientes, setLoaderClientes] = useState(true);
  const [loaderSolicitudes, setLoaderSolicitudes] = useState(false);
  const [clientes, setClientes] = useState();
  const [solicitudesPendientes, setSolicitudesPendientes] = useState();
  const [clienteSeleccionado, setClienteSeleccionado] = useState();
  const [solicitudes, setSolicitudes] = useState();

  const { notify } = useNotification();

  const navigate = useNavigate();
  useEffect(() => {
    // Cuando carga el componente va a buscar clientes para las solicitudes proceadas
    accessAPI(
      "GET",
      "admin/cliente?filtro=solicitudes",
      null,
      (response) => {
        setClientes(response);
      },
      (respuesta) => {
        // Si la API da error, devuelve el error al cliente
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
      }
    );

    // También va a buscar las solicitudes pendientes de proceso
    accessAPI(
      "GET",
      "admin/proceso/solicitud",
      null,
      (respuesta) => {
        setSolicitudesPendientes(respuesta);
      },
      (respuesta) => {
        // Si la API da error, devuelve el error al cliente
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
      }
    );
  }, [notify]);

  // Cuando selecciona a un cliente, va a buscar sus solicitudes
  useEffect(() => {
    if (clienteSeleccionado) {
      setLoaderSolicitudes(true);
      accessAPI(
        "GET",
        `admin/proceso/cliente/${clienteSeleccionado}/solicitud`,
        null,
        (respuesta) => {
          setSolicitudes(respuesta);
        },
        (respuesta) => {
          // Si la API da error, devuelve el error al cliente
          notify({
            mensaje: respuesta[0].msg,
            temporal: true,
            error: true,
          });
        }
      );
    }
  }, [clienteSeleccionado, notify]);

  // Cuando se cargan los clientes, apaga el loader
  useEffect(() => {
    if (clientes && solicitudesPendientes) {
      setLoaderClientes(false);
    }
  }, [clientes, solicitudesPendientes]);

  // Cuando se cargan las solicitudes, apaga el loader
  useEffect(() => {
    if (solicitudes) {
      setLoaderSolicitudes(false);
    }
  }, [solicitudes]);

  return (
    <div className="seccion">
      <MenuNavegacion submenuSeleccionado="procesos" />
      <Footer />
      <div className="contenido">
        {loaderClientes && <Loader>Cargando clientes</Loader>}
        {!loaderClientes && clientes && (
          <>
            <div className="flexContainer">
              <div className="tarjetasContainer">
                <div className="flexContainer vertical">
                  {solicitudesPendientes.length > 0 && (
                    <div className="tarjeta listadoSolicitudes">
                      <div className="titulo">Solicitudes pendientes</div>
                      {solicitudesPendientes.map((solicitud) => {
                        return (
                          <div
                            className="solicitud"
                            key={solicitud.id}
                            onClick={() => {
                              navigate(`/solicitud/${solicitud.id}`);
                            }}
                          >
                            {solicitud.cliente.nombrefantasia} -{" "}
                            {solicitud.nombrefantasia}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <div className="tarjeta listadoSolicitudes">
                    <div className="titulo">Solicitudes procesadas</div>
                    {clientes.map((cliente) => {
                      return (
                        <div
                          className={
                            clienteSeleccionado === cliente.value
                              ? "solicitud seleccionado"
                              : "solicitud"
                          }
                          key={cliente.value}
                        >
                          <div
                            onClick={() => {
                              setClienteSeleccionado(cliente.value);
                            }}
                          >
                            {cliente.etiqueta}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="tarjetasContainer">
                {loaderSolicitudes && <Loader>Cargando solicitudes</Loader>}
                {!loaderSolicitudes && solicitudes && (
                  <div className="tarjeta listadoSolicitudes">
                    {solicitudes.map((solicitud) => {
                      return (
                        <div className="solicitud" key={solicitud.id}>
                          <div
                            onClick={() => {
                              navigate(`/solicitud/${solicitud.id}`);
                            }}
                          >
                            {solicitud.nombrefantasia}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
