import texts from "../data/texts";

// Store data un LS
export function storeInLS(key, data) {
  localStorage.setItem(key, data);
}

// Read from LS
export function readFromLS(key) {
  return localStorage.getItem(key);
}

// Delete from LS
export function deleteFromLS(key) {
  return localStorage.removeItem(key);
}

// Operations for logging out the user
export function logout() {
  localStorage.removeItem(process.env.REACT_APP_LS_LOGIN_TOKEN);
}

// Formatea una fecha YYYY-MM-DD
export function formatFecha(fecha) {
  if (fecha) {
    let fechaFormat = new Date(fecha);
    const fechaProcesada = new Date(
      fechaFormat.getTime() + fechaFormat.getTimezoneOffset() * 60 * 1000
    );
    return `${fechaProcesada.getFullYear()}-${(
      "0" +
      (fechaProcesada.getMonth() + 1)
    ).slice(-2)}-${("0" + fechaProcesada.getDate()).slice(-2)}`;
  }
}

// Access API
// With timeout specified in .env
export function accessAPI(
  verb,
  endpoint,
  data,
  callbackSuccess,
  callbackFail,
  file
) {
  const url = process.env.REACT_APP_API_URL + "/" + endpoint;
  const accessToken = readFromLS(process.env.REACT_APP_LS_LOGIN_TOKEN);

  let fetchConfig = {
    method: verb,
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };

  let dataParaEnviar;

  // Si hay un archivo para enviar, genera el form data
  if (file) {
    let formData = new FormData();
    formData.append("archivo", file);
    if (data) {
      // Se agregan al form data el resto de los campos del payload
      let datosArray = Object.entries(data);
      for (const [key, value] of datosArray) {
        formData.append(key, value);
      }
    }
    dataParaEnviar = formData;
  } else if (data) {
    // Si no hay archivos y es sólo data, se debe agregar el content-type adecuado en el header
    fetchConfig.headers["Content-Type"] = "application/json";
    fetchConfig.headers["accept-encoding"] = "gzip, deflate";
    dataParaEnviar = JSON.stringify(data);
  }
  fetchConfig.body = dataParaEnviar;

  Promise.race([
    // Generate two promises, one with the fetch and the other with the timeout
    // the one that finishes first resolves
    fetch(url, fetchConfig),
    new Promise(function (resolve, reject) {
      setTimeout(
        () => reject(new Error("request timeout")),
        process.env.REACT_APP_API_TIMEOUT
      );
    }),
  ])
    .then((response) => {
      // When race resolves, it verifies the status of the API response
      // If it's 200 or 201, it was successful, then the success callback is run
      if (response.status === 204) {
        callbackSuccess();
      } else if (response.status >= 200 && response.status < 300) {
        response.json().then((data) => {
          callbackSuccess(data);
        });
      } else {
        response.json().then((data) => {
          data.status = response.status;
          callbackFail(data);
        });
      }
    })
    .catch((e) => {
      var response = {
        status: 500,
        msg: texts.API_ERROR,
      };
      callbackFail(response);
    });
}

// Descargar archivo
export function downloadFile(
  endpoint,
  filename,
  callbackSuccess,
  callbackFail
) {
  const url = process.env.REACT_APP_API_URL + "/" + endpoint;
  const accessToken = readFromLS(process.env.REACT_APP_LS_LOGIN_TOKEN);
  var fetchConfig = {
    method: "GET",
    headers: {
      "accept-encoding": "gzip, deflate",
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  };
  Promise.race([
    // Genera dos promesas, una con el fetch y otra con el timeout
    // La primera que termine, resuelve
    fetch(url, fetchConfig),
    new Promise(function (resolve, reject) {
      setTimeout(
        () => reject(new Error("request timeout")),
        process.env.REACT_APP_API_TIMEOUT
      );
    }),
  ])
    .then((response) => {
      // Cuando resuelve la carrera, verifica el status de la respuesta de la API
      // Si es 200 o 201, es exitosa y llama al callback
      if (response.status === 204) {
        callbackSuccess([{ msg: "Respuesta exitosa" }]);
      } else if (response.status >= 200 && response.status < 300) {
        response.blob().then((data) => {
          const blobUrl = URL.createObjectURL(data);

          // Create a link element
          const link = document.createElement("a");

          // Set link's href to point to the Blob URL
          link.href = blobUrl;
          link.download = filename;

          // Append link to the body
          document.body.appendChild(link);

          // Dispatch click event on the link
          // This is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );

          // Remove link from body
          document.body.removeChild(link);
          callbackSuccess(data);
        });
      } else {
        response.json().then((data) => {
          data.status = response.status;
          callbackFail(data);
        });
      }
    })
    .catch((e) => {
      var response = {
        status: 500,
        msg: texts.API_ERROR,
      };
      callbackFail(response);
    });
}

// Function called when the API call responds with something other than 200 or 201
export function APIerror(response) {
  console.log(response);
  // Verifies if it's a server issue
  if (response.status >= 500) {
    alert(response.msg);
    window.location.href = "/tablero";
    return;
  }

  // Verifies if the issue is with permissions
  if (response.status === 401 || response.status === 403) {
    alert(response.msg);
    deleteFromLS(process.env.REACT_APP_LS_LOGIN_TOKEN);
    window.location.href = "/login";
    return;
  }

  // If the error is different, show the prompt but stay
  alert(response.msg);
  return;
}
