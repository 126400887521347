import { useState, useEffect } from "react";
import { accessAPI } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import Boton from "../boton/Boton";
import "./buscador.css";
import loaderImagen from "../../images/loaderImagen.png";
import "../loader/loader.css";

export default function Buscador() {
  const [buscar, setBuscar] = useState("");
  const [resultados, setResultados] = useState([]);
  const [loader, setLoader] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);

  const navigate = useNavigate();

  // Función para realizar la búsqueda
  const performSearch = (query) => {
    if (query.length > 0) {
      accessAPI(
        "GET",
        `admin/buscador/buscarDatos?query=${query}&limit=2`,
        null,
        (respuesta) => {
          setResultados(respuesta);
          setLoader(false);

          setHasSearched(true);
        },
        (error) => {
          setLoader(false);
          setHasSearched(true);
        }
      );
    } else {
      setResultados([]);
      setLoader(false);
    }
  };

  // Función que detecta enter y escape para comportamiento particular
  function detectarEnterEsc(e) {
    if (e.key === "Enter") {
      navigate(`/buesquedas/${buscar}`);
      navigate(0);
    }
    if (e.key === "Escape") {
      setResultados([]);
      setHasSearched(false);
    }
  }

  useEffect(() => {
    // Si el campo de texto está vacío, reinicia el componente
    if (buscar === "") {
      setResultados([]);
      setBuscar("");
      setHasSearched(false);
    }
  }, [buscar]);

  // Función manejadora para el input
  const handleInputChange = (e) => {
    setLoader(true);
    const value = e.target.value;
    setBuscar(value);

    // Si ya existe un timeout previo, lo cancelamos
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Creamos un nuevo timeout de 500ms para ejecutar la búsqueda
    setTypingTimeout(
      setTimeout(() => {
        performSearch(value);
      }, 500)
    );
  };

  // Función para manejar la redirección y limpiar los resultados después de navegar
  const navegacion = (item) => {
    let ruta = "";

    switch (item.tipo) {
      case "cliente":
        ruta = `/clientes/${item.id}`;
        break;
      case "procesos":
        ruta = `/procesos/cliente/${item.id}`;
        break;
      case "empleado":
        ruta = `/clientes/${item.clienteid}`;
        break;
      case "solicitud":
        ruta = `/solicitud/${item.id}`;
        break;
      case "auditor":
        ruta = `/proveedores/auditores/${item.id}`;
        break;
      case "laboratorio":
        ruta = `/proveedores/laboratorios/listado/${item.id}`;
        break;
      case "proceso":
        ruta = `/proceso/${item.id}`;
        break;
      default:
        ruta = "/";
        return;
    }

    setResultados([]);
    setBuscar("");
    setHasSearched(false);
    navigate(ruta);
    // No sé por qué necesito este navigate(0), pero si estoy en un proceso y clickeo en otro proceso que es el resultado de una búsqueda, no navega a menos que esté esto
    navigate(0);
  };

  return (
    <>
      <div className="buscadorContainer">
        <input
          type="text"
          value={buscar}
          onChange={handleInputChange}
          onKeyDown={detectarEnterEsc}
          placeholder="Buscar..."
          className="prettyInput"
        />

        {loader && (
          <img className="loaderImagen" src={loaderImagen} alt="loader" />
        )}

        {hasSearched && (
          <div className="resultadosBusqueda">
            {buscar !== "" && resultados.length > 0 && (
              <>
                {resultados.map((item, index) => (
                  <Boton
                    callback={() => {
                      navegacion(item);
                    }}
                    clases="texto"
                    key={index}
                  >
                    <span className="gris"> {item.tipoEtiqueta}:</span>{" "}
                    {item.nombre}
                  </Boton>
                ))}
                {resultados.length > 1 && (
                  <Boton uri={`/buesquedas/${buscar}`} clases="texto">
                    Ver más resultados
                  </Boton>
                )}
              </>
            )}
            {buscar !== "" && resultados.length === 0 && hasSearched && (
              <p>No se encontraron resultados</p>
            )}
          </div>
        )}
      </div>
    </>
  );
}
