import { useState } from "react";
import CampoEditable from "../../elementos/campoEditable/CampoEditable";
import TarjetaAlcance from "./TarjetaAlcance";
import AgregarAlcance from "./AgregarAlcance";

export default function LaboratorioDetalle(props) {
  const [alcances, setAlcances] = useState(props.laboratorio.alcances);

  const { laboratorio } = props;

  function agregarAlcance(alcance) {
    setAlcances([...alcances, alcance]);
  }

  return (
    <>
      <div className="tarjetasLaboratoriosContainer flexContainer vertical">
        {laboratorio && (
          <>
            <div className="tarjeta">
              <h1>{laboratorio.razonsocial}</h1>
              <CampoEditable
                etiqueta="Razón social"
                campo="razonsocial"
                valor={laboratorio.razonsocial}
                entidadid={laboratorio.id}
                endpoint="admin/laboratorio"
              />
              <CampoEditable
                etiqueta="Nombre Fantasía"
                campo="nombrefantasia"
                valor={laboratorio.nombrefantasia}
                entidadid={laboratorio.id}
                endpoint="admin/laboratorio"
              />
              <CampoEditable
                etiqueta="RUT"
                campo="rut"
                valor={laboratorio.rut}
                entidadid={laboratorio.id}
                endpoint="admin/laboratorio"
              />
              <CampoEditable
                etiqueta="Dirección"
                campo="direccion"
                valor={laboratorio.direccion}
                entidadid={laboratorio.id}
                endpoint="admin/laboratorio"
              />
              <CampoEditable
                etiqueta="C.P."
                campo="codigopostal"
                valor={laboratorio.codigopostal}
                entidadid={laboratorio.id}
                endpoint="admin/laboratorio"
              />
              <CampoEditable
                etiqueta="Ciudad"
                campo="ciudad"
                valor={laboratorio.ciudad}
                entidadid={laboratorio.id}
                endpoint="admin/laboratorio"
              />
              <CampoEditable
                etiqueta="Estado"
                campo="estado"
                valor={laboratorio.estado}
                entidadid={laboratorio.id}
                endpoint="admin/laboratorio"
              />
              <CampoEditable
                etiqueta="País"
                campo="pais"
                valor={laboratorio.pais}
                entidadid={laboratorio.id}
                endpoint="admin/laboratorio"
              />
              <CampoEditable
                etiqueta="Teléfono"
                campo="telefono"
                valor={laboratorio.telefono}
                entidadid={laboratorio.id}
                endpoint="admin/laboratorio"
              />
              <CampoEditable
                etiqueta="Email"
                campo="email"
                valor={laboratorio.email}
                entidadid={laboratorio.id}
                endpoint="admin/laboratorio"
              />
              <CampoEditable
                etiqueta="Página"
                campo="pagina"
                valor={laboratorio.pagina}
                entidadid={laboratorio.id}
                endpoint="admin/laboratorio"
              />
              <CampoEditable
                etiqueta="Notas"
                campo="notas"
                valor={laboratorio.notas}
                entidadid={laboratorio.id}
                endpoint="admin/laboratorio"
              />
            </div>

            <AgregarAlcance
              laboratorio={laboratorio}
              agregarAlcance={agregarAlcance}
              key={alcances.length}
            />
            <div className="titulo centrado">Alcances</div>

            {!!alcances.length &&
              alcances.map((alcance) => {
                return <TarjetaAlcance alcance={alcance} key={alcance.id} />;
              })}
            {!alcances.length && (
              <div>Aún no se han ingresado alcances para este laboratorio</div>
            )}
          </>
        )}
      </div>
    </>
  );
}
