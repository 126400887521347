import "./normas.css";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { accessAPI } from "../../utils/utils.js";
import Loader from "../../elementos/loader/Loader";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion.js";
import Footer from "../../elementos/footer/Footer";
import CampoEditable from "../../elementos/campoEditable/CampoEditable";
import Boton from "../../elementos/boton/Boton";

import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function Normas(props) {
  const [loader, setLoader] = useState(true);
  const [normas, setNormas] = useState();
  const [loaderNorma, setLoaderNorma] = useState(false);
  const [normaSeleccionada, setNormaSeleccionada] = useState();
  const [datosNormaSeleccionada, setDatosNormaSeleccionada] = useState();

  const { notify } = useNotification();

  const { normaId } = useParams();

  useEffect(() => {
    accessAPI(
      "GET",
      "admin/norma",
      null,
      (respuesta) => {
        setNormas(respuesta);
      },
      (respuesta) => {
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
      }
    );
    setNormaSeleccionada(parseInt(normaId));
  }, [normaId, notify]);

  useEffect(() => {
    if (normas) {
      setLoader(false);
    }
  }, [normas]);

  useEffect(() => {
    if (normaSeleccionada) {
      setDatosNormaSeleccionada();
      setLoaderNorma(true);
      accessAPI(
        "GET",
        `admin/norma/${normaSeleccionada}`,
        null,
        (respuesta) => {
          respuesta.norma.certificadotipoid =
            respuesta.norma.certificadotipo?.nombre;
          respuesta.norma.padreid = respuesta.norma.padre?.numeroano;
          setDatosNormaSeleccionada(respuesta.norma);
        },
        (respuesta) => {
          notify({
            mensaje: respuesta[0].msg,
            temporal: true,
            error: true,
          });
          setLoaderNorma(false);
          setNormaSeleccionada();
        }
      );
    }
  }, [normaSeleccionada, notify]);

  return (
    <div className="seccion">
      <MenuNavegacion submenuSeleccionado="config" />
      <Footer />
      <div className="contenido">
        <h1>Normas</h1>
        {loader && <Loader>Cargando normas</Loader>}
        {!loader && normas && (
          <>
            <Boton uri="/normas/nuevo" clases="centrado verde">
              Nueva norma
            </Boton>
            <div className="flexContainer">
              <div className="tarjetasContainer">
                <div className="tarjeta">
                  {normas.map((norma) => (
                    <div
                      className={
                        normaSeleccionada === norma.value
                          ? "norma seleccionado"
                          : "norma"
                      }
                      key={norma.value}
                    >
                      <div onClick={() => setNormaSeleccionada(norma.value)}>
                        {norma.etiqueta}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="tarjetasContainer">
                {!datosNormaSeleccionada && loaderNorma && (
                  <Loader>Cargando norma</Loader>
                )}
                {datosNormaSeleccionada && (
                  <div>
                    <div className="tarjeta">
                      <h1>{datosNormaSeleccionada.numero}</h1>
                      <CampoEditable
                        etiqueta="Numero"
                        campo="numero"
                        entidadid={datosNormaSeleccionada.id}
                        valor={datosNormaSeleccionada.numero}
                        endpoint="admin/norma"
                      />
                      <CampoEditable
                        etiqueta="Nombre"
                        campo="nombre"
                        entidadid={datosNormaSeleccionada.id}
                        valor={datosNormaSeleccionada.nombre}
                        endpoint="admin/norma"
                      />
                      <CampoEditable
                        etiqueta="Nombre en el certificado"
                        campo="nombrecertificado"
                        valor={datosNormaSeleccionada.nombrecertificado}
                        entidadid={datosNormaSeleccionada.id}
                        endpoint="admin/norma"
                      />
                      <CampoEditable
                        etiqueta="Acreditada"
                        campo="acreditada"
                        valor={datosNormaSeleccionada.acreditada}
                        entidadid={datosNormaSeleccionada.id}
                        endpoint="admin/norma"
                      />
                      <CampoEditable
                        etiqueta="Puntos de seguimiento"
                        campo="puntosdeseguimiento"
                        valor={datosNormaSeleccionada.puntosdeseguimiento.join(
                          " ; "
                        )}
                        entidadid={datosNormaSeleccionada.id}
                        endpoint="admin/norma"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
