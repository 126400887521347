import { useState, useEffect } from "react";
import Loader from "../../elementos/loader/Loader";
import "./certificado.css";
import CambioEstadoCertificado from "./CambioEstadoCertificado";
import Formulario from "../../elementos/formulario/Formulario";
import { accessAPI } from "../../utils/utils.js";

export default function EstadoCertificado(props) {
  const [estados, setEstados] = useState();
  const [loader, setLoader] = useState(true);
  const [loaderFormulario, setLoaderFormulario] = useState(false);

  const [cambiosEstadoHistoricos, setCambiosEstadoHistoricos] = useState(
    props.certificado.cambioestadocertificado
  );

  // Configuracion del formulario de cambio de estado
  const cambioEstadoFormConfig = {
    secciones: [
      {
        nombre: "Cambio de estado del certificado",
        campos: [
          {
            nombre: "nuevoestadoid",
            tipoitemformularioid: 5,
            etiqueta: "Nuevo estado",
            requerido: true,
            opciones: estados,
            sinOpcionInicial: true,
          },
          {
            nombre: "notas",
            tipoitemformularioid: 1,
            etiqueta: "Notas",
            requerido: false,
          },
        ],
      },
    ],
    submit: { callback: cambioEstadoSubmit },
    botones: [{ etiqueta: "Cambiar estado", tipo: "submit" }],
  };

  // Cuando carga el componente va a buscar los estados del certificado
  useEffect(() => {
    accessAPI(
      "GET",
      "admin/config/estadocertificado",
      null,
      (respuesta) => {
        setEstados(respuesta);
      },
      (respuesta) => {
        alert(respuesta[0].msg);
      }
    );
  }, []);

  // Cuando carga los estados del certificado apaga el loader
  useEffect(() => {
    if (estados && estados.length) {
      setLoader(false);
    }
  }, [estados]);

  // Submit del formualrio de cambio de estado
  function cambioEstadoSubmit(respuestaFormulario) {
    setLoaderFormulario(true);
    accessAPI(
      "PUT",
      `admin/certificado/${props.certificado.id}/cambioestado`,
      respuestaFormulario,
      (respuesta) => {
        setCambiosEstadoHistoricos([respuesta, ...cambiosEstadoHistoricos]);
        setLoaderFormulario(false);
      },
      (respuesta) => {
        alert(respuesta[0].msg);
        setLoaderFormulario(false);
      }
    );
  }

  return (
    <div className="tarjeta">
      {loader && <Loader>Cargando estados</Loader>}
      {!loader && (
        <div>
          <h1>
            Estado actual:{" "}
            <span
              className={
                props.certificado.estadocertificado.id === 1 ? "verde" : "rojo"
              }
            >
              {props.certificado.estadocertificado.nombre}
            </span>
          </h1>
          {cambiosEstadoHistoricos.length > 0 && (
            <div>
              <h2>Histórico de cambios de estado</h2>
              {cambiosEstadoHistoricos.map((cambioEstado) => {
                return (
                  <CambioEstadoCertificado
                    key={cambioEstado.id}
                    cambioestado={cambioEstado}
                  />
                );
              })}
            </div>
          )}
          <div>
            <Formulario
              config={cambioEstadoFormConfig}
              loader={loaderFormulario}
            />
          </div>
        </div>
      )}
    </div>
  );
}
