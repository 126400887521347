import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { accessAPI } from "../../utils/utils.js";
import Loader from "../../elementos/loader/Loader";
import Formulario from "../../elementos/formulario/Formulario";

export default function FormulariosProceso() {
  const [formularios, setFormularios] = useState();
  const [loader, setLoader] = useState(true);
  const [creandoFormulario, setCreandoFormulario] = useState(false);

  const navigate = useNavigate();

  const crearFormularioFormConfig = {
    campos: [
      {
        nombre: "codigo",
        tipoitemformularioid: 1,
        etiqueta: "Código",
        requerido: true,
      },
      {
        nombre: "nombre",
        tipoitemformularioid: 1,
        etiqueta: "Nombre",
        requerido: true,
      },
    ],
    submit: { callback: crearFormularioSubmit },
    botones: [
      { etiqueta: "Crear formulario", tipo: "submit" },
      {
        etiqueta: "Cancelar",

        callback: () => {
          setCreandoFormulario(false);
        },
        tipo: "button",
      },
    ],
  };

  // Cuando carga, trae los formularios liberados
  useEffect(() => {
    accessAPI(
      "GET",
      "admin/formulario",
      null,
      (response) => {
        setFormularios(response);
      },
      (response) => {
        console.log(response);
      }
    );
  }, []);

  // Cuando recibe los formularios apaga el loader
  useEffect(() => {
    if (formularios) {
      setLoader(false);
    }
  }, [formularios]);

  // Callback de submit del formulario
  function crearFormularioSubmit(contenidoCampos) {
    accessAPI(
      "POST",
      "admin/formulario",
      contenidoCampos,
      (response) => {
        navigate(`/formulario/${response.id}`);
      },
      (response) => {
        alert(response[0].msg);
      }
    );
    console.log(contenidoCampos);
  }

  return (
    <div>
      <div>Formularios</div>
      <div>Crear nuevo formulario</div>
      {!creandoFormulario && (
        <button
          onClick={() => {
            setCreandoFormulario(true);
          }}
        >
          Nuevo formulario
        </button>
      )}
      {creandoFormulario && <Formulario config={crearFormularioFormConfig} />}
      {loader && <Loader>Cargando formularios</Loader>}
      {!loader && formularios && (
        <div>
          <div>Formularios vigentes</div>
          {formularios.formulariosVigentes.map((formulario) => {
            return (
              <div key={formulario.id}>
                <a href={`formulario/${formulario.id}`}>
                  {formulario.codigo} v. {formulario.version} -{" "}
                  {formulario.nombre}
                </a>
              </div>
            );
          })}
          <div>Formularios no vigentes</div>
          {formularios.formulariosNoVigentes.map((formulario) => {
            return (
              <div key={formulario.id}>
                <a href={`formulario/${formulario.id}`}>
                  {formulario.codigo} v. {formulario.version} -{" "}
                  {formulario.nombre}
                </a>
                - {formulario.fechaliberacion && <span>Liberado</span>}
                {!formulario.fechaliberacion && <span>No liberado</span>}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
