import { useState } from "react";
import MenuNavegacion from "../../elementos/menuNavegacion/menuNavegacion";
import Footer from "../../elementos/footer/Footer";
import Formulario from "../../elementos/formulario/Formulario";
import { downloadFile, formatFecha } from "../../utils/utils";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function Reporte() {
  const [loaderBoton, setLoaderBoton] = useState(false);
  const { notify } = useNotification();

  const OUACertificadosPorNomaFormConfig = {
    secciones: [
      {
        nombre: "Certificados por norma",
        campos: [
          {
            nombre: "fechaInicio",
            tipoitemformularioid: 7,
            etiqueta: "Fecha de inicio",
          },
          {
            nombre: "fechaFinalizacion",
            tipoitemformularioid: 7,
            etiqueta: "Fecha de finalización",
          },
        ],
      },
    ],
    submit: { callback: procesarCertificadosPorNorma },
    botones: [
      { etiqueta: "Obtener certificados", tipo: "submit", clases: "azul" },
    ],
  };

  // Función ejecutada en el formulario de certificados por norma
  function procesarCertificadosPorNorma(camposFormulario) {
    // Enciende el loader del botón
    setLoaderBoton(true);
    let url;
    let filename;
    if (camposFormulario.fechaInicio && camposFormulario.fechaFinalizacion) {
      url = `admin/reporte/oua/procesospornorma/${camposFormulario.fechaInicio}/${camposFormulario.fechaFinalizacion}`;
      filename = `Certificados ${formatFecha(
        camposFormulario.fechaInicio
      )} - ${formatFecha(camposFormulario.fechaFinalizacion)}.xlsx`;
    } else {
      url = `admin/reporte/oua/procesospornorma`;
      filename = "Certificados.xlsx";
    }

    downloadFile(
      url,
      filename,
      (respuesta) => {
        setLoaderBoton(false);
      },
      (respuesta) => {
        setLoaderBoton(false);
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
      }
    );
  }

  return (
    <div className="seccion config">
      <MenuNavegacion submenuSeleccionado="config" />
      <Footer />
      <div className="contenido">
        <h1>Reportes para OUA</h1>
        <div className="tarjeta tresCuartos">
          <Formulario
            config={OUACertificadosPorNomaFormConfig}
            loader={loaderBoton}
          />
        </div>
      </div>
    </div>
  );
}
