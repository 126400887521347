import { useState } from "react";
import { accessAPI } from "../../utils/utils.js";
import Formulario from "../../elementos/formulario/Formulario.js";
import { useNotification } from "../../elementos/notificacion/NotificationProvider.js";

export default function AgregarAlcance(props) {
  const [loaderform, setLoaderform] = useState(false);

  const { notify } = useNotification();

  const { laboratorio, agregarAlcance } = props;

  function crearAlcanceSubmit(camposFormulario, archivo) {
    setLoaderform(true);
    accessAPI(
      "POST",
      `admin/laboratorio/alcance/${laboratorio.id}`,
      camposFormulario,
      (respuesta) => {
        agregarAlcance(respuesta);
        setLoaderform(false);
      },
      (respuesta) => {
        // Si la API da error, devuelve el error al laboratorio
        notify({
          mensaje: respuesta[0].msg,
          temporal: true,
          error: true,
        });
        setLoaderform(false);
      },
      archivo
    );
  }

  const nuevoAlcanceFormConfig = {
    secciones: [
      {
        nombre: "Agregar alcance",
        campos: [
          {
            nombre: "normaid",
            tipoitemformularioid: 5,
            etiqueta: "Norma",
            opciones: "admin/norma",
            requerido: true,
            sinOpcionInicial: true,
          },
          {
            nombre: "criterio",
            tipoitemformularioid: 1,
            etiqueta: "Criterio de aceptación",
            requerido: true,
          },
          {
            nombre: "fechavalidez",
            tipoitemformularioid: 7,
            etiqueta: "Fecha adquisición",
            requerido: true,
          },
          {
            nombre: "archivoexistente",
            tipoitemformularioid: 5,
            etiqueta: "Archivo de evidencia existente",
            opciones: `admin/laboratorio/archivosevidencias/${laboratorio.id}`,
            sinOpcionInicial: true,
            requerido: false,
          },
          {
            nombre: "evidencia",
            tipoitemformularioid: 10,
            etiqueta: "Archivo de evidencia nuevo",
            requerido: false,
          },
          {
            nombre: "enevaluacion",
            tipoitemformularioid: 6,
            etiqueta: "En evaluación (permite utilizarlo en procesos)",
            requerido: false,
          },
          {
            nombre: "normaequivalente",
            tipoitemformularioid: 1,
            etiqueta: "Norma equivalente",
            requerido: false,
          },
          {
            nombre: "documentohomologacion",
            tipoitemformularioid: 1,
            etiqueta: "Documento de homologación",
            requerido: false,
          },
          {
            nombre: "notas",
            tipoitemformularioid: 1,
            etiqueta: "Notas",
            requerido: false,
          },
        ],
      },
    ],
    submit: { callback: crearAlcanceSubmit },
    botones: [{ etiqueta: "Agregar alcance", tipo: "submit" }],
  };

  return (
    <div className="tarjeta">
      <Formulario config={nuevoAlcanceFormConfig} loader={loaderform} />
    </div>
  );
}
