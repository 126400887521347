import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./secciones/login/Login";
import Tablero from "./secciones/tablero/Tablero";
import Normas from "./secciones/norma/Normas";
import NuevaNorma from "./secciones/norma/NuevaNorma";
import ConfigPortada from "./secciones/config/Portada";
import ProcesoTipos from "./secciones/procesoTipos/ProcesoTipos";
import ProcesosPortada from "./secciones/proceso/Portada";
import Procesos from "./secciones/proceso/Procesos";
import ProcesosCliente from "./secciones/proceso/ProcesosCliente";
import Proceso from "./secciones/proceso/Proceso";
import Solicitudes from "./secciones/solicitud/Solicitudes";
import Solicitud from "./secciones/solicitud/Solicitud";
import TableroDeProcesos from "./secciones/proceso/TableroDeProcesos";
import NuevoProceso from "./secciones/proceso/NuevoProceso";
import FormulariosProceso from "./secciones/formularioProceso/FormulariosProceso";
import FormularioProceso from "./secciones/formularioProceso/FormularioProceso";
import Certificado from "./secciones/certificado/Certificado";
import Documentos from "./secciones/documento/Documentos";
import Documento from "./secciones/documento/Documento";
import Clientes from "./secciones/cliente/Clientes";
import Cliente from "./secciones/cliente/Cliente";
import ClientesProveedoresPortada from "./secciones/cliente/Portada";
import NuevoCliente from "./secciones/cliente/NuevoCliente";
import ProveedoresPortada from "./secciones/proveedor/Portada";
import Laboratorios from "./secciones/laboratorio/Laboratorios";
import NuevoLaboratorio from "./secciones/laboratorio/NuevoLaboratorio";
import Esquemas from "./secciones/esquemas/Esquemas";
import NuevoEsquema from "./secciones/esquemas/NuevoEsquema";
import Reporte from "./secciones/reporte/Reporte";
import CertificadoTipos from "./secciones/certificadoTipo/CertificadoTipos";
import CertificadoTipo from "./secciones/certificadoTipo/CertificadoTipo";
import NuevoCertificadoTipo from "./secciones/certificadoTipo/NuevoCertificadoTipo";
import NuevaCaracteristicaProducto from "./secciones/certificadoTipo/NuevaCaracteristicaProducto";
import NuevoAuditor from "./secciones/auditor/NuevoAuditor";
import Auditores from "./secciones/auditor/Auditores";
import Auditor from "./secciones/auditor/Auditor";
import AuditorDetalle from "./secciones/auditor/AuditorDetalle";
import BusquedaResultado from "./secciones/busquedaResultado/BusquedaResultado";
import Comunicaciones from "./secciones/comunicaciones/Comunicaciones";
import NotFound from "./secciones/notFound/NotFound";
import JuguetesOperaciones from "./secciones/juguetes/JuguetesOperaciones";
import ConfigEventos from "./secciones/configeventos/ConfigEventos";
import ConfigEvento from "./secciones/configeventos/ConfigEvento";
import Enviados from "./secciones/enviados/Enviados";
class Router extends React.Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<Tablero />} />
        <Route path="/login" element={<Login />} />
        <Route path="/config" element={<ConfigPortada />} />
        <Route path="/procesotipos" element={<ProcesoTipos />} />
        <Route path="/esquemas" element={<Esquemas />} />
        <Route path="/esquemas/nuevo" element={<NuevoEsquema />} />
        <Route path="/reporte" element={<Reporte />} />
        <Route path="/normas/listado" element={<Normas />} />
        <Route path="/normas/listado/:normaId" element={<Normas />} />
        <Route path="/normas/nuevo" element={<NuevaNorma />} />
        <Route path="/documentos" element={<Documentos />} />
        <Route path="/documento/:documentoId" element={<Documento />} />
        <Route path="/procesos" element={<ProcesosPortada />} />
        <Route path="/procesos/listado" element={<Procesos />} />
        <Route
          path="/procesos/cliente/:clienteId"
          element={<ProcesosCliente />}
        />
        <Route path="/proceso/:procesoId" element={<Proceso />} />
        <Route path="/procesos/nuevo" element={<NuevoProceso />} />
        <Route path="/procesos/tablero" element={<TableroDeProcesos />} />
        <Route path="/solicitudes" element={<Solicitudes />} />
        <Route path="/solicitud/:solicitudId" element={<Solicitud />} />
        <Route path="/certificado/:certificadoId" element={<Certificado />} />
        <Route path="/formularios" element={<FormulariosProceso />} />
        <Route path="/buesquedas/:buscar" element={<BusquedaResultado />} />
        <Route path="/juguetes/operaciones" element={<JuguetesOperaciones />} />
        <Route
          path="/formulario/:formularioId"
          element={<FormularioProceso />}
        />
        <Route path="/certificadotipos" element={<CertificadoTipos />} />
        <Route
          path="/certificadotipos/:certificadoTipoId"
          element={<CertificadoTipo />}
        />
        <Route
          path="/certificadotipos/nuevo"
          element={<NuevoCertificadoTipo />}
        />
        <Route
          path="/certificadotipos/:certificadoTipoId/nuevacaracteristicaproducto"
          element={<NuevaCaracteristicaProducto />}
        />
        <Route path="/clientes" element={<ClientesProveedoresPortada />} />
        <Route path="/clientes/listado" element={<Clientes />} />
        <Route path="/clientes/listado/:clienteId" element={<Clientes />} />
        <Route path="/clientes/:clienteId" element={<Cliente />} />
        <Route path="/clientes/nuevo" element={<NuevoCliente />} />
        <Route path="/clientes/comunicaciones" element={<Comunicaciones />} />
        <Route path="/proveedores" element={<ProveedoresPortada />} />
        <Route
          path="/proveedores/laboratorios/listado"
          element={<Laboratorios />}
        />
        <Route
          path="/proveedores/laboratorios/listado/:laboratorioId"
          element={<Laboratorios />}
        />
        <Route
          path="/proveedores/laboratorios/nuevo"
          element={<NuevoLaboratorio />}
        />
        <Route path="/proveedores/nuevoauditor" element={<NuevoAuditor />} />
        <Route path="/proveedores/auditores" element={<Auditores />} />
        <Route path="/proveedores/auditores/:auditorId" element={<Auditor />} />
        <Route
          path="/proveedores/auditores/detalle/:auditorId"
          element={<AuditorDetalle />}
        />
        <Route path="/comunicacion/configeventos" element={<ConfigEventos />} />
        <Route
          path="/comunicacion/configeventos/:eventoTipoId"
          element={<ConfigEvento />}
        />
        <Route path="/comunicacion/enviados" element={<Enviados />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }
}

export default Router;
